// let baseUrl = 'http://192.168.0.249:5000/api/v1';
// let mediaUrl = 'http://192.168.0.249:5000';
// let baseUrl = 'http://localhost:5000/api/v1';
// let mediaUrl = 'http://localhost:5000';
let baseUrl = 'https://signwise-api-dev.falconweb.app/api/v1';
let mediaUrl = 'https://signwise-api-dev.falconweb.app';

const appName = 'reactapp';
let env = 'dev';

// Update the baseUrl and mediaUrl on every environment
switch (window.location.host) {
  case 'signwise-admin-dev.falconweb.app':
    baseUrl = 'https://signwise-api-dev.falconweb.app/api/v1';
    mediaUrl = 'https://signwise-api-dev.falconweb.app';
    break;

  // case 'reactapp-qa.falconweb.app':
  //   baseUrl = 'https://reactapp-api-qa.falconweb.app/api/v1';
  //   mediaUrl = 'https://reactapp-api-qa.falconweb.app';
  //   env = 'qa';
  //   break;

  case 'signwise-admin-preprod.falconweb.app':
    baseUrl = 'https://signwise-api-preprod.falconweb.app/api/v1';
    mediaUrl = 'https://api.signwise.com';
    env = 'preprod';
    break;

  case 'admin.signwise.com':
    baseUrl = 'https://api.signwise.com/api/v1';
    mediaUrl = 'https://api.signwise.com';
    env = 'prod';
    break;
}

export { baseUrl, mediaUrl, appName, env };
