import React, { FC, useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import CompanyInfo from '../../assets/homeimg.svg';
import DefaultAvatar from '../../assets/avatar.svg';
import UpoadImg from '../../assets/uploadIcon.svg';
import request from '../../api/request';
import { toast } from 'react-toastify';
import { mediaUrl } from '../../config';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../assets/closeFilledIcon.svg';
import { IAddress, IAddressResponse } from '../../types/user';
import RightArrow from '../../assets/rightArrow.svg';
import { IAddlocalCompany } from './AddLocalCompany.pages';
import CustomToast from '../../components/common/CustomToast';

const initialState = {
  companyInfo: {
    name: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      address: '',
      country: '',
    },
    phone: '',
    formattedNo: '',

    email: '',
    domain: '',
    website: '',
    description: '',
    location: {
      type: 'Point',
      coordinates: [40, 6],
    },
    logo: '',
    companyType: 'supplier',
    // category: '',
    category: [],
  },
  profileCompleted: true,
};
const AddSupplier: FC<IAddlocalCompany> = ({ user, domain, company }) => {
  const [categories, setCategories] = useState<any>([]);
  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  const [name, setName] = useState('');
  const [data, setData] = useState<any>(initialState);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [checkedSubcategories, setCheckedSubcategories] = useState<any>({});
  const [checkedCategories, setCheckedCategories] = useState<any>({});
  const [isDomainUpdated, setIsDomainUpdated] = useState(false);

  const handleGetAddress = (value: string): void => {
    setName(value);
    request<IAddressResponse>('get', `/address?text=${value}`)
      .then((response) => {
        console.log(response);
        setAllAddresses(response.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddressChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: IAddress,
  ): void => {
    if (value) {
      setData({
        ...data,
        companyInfo: {
          ...data.companyInfo,
          address: {
            street: value.contexts.place,
            city: value.contexts.district,
            state: value.contexts.region,
            zip: value.contexts.postcode,
            address: value.place_name,
            country: value.contexts.country,
          },
          location: value.geometry,
        },
      });
    }
  };

  // const handleServiceChange = (category: any): void => {
  //   setCheckedCategories((prev: any) => ({
  //     ...prev,
  //     [category]: !prev[category],
  //   }));
  // };
  const handleSubcategoryChange = (category: any, subcategory: any): void => {
    console.log('handleSubcategoryChange', category, 'subcategory', subcategory);
    setCheckedSubcategories((prev: any) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [subcategory]: !prev[category]?.[subcategory],
      },
    }));
  };

  const handleAddSubcategories = (): void => {
    if (!selectedCategory) return;

    const subcategories = checkedSubcategories[selectedCategory] || {};

    const hasCheckedSubcategories = Object.values(subcategories).some((checked) => checked);

    setCheckedCategories((prev: any) => ({
      ...prev,
      [selectedCategory]: hasCheckedSubcategories,
    }));

    setData((prevData: any) => {
      const updatedCategories = prevData.companyInfo.category.filter(
        (cat: any) => cat.name !== selectedCategory,
      );

      const newCategory = {
        name: selectedCategory,
        subCategory: Object.keys(subcategories).filter((subcat) => subcategories[subcat]),
      };

      return {
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          category: hasCheckedSubcategories
            ? [...updatedCategories, newCategory]
            : updatedCategories,
        },
      };
    });

    setSelectedCategory(null);
  };

  const isChecked = (category: string | number): boolean => checkedCategories[category] || false;
  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = '';
      if (match[1]) {
        formattedNumber += `(${match[1]}`;
      }
      if (match[2]) {
        formattedNumber += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedNumber += `-${match[3]}`;
      }
      return formattedNumber;
    }

    // Return as-is if format doesn't match
    return phoneNumber;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const formattedValue = formatPhoneNumber(value);
    setFormattedPhoneNumber(formattedValue);
    handleChange(event);
  };

  const handleChange = (e: { target: { name: any; value: any } }): void => {
    const { name, value } = e.target;

    setData((prevData: any) => {
      if (name.startsWith('companyInfo.address.')) {
        const addressField = name.split('.')[2];
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            address: {
              ...prevData.companyInfo.address,
              [addressField]: value,
            },
          },
        };
      } else if (name === 'serviceAreaDescription') {
        return {
          ...prevData,
          [name]: value,
        };
      } else if (name === 'domain') {
        const noSpacesValue = value.replace(/\s/g, '');
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            [name]: noSpacesValue,
          },
        };
      } else {
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            [name]: value,
          },
        };
      }
    });
  };
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          const imageUrl = (res as unknown as string[])[0]; // Assuming the response contains the image URL in `data.url`

          setData((prevData: any) => ({
            ...prevData,
            companyInfo: {
              ...prevData.companyInfo,
              logo: imageUrl,
            },
          }));
        })
        .catch((err) => {
          console.error('Image upload error', err);
        });
    }
  };
  const handleErrorCheck = (): void => {
    const domainRegex = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if (domain) {
      handleSubmit();
    } else if (domainRegex.test(data?.companyInfo?.domain)) {
      // Extract part after "www."
      const valueWithoutWWW = data?.companyInfo?.domain.substring(4); // "abc.xyz"
      console.log('valueWithoutWWW', valueWithoutWWW);
      if (valueWithoutWWW) {
        setLoading(true);
        setData((prevData: any) => ({
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            domain: valueWithoutWWW,
          },
        }));

        setIsDomainUpdated(true);
      }
    } else {
      // Show toast error for invalid format
      // toast.error('Enter valid domain format.');
      toast(<CustomToast message={'Enter valid domain format.'} type="error" />);
    }
  };
  const handleSubmit = (): void => {
    if (!data?.companyInfo?.name) {
      // toast.error('Company name is required');
      toast(<CustomToast message={'Company name is required'} type="error" />);

      return;
    }
    if (!data?.companyInfo?.address.state) {
      // toast.error('Company address is required');
      toast(<CustomToast message={'Company address is required'} type="error" />);

      return;
    }

    if (!data?.companyInfo?.email) {
      // toast.error('Company email is required');
      toast(<CustomToast message={'Company email is required'} type="error" />);

      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(data.companyInfo.email)) {
      // toast.error('Invalid email format');
      toast(<CustomToast message={'Invalid email format'} type="error" />);
      return;
    }
    if (!data?.companyInfo?.domain) {
      // toast.error('Company domain is required');
      toast(<CustomToast message={'Company domain is required'} type="error" />);
      return;
    }
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/;
    const isValidDomain = domainRegex.test(data?.companyInfo?.domain);
    if (!isValidDomain) {
      // toast.error('Enter Valid Domain Format');
      toast(<CustomToast message={'Enter Valid Domain Format'} type="error" />);
      return;
    }

    // if (!data?.companyInfo?.description) {
    //   toast.error('Company description is required');
    //   return;
    // }
    if (!data?.companyInfo?.category?.length) {
      // toast.error('Company category is required');
      toast(<CustomToast message={'Company category is required'} type="error" />);
      return;
    }
    setLoading(true);

    request('post', `/companies`, data)
      .then((res: any) => {
        const stateData = {
          user,
          company: res.company,
        };
        // toast.success(`Company created successfully`);
        toast(<CustomToast message={'Company created successfully'} type="success" />);
        if (user) {
          nav('/adduser', { state: stateData });
        } else {
          nav('/dashboard/home');
        }
        setLoading(false);

        console.log('res', res);
        setData(initialState);
      })
      .catch((err) => {
        setLoading(false);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);

        // toast.error('Company already exists.', err);
        console.log('err', err);
      });
  };
  const nav = useNavigate();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    validateForm();
  }, [data]);
  useEffect(() => {
    if (domain) {
      setData((prevData: any) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          domain: domain,
        },
      }));
    }
  }, [domain]);

  useEffect(() => {
    if (isDomainUpdated) {
      const timer = setTimeout(() => {
        handleSubmit();
        // Reset the flag after submission
        setIsDomainUpdated(false);
      }, 1000); // Delay in milliseconds (e.g., 1000 ms = 1 second)

      return () => {
        clearTimeout(timer);
      }; // Clean up the timer
    }
  }, [isDomainUpdated]);
  const validateForm = (): void => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !data.companyInfo.name ||
      !data.companyInfo.address.state ||
      !data.companyInfo.phone ||
      !data.companyInfo.email ||
      !emailPattern.test(data.companyInfo.email) ||
      !data.companyInfo.domain ||
      // !data.companyInfo.description ||
      !data.companyInfo.category ||
      !data?.companyInfo?.category?.length
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };
  const handleRemoveImage = (): void => {
    setData((prev: any) => ({
      ...prev,
      companyInfo: {
        ...prev.companyInfo,
        logo: '',
      },
    }));
    const fileInput = document.getElementById('upload-button') as HTMLInputElement | null; // Get the file input element
    if (fileInput) {
      fileInput.value = ''; // Resets the file input element to allow re-uploading the same image
    }
  };
  console.log('first');
  const getCategories = (): void => {
    request('get', `/companies/categories`)
      .then((res: any) => {
        // toast.success('Success');
        console.log('res searcchh', res?.categories);
        setCategories(res?.categories);
      })
      .catch((err) => {
        toast.error('Error');
        toast(<CustomToast message={'Error'} type="error" />);

        console.log('err', err);
      });
  };
  console.log('category', categories);
  // const handleCategoryChange = (event: any, value: any): void => {
  //   setData((prevData) => ({
  //     ...prevData,
  //     companyInfo: {
  //       ...prevData.companyInfo,
  //       category: value ?? '', // Handle the case when value is null
  //     },
  //   }));
  // };
  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    if (data?.companyInfo?.formattedNo) {
      setFormattedPhoneNumber(formatPhoneNumber(data.companyInfo.formattedNo));
    }
  }, [data?.companyInfo?.formattedNo]);

  useEffect(() => {
    if (company) {
      // Initialize the checked categories and subcategories based on the existing company data
      const initialCheckedCategories: any = {};
      const initialCheckedSubcategories: any = {};
      const subCate =
        company?.companyInfo?.category[0]?.name === 'Materials'
          ? 'Adhesives'
          : company?.companyInfo?.category[0]?.name === 'Equipment'
          ? '3D'
          : 'Accounting';
      company?.companyInfo?.category?.forEach((category: any) => {
        initialCheckedCategories[category.name] = true; // Mark the category as checked

        // Populate subcategories
        initialCheckedSubcategories[category.name] = {};
        category?.subCategory?.forEach((subcat: string) => {
          console.log('subcat', subcat);
          initialCheckedSubcategories[category.name][subCate] = true; // Mark the subcategory as checked
        });
      });

      setCheckedCategories(initialCheckedCategories);
      setCheckedSubcategories(initialCheckedSubcategories);

      // Update the data state to include name, domain, and categories
      setData((prevData: any) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          name: company?.companyInfo?.name,
          domain: company?.companyInfo?.website,
          category: company?.companyInfo?.category || [], // Ensure the category is included
        },
      }));
    }
  }, [company]);
  console.log('data', data);
  console.log('company', company);
  return (
    <Box
      width={'100%'}
      borderRadius={'16px'}
      border={'1px solid rgba(69, 66, 56, 0.10)'}
      padding={'36px'}
    >
      <Typography
        fontSize={'24px'}
        fontWeight={'600'}
        lineHeight={'20px'}
        fontFamily={'Inter'}
        color={'#000000'}
        marginBottom={'36px'}
      >
        Fill the details below:
      </Typography>
      <Box width={'65%'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            src={CompanyInfo}
            alt="Company Logo"
            style={{ width: '100px', marginBottom: '20px' }}
          />

          <Typography
            fontSize="24px"
            fontWeight="800"
            lineHeight="28.64px"
            fontFamily="SF UI Display"
            color="#000000"
            id="modal-modal-title"
          >
            Company Info{' '}
          </Typography>
          <Typography
            fontFamily="SF UI Display"
            fontSize="16px"
            fontWeight="300"
            lineHeight="19.09px"
            color="#000000"
            marginTop="10px"
            id="modal-modal-title"
          >
            Enter the company details
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} margin={'3% 0'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              position="relative"
            >
              <Avatar
                alt="Avatar"
                src={
                  /* eslint-disable @typescript-eslint/restrict-template-expressions */ data
                    ?.companyInfo?.logo
                    ? `${mediaUrl}/${data?.companyInfo?.logo}`
                    : DefaultAvatar
                }
                sx={{ width: '64px', height: '64px' }}
              />
              {data?.companyInfo?.logo && (
                <Box
                  width={'20px'}
                  display="flex"
                  justifyContent="center"
                  alignItems={'center'}
                  position="absolute"
                  right="5px"
                  top="-3px"
                  onClick={handleRemoveImage}
                  // bgcolor={'blue'}
                >
                  <img
                    src={CloseIcon}
                    alt=".."
                    style={{ width: '18px', height: '18px', cursor: 'pointer' }}
                  />
                </Box>
              )}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              ml={2}
            >
              <input
                id="upload-button"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => document.getElementById('upload-button')?.click()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  bgcolor: '#ffffff',
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  padding: '10px 16px',

                  '&:hover': {
                    bgcolor: '#FFFFFF',
                  },
                }}
              >
                <img src={UpoadImg} alt="UpoadImg" />
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  lineHeight={'20px'}
                  fontFamily={'Inter'}
                  textTransform={'capitalize'}
                  color={'#344054'}
                >
                  Upload Company Logo
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Name{' '}
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="name"
              variant="outlined"
              placeholder="D’s Sign & Lighting LLC"
              name="name"
              value={data?.companyInfo?.name}
              onChange={handleChange}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Address{' '}
            </Typography>
            <Autocomplete
              fullWidth
              sx={{
                '.MuiOutlinedInput-root': {
                  padding: '10px 14px',
                },
              }}
              value={name}
              freeSolo
              options={allAddresses}
              getOptionLabel={(option: string | IAddress) => {
                if (typeof option === 'object') {
                  return option.place_name;
                } else {
                  return option;
                }
              }}
              onChange={(event, value) => {
                console.log(value, 'value');
                if (value && typeof value !== 'string') handleAddressChange(event, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="4311 Iris Dr SW, Baltimore, MD "
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input.MuiAutocomplete-input': {
                      padding: '0px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  id="select"
                  onChange={(e) => {
                    handleGetAddress(e.target.value);
                  }}
                  // inputProps={{
                  //   sx: {
                  //     '&::placeholder': {
                  //       fontSize: '16px',
                  //     },
                  //     '@media screen and (max-width: 1060px)': {
                  //       '&::placeholder': {
                  //         fontSize: '12px',
                  //       },
                  //     },
                  //   },
                  // }}
                  InputLabelProps={{
                    style: {
                      borderRadius: '50px',
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Phone Number
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="phone"
              variant="outlined"
              placeholder="(500) 234-5678"
              name="phone"
              value={formattedPhoneNumber}
              onChange={handlePhoneChange}
              inputProps={{
                pattern: '[0-9]*',
                maxLength: 14,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Email
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="email"
              variant="outlined"
              placeholder="abc@xyz.com"
              name="email"
              value={data?.companyInfo?.email}
              onChange={handleChange}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Domain
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              inputProps={{
                maxLength: 40,
                readOnly: !!domain,
              }}
              id="domain"
              variant="outlined"
              placeholder="www.mysignco.com"
              name="domain"
              value={domain ? `www.${data?.companyInfo?.domain}` : data?.companyInfo?.domain}
              onChange={domain ? undefined : handleChange}
            />
          </Box>
          {/* <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Categories
            </Typography>
            <Autocomplete
              fullWidth
              options={categories}
              getOptionLabel={(option: any) => option?.categories ?? 'Choose Category'}
              value={data?.companyInfo?.category}
              // name="category"
              onChange={handleCategoryChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Enter Category"
                  variant="outlined"
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                />
              )}
            />
          </Box> */}
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Description (Optional)
            </Typography>
            <TextField
              multiline
              rows="4"
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '0 3px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="description"
              variant="outlined"
              placeholder="Write Description Here"
              name="description"
              value={data?.companyInfo?.description}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
      <Box width={'65%'} margin={'4% 0'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="5px"
          >
            {/* <img src={ServiceIcon} alt="" /> */}
            <Typography
              fontSize="24px"
              fontWeight="800"
              lineHeight="28.64px"
              fontFamily="SF UI Display"
              color=" #000000"
              id="modal-modal-title"
              margin="1% 0"
            >
              Category
            </Typography>

            <Typography
              fontFamily="SF UI Display"
              fontSize="16px"
              fontWeight="300"
              lineHeight="19.09px"
              color="#000000"
              marginTop="10px"
              id="modal-modal-title"
              mb={8}
            >
              Add all the categories your company offers.
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          {selectedCategory === null ? (
            categories?.map((item: any, index: any) => (
              <Box
                key={index}
                borderRadius="10px"
                border="solid 1px #E8E8E8"
                padding="2%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1rem"
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: '#DFFFED',
                  },
                }}
                onClick={() => {
                  setSelectedCategory(item?.category);
                }}
              >
                <Box width="50%" display="flex" alignItems="center" gap="15px">
                  <Checkbox
                    checked={isChecked(item?.category)}
                    // onChange={() => {
                    //   handleServiceChange(item?.category);
                    // }}
                    sx={{
                      '&.Mui-checked': {
                        color: '#00BD57',
                      },
                    }}
                  />
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="19.09px"
                  >
                    {item?.category}
                  </Typography>
                </Box>
                <Box
                  width="50%"
                  display="flex"
                  justifyContent="flex-end"
                  paddingRight="2%"
                  onClick={() => {
                    setSelectedCategory(item?.category);
                  }}
                >
                  <img src={RightArrow} alt="" style={{ width: '24px', height: '24px' }} />
                </Box>
              </Box>
            ))
          ) : (
            <Box>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="700"
                fontSize="16px"
                lineHeight="19.09px"
                marginBottom="1rem"
              >
                {selectedCategory}
              </Typography>
              {categories
                ?.find((item: any) => item?.category === selectedCategory)
                ?.subCategories?.map((subcategory: any, index: number) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    gap="15px"
                    marginBottom="0.5rem"
                  >
                    <Checkbox
                      checked={checkedSubcategories[selectedCategory]?.[subcategory] || false}
                      onChange={() => {
                        handleSubcategoryChange(selectedCategory, subcategory);
                      }}
                      sx={{
                        '&.Mui-checked': {
                          color: '#00BD57',
                        },
                      }}
                    />
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                    >
                      {subcategory}
                    </Typography>
                  </Box>
                ))}
              <Button
                variant="contained"
                sx={{ bgcolor: '#00BD57', width: '50%', textTransform: 'unset' }}
                onClick={handleAddSubcategories}
              >
                {' '}
                Add
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'right'}
        marginTop={'2%'}
      >
        {' '}
        <Button
          onClick={handleErrorCheck}
          disabled={isButtonDisabled || loading}
          sx={{
            bgcolor: isButtonDisabled || loading ? '#f0f0f0' : '#47B45F',
            color: '#ffffff',
            border: isButtonDisabled || loading ? 'none' : '1px solid #47B45F',
            borderRadius: '8px',
            padding: '10px 16px',

            minWidth: '20%',
            textTransform: 'capitalize',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
            '&:hover': {
              bgcolor: '#47B45F',
            },
          }}
        >
          {loading ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            '            Save & Add Company   '
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default AddSupplier;
