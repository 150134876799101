/* eslint-disable @typescript-eslint/no-base-to-string */
import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Header from '../components/Header.component';
// import Star from '../assets/recommendedIcon.svg';
// import Complaint from '../assets/complaintIcon.svg';
// import Favourite from '../assets/favIcon.svg';
// import Share from '../assets/shareIcon.svg';
import GreenCheck from '../assets/greenCheck.svg';
import BackArrow from '../assets/arrowback.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Close } from '../assets/close.svg';
import request from '../api/request';
import { useLocation, useParams } from 'react-router-dom';
import { Company, ICompanyByIdResponse } from '../types/user';
import { mediaUrl } from '../config';
import { toast } from 'react-toastify';
import Dummyimage from '../assets/dummyimg.png';
import CustomToast from '../components/common/CustomToast';
import MapView from '../components/common/MapView';

const RequestDetail: FC = () => {
  const params = useParams();
  const { companyId } = params;
  const location = useLocation();
  const { updateRequest } = location?.state || {};
  // const [showWebsite, setShowWebsite] = useState(false);
  // const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  // const [showEmailAddress, setShowEmailAddress] = useState(false);
  const [company, setCompany] = useState<Company>();
  const [linkedAccounts, setLinkedAccounts] = useState<any>();
  const handleBackClick = (): void => {
    window.history.back();
  };

  // const handleWebsiteClick = (): void => {
  //   setShowWebsite(true);
  // };

  // const handlePhoneNumberClick = (): void => {
  //   setShowPhoneNumber(true);
  // };

  // const handleEmailAddressClick = (): void => {
  //   setShowEmailAddress(true);
  // };

  const calculateYears = (year: number): string => {
    const date = new Date().getFullYear();
    switch (date - year) {
      case 0:
        return year.toString();
      case 1:
        return 'A Year Ago';

      default:
        return `${date - year} Years Ago`;
    }
  };

  const getLinkedAccounts = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('get', `/users/getFellowMembers/${companyId}`)
      .then((res: any) => {
        console.log('linkedAccounts res', res);
        setLinkedAccounts(res?.users);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const handleApproved = (id: string, status: boolean): void => {
    request('patch', `/companies/${id}`, {
      approved: status,
    })
      .then(() => {
        // toast.success('Status Updated');
        toast(<CustomToast message={'Status Updated'} type="Success" />);
        getCompnyById();
      })
      .catch((err) => {
        // toast.error('Error');
        console.log('err', err);
      });
  };

  const getCompnyById = (): void => {
    if (updateRequest) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      request<ICompanyByIdResponse>('get', `/companies/updated/${companyId}`)
        .then((res) => {
          console.log(res);
          setCompany(res?.company);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      request<ICompanyByIdResponse>('get', `/companies/${companyId}`)
        .then((res) => {
          console.log(res);
          setCompany(res?.company);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  useEffect(() => {
    if (companyId) {
      getCompnyById();
      getLinkedAccounts();
    }
  }, [companyId]);
  // update apporve
  const handleUpdateApproved = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('put', `/companies/approveEdit/${companyId}`, {
      updateRequest: true,
    })
      .then(() => {
        // toast.success('Company Updated');
        toast(<CustomToast message={'Company Updated'} type="Success" />);
        getCompnyById();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  // end
  // update reject
  const handleUpdateReject = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('put', `/companies/approveEdit/${companyId}`, {
      updateRequest: false,
    })
      .then(() => {
        // toast.success('Company Updated');
        toast(<CustomToast message={'Company Updated'} type="Success" />);
        getCompnyById();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  // end
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={'column'}
      width="100%"
      position={'relative'}
    >
      <Header />
      <Box
        position={'absolute'}
        width={'30px'}
        left={'18%'}
        top={'110px'}
        onClick={handleBackClick}
        display={'flex'}
        gap={'10px'}
        sx={{
          cursor: 'pointer',
        }}
      >
        <img style={{ width: '100%', cursor: 'pointer' }} src={BackArrow} alt="BackArrow" />
        <Typography
          fontFamily={'SF UI Display'}
          fontWeight={600}
          // lineHeight={'20px'}
          color={'black'}
          fontSize={'14px'}
        >
          Back
        </Typography>
      </Box>
      <Box
        width={'50%'}
        marginBottom={'2%'}
        borderRadius={'12px'}
        border={'1px solid #EAECF0'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
        minHeight={'50vh'}
        display={'flex'}
        flexDirection={'column'}
        margin={'2% 0'}
        padding={'2% 1%'}
        gap={'30px'}
      >
        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'20px'}>
          <Box width={'130px'} height={'130px'}>
            {company?.companyInfo?.logo ? (
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                src={`${mediaUrl}/${company.companyInfo.logo}`}
                alt="logo"
              />
            ) : (
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={Dummyimage}
                alt="dummy logo"
              />
            )}
          </Box>
          <Box width={'75%'}>
            <Typography fontSize={'44px'} fontWeight={'700'} color={'#000000'}>
              {company?.companyInfo.name}
            </Typography>
            <Typography fontSize={'16px'} fontWeight={'300'} color={'#454238'}>
              {company?.companyInfo.description
                ? company?.companyInfo.description
                : 'No Description'}
            </Typography>
          </Box>
        </Box>
        <Box width={'100%'}>
          <Typography
            fontFamily={'SF UI Display'}
            fontWeight={900}
            lineHeight={'23.87px'}
            color={'black'}
            fontSize={'20px'}
          >
            Accounts Linked To:
          </Typography>
          {linkedAccounts?.map((item: any, index: number) => (
            <Box key={index} width={'100%'} display={'flex'} gap={'20px'} margin={'2% 0'}>
              <Box padding={'0'} width={'40%'}>
                <Typography
                  fontFamily={'Inter'}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  color="#344054"
                  fontWeight={600}
                >
                  {item?.first_name} {item?.last_name}
                </Typography>
                <Typography
                  fontFamily={'Inter'}
                  fontSize={'14px'}
                  lineHeight={'20px'}
                  color="#475467"
                  fontWeight={400}
                >
                  {item?.email}
                </Typography>
              </Box>
              <Box width={'48%'} display={'flex'} alignItems={'center'}>
                <Box
                  color={item.type === 'owner' ? '#067647' : '#FFFFFF'}
                  fontFamily={'Inter'}
                  fontSize={'12px'}
                  fontWeight={'400'}
                  lineHeight={'24px'}
                  bgcolor={item.type === 'owner' ? '#ECFDF3' : '#000000'}
                  border={'1px solid ' + (item.type === 'owner' ? '#ABEFC6' : '#000000')}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'4px'}
                  borderRadius={'50px'}
                  boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                  padding={'2px 6px'}
                  width={'fit-content'}
                  textTransform={'capitalize'}
                >
                  {item?.type === 'owner' ? 'Page Owner' : 'Team Member'}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        {company?.companyInfo?.companyType === 'local' && (
          <Box width={'100%'}>
            <Typography fontSize={'20px'} fontWeight={'800'} color={'#000000'}>
              Services
            </Typography>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              gap={'20px'}
              marginTop={'3%'}
              flexWrap={'wrap'}
            >
              {company?.services?.map((item, index) => {
                return (
                  <Typography
                    key={index}
                    display={'flex'}
                    alignItems={'center'}
                    gap={'5px'}
                    fontSize={'16px'}
                    fontWeight={'700'}
                    color={'#000000'}
                    sx={{ textDecoration: 'underline' }}
                  >
                    <img src={GreenCheck} alt="Check" />
                    {item.service ?? 'testing'}
                  </Typography>
                );
              })}
            </Box>
          </Box>
        )}
        {/* <Box width={'100%'} margin={'1% 0'}>
          <Typography fontSize={'20px'} fontWeight={'800'} color={'#000000'}>
            Equipments
          </Typography>
          <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'20px'} margin={'3% 0'}>
            <Typography
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
              fontSize={'16px'}
              fontWeight={'700'}
              color={'#000000'}
            >
              <img src={GreenCheck} alt="Check" />
              Crane
            </Typography>
            <Typography
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
              fontSize={'16px'}
              fontWeight={'700'}
              color={'#000000'}
            >
              <img src={GreenCheck} alt="Check" />
              Bucket Truck
            </Typography>
            <Typography
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
              fontSize={'16px'}
              fontWeight={'700'}
              color={'#000000'}
            >
              <img src={GreenCheck} alt="Check" />
              Service Truck
            </Typography>
          </Box>
        </Box> */}
        {company?.serviceArea?.[0]?.distance && (
          <Box width={'95%'} height={'300px'} marginTop={'2%'}>
            <MapView
              radiusInMiles={company?.serviceArea?.[0]?.distance}
              data={company?.companyInfo?.location}
            />
          </Box>
        )}

        <Box width="95%" border="solid 1px #E8E8E8" borderRadius="5px" padding="2% 4%">
          <Typography
            fontFamily="SF UI Display"
            fontWeight="900"
            fontSize="18px"
            lineHeight="21.48px"
            color="black"
          >
            Company Details
          </Typography>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="1% 0"
            gap={'20px'}
          >
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Location
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {company?.companyInfo?.address?.address}
            </Typography>
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Domain
            </Typography>
            {/* {!showWebsite ? (
              <Button
                onClick={handleWebsiteClick}
                sx={{
                  padding: '0',
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '19px',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                View
              </Button>
            ) : ( */}
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {company?.companyInfo.domain}
            </Typography>
            {/* )} */}
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Phone Number
            </Typography>
            {/* {!showPhoneNumber ? (
              <Button
                onClick={handlePhoneNumberClick}
                sx={{
                  padding: '0',
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '19px',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                Show
              </Button>
            ) : ( */}
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
              sx={{ opacity: '0.8' }}
            >
              +1 {formatPhoneNumber(company?.companyInfo?.phone)}
            </Typography>
            {/* )} */}
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Email Address
            </Typography>
            {/* {!showEmailAddress ? (
              <Button
                onClick={handleEmailAddressClick}
                sx={{
                  padding: '0',
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '19px',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                Show
              </Button>
            ) : ( */}
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {company?.companyInfo?.email}
            </Typography>
            {/* )} */}
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          {company?.companyInfo?.licence && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding="1% 0"
                gap={'20px'}
              >
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="19px"
                  fontFamily="SF UI Display"
                >
                  Contractor License #
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="19px"
                  fontFamily="SF UI Display"
                >
                  {company?.companyInfo?.licence}
                </Typography>
              </Box>
              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            </>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="1% 0"
            gap={'20px'}
          >
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Formation Year
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {calculateYears(Number(company?.companyInfo?.formation_year))}
            </Typography>
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="1% 0"
            gap={'20px'}
          >
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Company Size
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {company?.companyInfo?.company_size}
            </Typography>
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="1% 0"
            gap={'20px'}
          >
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Union Shop
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {company?.companyInfo?.union_shop}
            </Typography>
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="1% 0"
            gap={'20px'}
          >
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Response Time
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {company?.companyInfo?.responce_time}
            </Typography>
          </Box>
        </Box>
        {company?.companyInfo?.companyType === 'local' && !company?.serviceArea?.[0]?.distance && (
          <Box width="95%" border="solid 1px #E8E8E8" borderRadius="5px" padding="2% 4%">
            <Typography
              fontFamily="SF UI Display"
              fontWeight="800"
              fontSize="18px"
              lineHeight="23px"
              color="black"
            >
              Service States
            </Typography>
            <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="1% 0"
              gap={'5px'}
            >
              <Typography
                fontSize="14px"
                fontWeight="600"
                lineHeight="19px"
                fontFamily="SF UI Display"
              >
                Location
              </Typography>
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={'10px'}
                // width={'90%'}
                justifyContent={'right'}
                flexWrap={'wrap'}
              >
                {company?.serviceArea?.map((area, index) => {
                  return (
                    <Typography
                      key={index}
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="19px"
                      fontFamily="SF UI Display"
                      borderRadius={'5px'}
                      border={'1px solid #000'}
                      bgcolor={'#D7D7D7'}
                      padding={'5px 10px'}
                    >
                      {area.name}
                    </Typography>
                  );
                })}
              </Box>
            </Box>
            <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          </Box>
        )}
        <Box width={'95%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          {!company?.companyInfo?.verification && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
              gap="10px"
            >
              <Button
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  border: '1px solid #47B45F',
                  background: 'transparent',
                  color: '#454238B2',
                  textTransform: 'capitalize',
                  // '&:hover': {
                  //   bgcolor: '#47B45F',
                  // },
                }}
                onClick={() => {
                  if (company?._id) handleApproved(company?._id, true);
                }}
              >
                <Box>
                  <Check />
                </Box>
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Inter'}
                  lineHeight={'20px'}
                  color={'#454238B2'}
                >
                  Approve
                </Typography>
              </Button>
              <Button
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  border: '1px solid #FF5D39',
                  background: '#FFFFFF',
                  color: '#ffffff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: '#FFFFFF',
                  },
                }}
                onClick={() => {
                  if (company?._id) handleApproved(company?._id, false);
                }}
              >
                <Box>
                  {/* <img src={Close} alt="Check" /> */}
                  <Close color="#FF5D39" />
                </Box>
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Inter'}
                  lineHeight={'20px'}
                  color={'#FF5D39'}
                >
                  Reject
                </Typography>
              </Button>
            </Box>
          )}
          {/* {company?.companyInfo?.verification?.status === true && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Button
                disabled
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  background: 'rgba(71, 180, 95, 0.50)',
                  color: '#ffffff',
                  textTransform: 'capitalize',
                  mt: 3,
                }}
              >
                <Check color="#E1E1E1" />

                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Inter'}
                  lineHeight={'20px'}
                  color={'#ffffff'}
                >
                  Approved
                </Typography>
              </Button>
            </Box>
          )} */}
          {/* approved Conditions */}
          {company?.companyInfo?.verification?.status === true &&
            (company?.updateRequest ? (
              <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                <Button
                  onClick={(e): void => {
                    e.stopPropagation();
                    handleUpdateApproved();
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid #47B45F',
                    background: 'transparent',
                    color: '#454238B2',
                    textTransform: 'capitalize',
                    width: '48%',
                  }}
                >
                  Update
                </Button>
                <Button
                  onClick={(e): void => {
                    e.stopPropagation();
                    handleUpdateReject();
                  }}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid red',
                    background: '#FFFFFF',
                    color: 'red',
                    textTransform: 'capitalize',
                    width: '48%',
                    '&:hover': {
                      bgcolor: '#FFFFFF',
                    },
                  }}
                >
                  Reject
                </Button>
              </Box>
            ) : (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Button
                  disabled
                  fullWidth
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    background: 'rgba(71, 180, 95, 0.50)',
                    color: '#ffffff',
                    textTransform: 'capitalize',
                  }}
                >
                  <Check color="#E1E1E1" />
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'600'}
                    fontFamily={'Inter'}
                    lineHeight={'20px'}
                    color={'#ffffff'}
                  >
                    Approved
                  </Typography>
                </Button>
              </Box>
            ))}
          {company?.companyInfo?.verification?.status === false && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Button
                disabled
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  border: '1px solid rgba(255, 93, 57, 0.50)',
                  background: '#FFFFFF',
                  color: '#FF5D3980',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: '#FFFFFF',
                  },
                }}
              >
                <Box>
                  {/* <img src={Close} alt="Check" /> */}
                  <Close />
                </Box>
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Inter'}
                  lineHeight={'20px'}
                  color={'#FF5D3980'}
                >
                  Rejected
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestDetail;
