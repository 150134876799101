import React, { FC, useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../components/Header.component';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import request from '../api/request';
import moment from 'moment';
import { ApiResponse, Search } from '../types/user';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import { mediaUrl } from '../config';
import CustomToast from '../components/common/CustomToast';

const TopSearch: FC = () => {
  const [page, setPage] = useState(1);
  const [topsearchpage, settopsearchPage] = useState(1);
  const [topsearchTypepage, settopsearchTypePage] = useState(1);
  const [item, setItem] = useState<Search>();
  const [latestSearches, setLatestSearches] = useState<Search[]>([]);
  const [topSearchTypes, setTopSearchTypes] = useState<Search[]>([]);
  const [topsearch, setTopSearch] = useState<Search[]>([]);
  console.log('item', item);
  const [loading, setLoading] = useState(true);
  const [loadingTopCompanies, setLoadingTopCompanies] = useState(true);
  const [loadingTopCategories, setLoadingTopCategories] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const searchRef = useRef<HTMLDivElement>(null);
  const [showSearch, setShowSearch] = useState(false);

  const handleSearchClick = (): void => {
    setShowSearch(true);
  };
  const handleClickOutside = (event: MouseEvent): void => {
    if (
      searchRef.current &&
      !searchRef.current.contains(event.target as Node) &&
      searchQuery === ''
    ) {
      setShowSearch(false);
    }
  };
  const filteredCompanies = topsearch.filter((company: any) =>
    company?.companyInfo?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const itemsPerPage = 8;
  const totalPages = Math.ceil(latestSearches.length / itemsPerPage);
  const totalPagestopsearch = Math.ceil(filteredCompanies.length / itemsPerPage);
  const totalPagestopSearchTypes = Math.ceil(topSearchTypes.length / itemsPerPage);
  useEffect(() => {
    getAllSearch();
    getTopSearch();
    getTopSearchCategories();
  }, [page, itemsPerPage]);

  const getAllSearch = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<ApiResponse>(
      'get',
      `/companies/search/stats/search?page=${page}&pageSize=${itemsPerPage}`,
    )
      .then((res) => {
        console.log('res', res);
        setLoading(false);
        setLatestSearches(res?.searches);
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
  };

  const getTopSearch = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<any>('get', `/companies/search/stats/top`)
      .then((res) => {
        console.log('res2', res);
        setTopSearch(res?.companies);
        setLoadingTopCompanies(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoadingTopCompanies(false);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
  };
  const getTopSearchCategories = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<any>('get', `/companies/search/stats/types`)
      .then((res) => {
        console.log('res3', res);
        setTopSearchTypes(res?.searchTypes);
        setLoadingTopCategories(false);
      })
      .catch((err) => {
        console.log('err', err);
        setLoadingTopCategories(false);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
  };

  const handlePreviousPage = (): void => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (): void => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPagetopsearch = (): void => {
    settopsearchPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPagetopsearch = (): void => {
    settopsearchPage((prevPage) => Math.min(prevPage + 1, totalPagestopsearch));
  };

  // eslint-disable-next-line
  const handlePreviousPagetopsearchType = (): void => {
    settopsearchTypePage((prevPage) => Math.max(prevPage - 1, 1));
  };
  // eslint-disable-next-line
  const handleNextPagetopsearchType = (): void => {
    settopsearchTypePage((prevPage) => Math.min(prevPage + 1, totalPagestopSearchTypes));
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = latestSearches.slice(startIndex, endIndex);

  const startIndextopsearch = (topsearchpage - 1) * itemsPerPage;
  const endIndextopsearch = startIndextopsearch + itemsPerPage;
  const paginatedRowstopsearch = filteredCompanies.slice(startIndextopsearch, endIndextopsearch);

  const startIndextopsearchType = (topsearchTypepage - 1) * itemsPerPage;
  const endIndextopsearchType = startIndextopsearchType + itemsPerPage;
  const paginatedRowstopsearchType = topSearchTypes.slice(
    startIndextopsearchType,
    endIndextopsearchType,
  );

  const [open, setOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  useEffect(() => {
    settopsearchPage(1);
  }, [searchQuery]);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchQuery]);

  console.log('paginatedRowstopsearchType', paginatedRowstopsearchType, loading);
  console.log('paginatedRows', paginatedRows);

  return (
    <Box display="flex" alignItems="center" flexDirection={'column'} width="100%">
      <Header />
      <Box
        width={'74%'}
        marginBottom={'2%'}
        borderRadius={'12px'}
        border={'1px solid #EAECF0'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
        minHeight={'50vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        margin={'2% 0'}
      >
        <Box width={'100%'}>
          <Box width={'100%'} padding={'20px 24px'} borderBottom={'1px solid #EAECF0'}>
            <Typography
              color={'#101828'}
              fontFamily={'Inter'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'28px'}
            >
              Latest Searches
            </Typography>
          </Box>
          <Box width={'100%'}>
            <TableContainer sx={{ display: 'flex', flexDirection: 'column' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: '25%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        padding: '0 24px',
                      }}
                    >
                      Time of Search
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        // padding: '0 24px',
                      }}
                    >
                      Company Type
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        // padding: '0 24px',
                      }}
                    >
                      Search Keyword
                    </TableCell>

                    <TableCell
                      sx={{
                        width: '15%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Location / Category
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '10%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Search Results
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRows?.length
                    ? paginatedRows.map((item: Search, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': { backgroundColor: '#FADE701A' },
                          }}
                        >
                          <TableCell
                            sx={{
                              width: '25%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                              padding: '16px 24px',
                            }}
                            component="th"
                            scope="row"
                          >
                            <Typography>
                              {moment(item?.updatedAt).format('DD - MMM - YYYY')}
                            </Typography>
                            <Typography
                              color={'#475467'}
                              fontFamily={'Inter'}
                              fontSize={'14px'}
                              fontWeight={'400'}
                              lineHeight={'20px'}
                            >
                              {moment(item?.updatedAt).format('HH:mm:ss')}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '10%',
                            }}
                          >
                            <Box
                              color={
                                // item?.type === 'local'
                                // ? '#F9F5FF'
                                // : item?.type === 'national'
                                // ? '#EFF8FF'
                                // : '#EEF4FF'
                                'transparent'
                              }
                              border={
                                // item?.companyType === 'Local'
                                //   ? '1px solid #E9D7FE'
                                //   : item?.companyType === 'National'
                                //   ? '1px solid #B2DDFF'
                                //   : '1px solid #C7D7FE'
                                '1px solid #D0D5DD'
                              }
                              bgcolor={
                                // item?.type === 'local'
                                //   ? '#F9F5FF'
                                //   : item?.type === 'national'
                                //   ? '#EFF8FF'
                                //   : '#EEF4FF'
                                'transparent'
                              }
                              borderRadius={'6px'}
                              padding={'2px 8px'}
                              width={'fit-content'}
                              display={'flex'}
                              justifyContent={'center'}
                              alignItems={'center'}
                              gap={'5px'}
                            >
                              <Box
                                width={'7px'}
                                height={'7px'}
                                borderRadius={'3.5px'}
                                bgcolor={
                                  item?.type === 'local'
                                    ? '#17B26A'
                                    : item?.type === 'national'
                                    ? '#667085'
                                    : '#2E90FA'
                                }
                              />
                              <Typography
                                fontFamily={'Inter'}
                                fontSize={'12px'}
                                fontWeight={'500'}
                                lineHeight={'18px'}
                                whiteSpace={'nowrap'}
                                color={'#344054'}
                                textTransform={'capitalize'}
                              >
                                {item?.type === 'local' || item?.type === 'national'
                                  ? `${item?.type} Company`
                                  : item?.type}
                              </Typography>
                            </Box>
                          </TableCell>
                          {/* search keyword */}
                          <TableCell
                            sx={{
                              width: '20%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'12px'}>
                              <Box>
                                <Typography
                                  color={'#101828'}
                                  fontFamily={'Inter'}
                                  fontSize={'14px'}
                                  fontWeight={'500'}
                                  lineHeight={'20px'}
                                >
                                  {item?.search?.length ? item?.search : '-'}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          {/*  */}
                          <TableCell
                            sx={{
                              width: '15%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Typography
                              color={'#667085'}
                              fontFamily={'Inter'}
                              fontSize={'16px'}
                              fontWeight={'400'}
                              lineHeight={'24px'}
                            >
                              {item?.additional?.length ? item?.additional : '-'}
                              {/* {item?.location && item.location.length > 36
                            ? `${item.location.slice(0, 36)}...`
                            : item.location} */}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '10%',
                            }}
                          >
                            <Button
                              sx={{
                                fontWeight: '600',
                                lineHeight: '20px',
                                fontFamily: 'inter',
                                fontSize: '14px',
                                border: 'solid 1px #D0D5DD',
                                color: '#344054',
                                width: '124px',
                                height: '40px',
                                whiteSpace: 'pre',
                                borderRadius: '8px',
                                textTransform: 'capitalize',
                              }}
                              onClick={() => {
                                handleOpen();
                                setItem(item);
                              }}
                            >
                              Show Results
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
              {!paginatedRows?.length ? (
                <Typography
                  color={'#101828'}
                  fontFamily={'Inter'}
                  fontSize={'18px'}
                  fontWeight={'600'}
                  lineHeight={'28px'}
                  padding={'2% 5%'}
                  whiteSpace={'nowrap'}
                >
                  {loading ? <CircularProgress size={15} color="inherit" /> : 'No Searches Found'}
                </Typography>
              ) : (
                ''
              )}
            </TableContainer>
          </Box>
        </Box>
        {paginatedRows?.length ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="32px"
            sx={{
              '@media (max-width:520px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}
          >
            <Box display={'flex'} alignItems={'center'} gap={'12px'}>
              <Button
                variant="outlined"
                onClick={handlePreviousPage}
                disabled={page === 1}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={page === totalPages}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Next
              </Button>
            </Box>
            <Typography
              fontFamily="Inter"
              lineHeight="20px"
              fontSize="14px"
              color="#344054"
              fontWeight="500"
            >
              Page {page} of {Math.ceil(latestSearches.length / itemsPerPage)}
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Box width={'100%'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        <Box
          width={'37%'}
          marginBottom={'2%'}
          borderRadius={'12px'}
          border={'1px solid #EAECF0'}
          bgcolor={'#FFF'}
          boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
          minHeight={'50vh'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          margin={'2% 1%'}
        >
          <Box width={'100%'}>
            <Box
              width={'100%'}
              padding={'20px 24px'}
              borderBottom={'1px solid #EAECF0'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography
                color={'#101828'}
                fontFamily={'Inter'}
                fontSize={'18px'}
                fontWeight={'600'}
                lineHeight={'28px'}
              >
                Company Search View
              </Typography>
              <Box ref={searchRef}>
                {showSearch && (
                  <TextField
                    placeholder="Search Company"
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    fullWidth
                    sx={{
                      fontSize: '16px',
                      fontWeight: '400',
                      // lineHeight: '24px',
                      color: '#667085',
                      borderRadius: '8px',
                      '.MuiOutlinedInput-input': {
                        padding: '6px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                  />
                )}
                {!showSearch && (
                  <SearchIcon onClick={handleSearchClick} style={{ cursor: 'pointer' }} />
                )}
              </Box>
            </Box>
            <Box width={'100%'}>
              <TableContainer
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: '25%',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '18px',
                          color: '#475467',
                          fontFamily: 'Inter',
                          whiteSpace: 'nowrap',
                          padding: '0 24px',
                        }}
                      >
                        Company
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '20%',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '18px',
                          color: '#475467',
                          fontFamily: 'Inter',
                          whiteSpace: 'nowrap',
                          // padding: '0 24px',
                          textAlign: 'end',
                        }}
                      >
                        Number of Search Appearance
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedRowstopsearch?.length
                      ? paginatedRowstopsearch.map((item: any, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              '&:hover': { backgroundColor: '#FADE701A' },
                            }}
                          >
                            <TableCell
                              sx={{
                                width: '25%',
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#101828',
                                fontFamily: 'Urbanist',
                                whiteSpace: 'nowrap',
                                padding: '16px 24px',
                              }}
                              component="th"
                              scope="row"
                            >
                              <Typography>{item?.companyInfo?.name}</Typography>
                              <Typography
                                color={'#475467'}
                                fontFamily={'Inter'}
                                fontSize={'14px'}
                                fontWeight={'400'}
                                lineHeight={'20px'}
                              >
                                {/* {moment(item?.updatedAt).format('HH:mm:ss')} */}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '10%',
                              }}
                            >
                              <Box
                                borderRadius={'6px'}
                                padding={'2px 8px'}
                                width={'100%'}
                                display={'flex'}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                                gap={'5px'}
                              >
                                <Box />
                                <Typography
                                  fontFamily={'Inter'}
                                  fontSize={'12px'}
                                  fontWeight={'500'}
                                  lineHeight={'18px'}
                                  whiteSpace={'nowrap'}
                                  color={'#344054'}
                                >
                                  {item?.searchCount}
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableBody>
                </Table>
                {!paginatedRowstopsearch?.length ? (
                  <Typography
                    color={'#101828'}
                    fontFamily={'Inter'}
                    fontSize={'18px'}
                    fontWeight={'600'}
                    lineHeight={'28px'}
                    padding={'2% 5%'}
                    whiteSpace={'nowrap'}
                  >
                    {loadingTopCompanies ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      'No Companies Found'
                    )}
                  </Typography>
                ) : (
                  ''
                )}
              </TableContainer>
            </Box>
          </Box>
          {paginatedRowstopsearch?.length ? (
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="32px"
              sx={{
                '@media (max-width:520px)': {
                  flexDirection: 'column',
                  gap: '10px',
                },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'12px'}>
                <Button
                  variant="outlined"
                  onClick={handlePreviousPagetopsearch}
                  disabled={topsearchpage === 1}
                  sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid #D0D5DD',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '20px',
                    color: '#344054',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    textTransform: 'capitalize',
                    '&.Mui-disabled': {
                      borderColor: '#E0E0E0',
                      color: '#E0E0E0',
                    },
                  }}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleNextPagetopsearch}
                  disabled={topsearchpage === totalPagestopsearch}
                  sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid #D0D5DD',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '20px',
                    color: '#344054',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    textTransform: 'capitalize',
                    '&.Mui-disabled': {
                      borderColor: '#E0E0E0',
                      color: '#E0E0E0',
                    },
                  }}
                >
                  Next
                </Button>
              </Box>
              <Typography
                fontFamily="Inter"
                lineHeight="20px"
                fontSize="14px"
                color="#344054"
                fontWeight="500"
              >
                Page {topsearchpage} of {Math.ceil(filteredCompanies?.length / itemsPerPage)}
              </Typography>
            </Box>
          ) : (
            ''
          )}
        </Box>

        <Box
          width={'37%'}
          marginBottom={'2%'}
          borderRadius={'12px'}
          border={'1px solid #EAECF0'}
          bgcolor={'#FFF'}
          boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
          minHeight={'50vh'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          margin={'2% 1%'}
        >
          <Box width={'100%'}>
            <Box width={'100%'} padding={'20px 24px'} borderBottom={'1px solid #EAECF0'}>
              <Typography
                color={'#101828'}
                fontFamily={'Inter'}
                fontSize={'18px'}
                fontWeight={'600'}
                lineHeight={'28px'}
              >
                Top Searched Categories
              </Typography>
            </Box>
            <Box width={'100%'}>
              <TableContainer
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: '25%',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '18px',
                          color: '#475467',
                          fontFamily: 'Inter',
                          whiteSpace: 'nowrap',
                          padding: '0 24px',
                        }}
                      >
                        Company Type
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '20%',
                          fontSize: '12px',
                          fontWeight: '500',
                          lineHeight: '18px',
                          color: '#475467',
                          fontFamily: 'Inter',
                          whiteSpace: 'nowrap',
                          // padding: '0 24px',
                          textAlign: 'end',
                        }}
                      >
                        Number of Searches
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedRowstopsearchType?.length
                      ? paginatedRowstopsearchType.map((item: any, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              '&:hover': { backgroundColor: '#FADE701A' },
                            }}
                          >
                            <TableCell
                              sx={{
                                width: '10%',
                              }}
                            >
                              <Box
                                color={
                                  item?.type === 'local'
                                    ? '#6941C6'
                                    : item?.type === 'national'
                                    ? '#175CD3'
                                    : '#3538CD'
                                }
                                border={
                                  // item?.companyType === 'Local'
                                  //   ? '1px solid #E9D7FE'
                                  //   : item?.companyType === 'National'
                                  //   ? '1px solid #B2DDFF'
                                  //   : '1px solid #C7D7FE'
                                  '1px solid #D0D5DD'
                                }
                                bgcolor={
                                  // item?.companyType === 'Local'
                                  //   ? '#F9F5FF'
                                  //   : item?.companyType === 'National'
                                  //   ? '#EFF8FF'
                                  //   : '#EEF4FF'
                                  'transparent'
                                }
                                borderRadius={'6px'}
                                padding={'2px 8px'}
                                width={'fit-content'}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                gap={'5px'}
                                textTransform={'capitalize'}
                              >
                                <Box
                                  width={'7px'}
                                  height={'7px'}
                                  borderRadius={'3.5px'}
                                  bgcolor={
                                    item?.type === 'local'
                                      ? '#17B26A'
                                      : item?.type === 'national'
                                      ? '#667085'
                                      : '#2E90FA'
                                  }
                                  textTransform={'capitalize'}
                                />
                                <Typography
                                  fontFamily={'Inter'}
                                  fontSize={'12px'}
                                  fontWeight={'500'}
                                  lineHeight={'18px'}
                                  whiteSpace={'nowrap'}
                                  color={'#344054'}
                                >
                                  {item?.type === 'local'
                                    ? 'Local Company'
                                    : item?.type === 'national'
                                    ? 'National Company'
                                    : item?.type}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                width: '25%',
                                fontSize: '14px',
                                fontWeight: '500',
                                color: '#101828',
                                fontFamily: 'Urbanist',
                                whiteSpace: 'nowrap',
                                padding: '16px 24px',
                                textAlign: 'end',
                              }}
                              component="th"
                              scope="row"
                            >
                              <Typography>{item?.count}</Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      : ''}
                  </TableBody>
                </Table>
                {!paginatedRowstopsearchType?.length ? (
                  <Typography
                    color={'#101828'}
                    fontFamily={'Inter'}
                    fontSize={'18px'}
                    fontWeight={'600'}
                    lineHeight={'28px'}
                    padding={'2% 5%'}
                    whiteSpace={'nowrap'}
                  >
                    {loadingTopCategories ? (
                      <CircularProgress size={15} color="inherit" />
                    ) : (
                      'No Results Found'
                    )}
                  </Typography>
                ) : (
                  ''
                )}
              </TableContainer>
            </Box>
          </Box>
          {paginatedRowstopsearchType?.length ? (
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="32px"
              sx={{
                '@media (max-width:520px)': {
                  flexDirection: 'column',
                  gap: '10px',
                },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'12px'}>
                <Button
                  variant="outlined"
                  onClick={handlePreviousPagetopsearchType}
                  disabled={topsearchTypepage === 1}
                  sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid #D0D5DD',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '20px',
                    color: '#344054',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    textTransform: 'capitalize',
                    '&.Mui-disabled': {
                      borderColor: '#E0E0E0',
                      color: '#E0E0E0',
                    },
                  }}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  onClick={handleNextPagetopsearchType}
                  disabled={topsearchTypepage === totalPagestopSearchTypes}
                  sx={{
                    backgroundColor: 'transparent',
                    border: '1px solid #D0D5DD',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '20px',
                    color: '#344054',
                    padding: '8px 12px',
                    borderRadius: '8px',
                    textTransform: 'capitalize',
                    '&.Mui-disabled': {
                      borderColor: '#E0E0E0',
                      color: '#E0E0E0',
                    },
                  }}
                >
                  Next
                </Button>
              </Box>
              <Typography
                fontFamily="Inter"
                lineHeight="20px"
                fontSize="14px"
                color="#344054"
                fontWeight="500"
              >
                Page {topsearchTypepage} of {Math.ceil(topSearchTypes.length / itemsPerPage)}
              </Typography>
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            height: '60vh',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            // border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
            overflow: 'scroll',
          }}
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
          </Box>
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            fontSize={'12px'}
            fontWeight={500}
            lineHeight={'18px'}
            fontFamily={'SF UI Display'}
            borderBottom="solid 1px #EAECF0"
            padding={'5% 0%'}
            color={'#475467'}
          >
            Search Results
          </Typography>
          <Box display="flex" gap="5px" flexDirection={'column'}>
            {item?.results?.map((result, index) => (
              <Box
                key={index}
                display={'flex'}
                gap={'15px'}
                alignItems={'center'}
                borderBottom={'solid 1px #EAECF0'}
              >
                <Avatar
                  src={
                    result?.companyInfo?.logo
                      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        `${mediaUrl}/${result?.companyInfo?.logo}`
                      : ''
                  }
                  sx={{
                    width: '40px',
                    height: '40px',
                  }}
                />
                <Box width="90%" display={'flex'} flexDirection={'column'}>
                  <Typography id="modal-description">
                    <Typography
                      id="modal-title"
                      variant="h6"
                      component="h2"
                      fontWeight={500}
                      fontSize="14px"
                      fontFamily="SF UI Display"
                      lineHeight="20px"
                      padding="3% 0 2% 0"
                      color="#101828"
                    >
                      {result?.companyInfo?.name ?? 'No Company Found'}
                    </Typography>
                    <Typography
                      id="modal-title"
                      variant="h6"
                      component="h2"
                      fontWeight={300}
                      color="rgba(71, 84, 103, 1)"
                      fontSize="14px"
                      fontFamily="SF UI Display"
                      lineHeight="20px"
                      padding="0 0 3% 0"
                    >
                      <Tooltip title={result?.companyInfo?.description ?? 'No Description'} arrow>
                        <span style={{ cursor: 'pointer' }}>
                          {(result?.companyInfo?.description as string)?.length > 100
                            ? (result?.companyInfo?.description as string)?.substring(0, 100) +
                              '...'
                            : result?.companyInfo?.description?.length
                            ? result?.companyInfo?.description
                            : 'No Description'}
                        </span>
                      </Tooltip>
                    </Typography>
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TopSearch;
