/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Header from '../components/Header.component';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Close } from '../assets/close.svg';
import { toast } from 'react-toastify';
import request from '../api/request';
import { IClaims, IClaimsResponse } from '../types/user';
import { mediaUrl } from '../config';
import Edit from '../assets/companyIcon.svg';
import ClaimArrow from '../assets/claimArrow.svg';
import { useNavigate } from 'react-router-dom';
import CustomToast from '../components/common/CustomToast';

const ClaimCompany: FC = () => {
  const nav = useNavigate();
  const [page, setPage] = useState(1);
  const [claims, setClaims] = useState<IClaims[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [approveLoad, setApproveLoad] = useState<any>(null);
  const [rejectLoad, setRejectLoad] = useState<any>(null);
  const [dataLoading, setDataLoading] = React.useState(true);
  console.log('claims', claims);
  // const sortClaimsByOwner = (claims: IClaims[] | undefined): IClaims[] => {
  //   if (!claims) {
  //     // Handle the case where claims might be undefined
  //     return [];
  //   }

  //   return claims.sort((a, b) => {
  //     const aHasOwner = !!a.company?.companyInfo?.owner;
  //     const bHasOwner = !!b.company?.companyInfo?.owner;

  //     // If a doesn't have owner but b does, a should come first
  //     if (!aHasOwner && bHasOwner) return -1;
  //     // If b doesn't have owner but a does, b should come first
  //     if (aHasOwner && !bHasOwner) return 1;

  //     // If both have or both don't have owner, maintain current order
  //     return 0;
  //   });
  // };
  const sortClaimsByStatus = (claims: any[]): any[] => {
    return claims.sort((a, b) => {
      // Sort by status: "pending" first, then others
      if (a.status === 'pending' && b.status !== 'pending') return -1;
      if (a.status !== 'pending' && b.status === 'pending') return 1;

      // If both statuses are the same, sort by createdAt (newest first)
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  };
  const getClaims = (): void => {
    setDataLoading(true);
    request<IClaimsResponse>('get', `/companies/claim`)
      .then((res) => {
        console.log('res searcchh', res);
        const sortedClaims = sortClaimsByStatus(res?.claims);
        setClaims(sortedClaims);
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
        console.log('err', err);
      });
  };

  useEffect(() => {
    getClaims();
  }, []);

  const itemsPerPage = 9;
  const totalPages = Math.ceil(claims?.length / itemsPerPage);

  const handlePreviousPage = (): void => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (): void => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleApproved = (id: string, status: string): void => {
    setLoading(true);
    if (status === 'approved') {
      setApproveLoad(id);
    } else {
      setRejectLoad(id);
    }
    request('patch', `/companies/claim/${id}`, {
      status,
    })
      .then(() => {
        if (status === 'approved') {
          // toast.success('Approved Succesfully');
          toast(<CustomToast message={'Approved Succesfully'} type="Success" />);
        } else {
          // toast.success('Rejected Succesfully');
          toast(<CustomToast message={'Rejected Succesfully'} type="Success" />);
        }
        getClaims();
        setLoading(false);
        setApproveLoad(null);
        setRejectLoad(null);
      })
      .catch((err) => {
        console.log('err', err);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
        setLoading(false);
        setApproveLoad(null);
        setRejectLoad(null);
      });
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = claims?.length && claims?.slice(startIndex, endIndex);
  console.log('paginatedRows claim', claims);
  return (
    <Box width={'100%'}>
      <Header />
      <Box
        width={'74%'}
        margin={'1% 13%'}
        borderRadius={'12px'}
        border={'1px solid #EAECF0'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
        minHeight={'80vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Box width={'100%'}>
          <Box width={'100%'} padding={'20px 24px'} borderBottom={'1px solid #EAECF0'}>
            <Typography
              color={'#101828'}
              fontFamily={'Inter'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'28px'}
            >
              Claim Requests
            </Typography>
          </Box>
          <Box width={'100%'}>
            <TableContainer sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Table aria-label="simple table" sx={{ width: '100%' }}>
                <TableHead sx={{ width: '100%' }}>
                  <TableRow sx={{ width: '100%' }}>
                    <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        padding: '0 24px',
                      }}
                    >
                      User
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '25%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Job Title
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '25%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Company Claimed
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '30%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Approve / Reject
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {claims?.length && paginatedRows
                    ? paginatedRows?.map((claim: any, index: any): any => (
                        <TableRow
                          key={index}
                          sx={{
                            cursor: 'pointer',
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': { backgroundColor: '#FADE701A' },
                          }}
                          onClick={() => {
                            /* eslint-disable @typescript-eslint/restrict-template-expressions */
                            nav(`/dashboard/claimdetails/${claim?._id}`);
                          }}
                        >
                          <TableCell
                            sx={{
                              width: '30%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                              padding: '16px 24px',
                            }}
                            component="th"
                            scope="row"
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              gap={'12px'}
                            >
                              <Box>
                                <Typography
                                  color={'#101828'}
                                  fontFamily={'SF UI Display'}
                                  fontSize={'14px'}
                                  fontWeight={'500'}
                                  lineHeight={'20px'}
                                  textTransform={'capitalize'}
                                >
                                  {claim?.user?.first_name} {claim?.user?.last_name}
                                </Typography>
                                <Typography
                                  color={'#101828'}
                                  fontFamily={'SF UI Display'}
                                  fontSize={'14px'}
                                  fontWeight={'300'}
                                  lineHeight={'20px'}
                                >
                                  {claim?.user?.email}
                                </Typography>
                              </Box>
                              <Box>
                                <img src={ClaimArrow} alt="." />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '25%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Typography
                              color={'#101828'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              textTransform={'capitalize'}
                              lineHeight={'20px'}
                            >
                              {claim?.user?.job_title ? claim?.user?.job_title : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '25%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box
                              padding={'2px 6px'}
                              alignItems={'center'}
                              width={'fit-content'}
                              display={'flex'}
                              gap={'15px'}
                            >
                              <Box width={'40px'} height={'40px'}>
                                <Avatar
                                  src={`${mediaUrl}/${claim?.company?.companyInfo?.logo}`}
                                  alt=""
                                />
                              </Box>
                              <Typography
                                color={'#101828'}
                                fontFamily={'SF UI Display'}
                                fontSize={'14px'}
                                fontWeight={'500'}
                                textTransform={'capitalize'}
                                lineHeight={'20px'}
                              >
                                {claim?.company?.companyInfo.name}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '20%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box
                              display={'flex'}
                              justifyContent={'space-between'}
                              alignItems={'center'}
                              width={'100%'}
                              gap={'15px'}
                            >
                              <Box
                                width={'90%'}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                              >
                                {claim.status === 'pending' && (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                    gap="8px"
                                  >
                                    <Button
                                      disabled={loading}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        padding: '10px 14px',
                                        borderRadius: '8px',
                                        border: '1px solid #47B45F',
                                        background: 'transparent',
                                        color: '#454238B2',
                                        textTransform: 'capitalize',
                                        width: '100%',
                                        // '&:hover': {
                                        //   bgcolor: '#47B45F',
                                        // },
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleApproved(claim?._id, 'approved');
                                      }}
                                    >
                                      {approveLoad === claim?._id ? (
                                        <CircularProgress size={15} color="inherit" />
                                      ) : (
                                        <>
                                          <Box>
                                            <Check />
                                          </Box>
                                          <Typography
                                            fontSize={'14px'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter'}
                                            lineHeight={'20px'}
                                            color={'#454238B2'}
                                          >
                                            Approve
                                          </Typography>
                                        </>
                                      )}
                                    </Button>
                                    <Button
                                      disabled={loading}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        padding: '10px 14px',
                                        borderRadius: '8px',
                                        border: '1px solid #FF5D39',
                                        background: '#FFFFFF',
                                        color: '#ffffff',
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                          bgcolor: '#FFFFFF',
                                        },
                                        width: '100%',
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleApproved(claim?._id, 'rejected');
                                      }}
                                    >
                                      {rejectLoad === claim?._id ? (
                                        <CircularProgress size={15} color="inherit" />
                                      ) : (
                                        <>
                                          <Box>
                                            {/* <img src={Close} alt="Check" /> */}
                                            <Close color="#FF5D39" />
                                          </Box>
                                          <Typography
                                            fontSize={'14px'}
                                            fontWeight={'600'}
                                            fontFamily={'Inter'}
                                            lineHeight={'20px'}
                                            color={'#FF5D39'}
                                          >
                                            Reject
                                          </Typography>
                                        </>
                                      )}
                                    </Button>
                                  </Box>
                                )}
                                {claim.status === 'approved' && (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                  >
                                    <Button
                                      disabled
                                      fullWidth
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        padding: '10px 14px',
                                        borderRadius: '8px',
                                        background: 'rgba(71, 180, 95, 0.50)',
                                        color: '#ffffff',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <Box>
                                        <Check color="#E1E1E1" />
                                      </Box>
                                      <Typography
                                        fontSize={'14px'}
                                        fontWeight={'600'}
                                        fontFamily={'Inter'}
                                        lineHeight={'20px'}
                                        color={'#ffffff'}
                                      >
                                        Claim Approved
                                      </Typography>
                                    </Button>
                                  </Box>
                                )}
                                {claim.status === 'rejected' && (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                  >
                                    <Button
                                      disabled
                                      fullWidth
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        padding: '10px 14px',
                                        borderRadius: '8px',
                                        border: '1px solid rgba(255, 93, 57, 0.50)',
                                        background: '#FFFFFF',
                                        color: '#FF5D3980',
                                        textTransform: 'capitalize',
                                        '&:hover': {
                                          bgcolor: '#FFFFFF',
                                        },
                                      }}
                                    >
                                      <Box>
                                        <Close color="#FF5D39" opacity={0.5} />
                                      </Box>
                                      <Typography
                                        fontSize={'14px'}
                                        fontWeight={'600'}
                                        fontFamily={'Inter'}
                                        lineHeight={'20px'}
                                        color={'#FF5D3980'}
                                      >
                                        Claim Rejected
                                      </Typography>
                                    </Button>
                                  </Box>
                                )}
                              </Box>
                              <Box width={'16.67px'} height={'16.67px'}>
                                <img src={Edit} alt="." />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
              {!claims?.length ? (
                <Typography
                  color={'#101828'}
                  fontFamily={'Inter'}
                  fontSize={'18px'}
                  fontWeight={'600'}
                  lineHeight={'28px'}
                  padding={'2% 5%'}
                  whiteSpace={'nowrap'}
                >
                  {dataLoading ? <CircularProgress size={15} color="inherit" /> : 'No Claims Found'}
                </Typography>
              ) : (
                ''
              )}
            </TableContainer>
          </Box>
        </Box>
        {claims?.length ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="32px"
            sx={{
              '@media (max-width:520px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}
          >
            <Box display={'flex'} alignItems={'center'} gap={'12px'}>
              <Button
                variant="outlined"
                onClick={handlePreviousPage}
                disabled={page === 1}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={page === totalPages}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Next
              </Button>
            </Box>
            <Typography
              fontFamily="Inter"
              lineHeight="20px"
              fontSize="14px"
              color="#344054"
              fontWeight="500"
            >
              Page {page} of {Math.ceil(claims?.length / itemsPerPage)}
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default ClaimCompany;
