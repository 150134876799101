import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import CrossIcon from '../assets/DesignUpdate/toastClose.svg';
import Error from '../assets/DesignUpdate/toastError.svg';
import Always from '../assets/DesignUpdate/reviewAlways.svg';
import Often from '../assets/DesignUpdate/reviewOften.svg';
import Sometimes from '../assets/DesignUpdate/reviewSometimes.svg';
import Rarely from '../assets/DesignUpdate/reviewRarely.svg';
import Never from '../assets/DesignUpdate/reviewNever.svg';
import Success from '../assets/DesignUpdate//toastSuccess.svg';
// import VerticalLinearStepper from '../components/phase1a/common/ReviewStepper.component';
import VerticalLinearStepper from '../components/common/ReviewStepper.component';
// import { mediaUrl } from '../config';
import Dummyimage from '../assets/dummyimg.png';
import UserAvatar from '../assets/DesignUpdate/userAvatar.svg';
import { useLocation, useNavigate } from 'react-router-dom';
// import request from '../api/request';
import { toast } from 'react-toastify';
import StarIcon from '@mui/icons-material/Star';
import DoneIcon from '@mui/icons-material/Done';
import request from '../api/request';
import CustomToast from '../components/common/CustomToast';
import { mediaUrl } from '../config';
// import CustomToast from '../components/phase1a/common/CustomToast.component';
interface CheckboxUserData {
  avatarSrc?: string;
  name: string;
  designation: string;
  companyInfo: string;
  job_title?: string;
}
interface Guideline {
  src: string;
  text: string;
}
const deliveryOptions = [
  {
    id: 1,
    label: 'Always',
    description: 'They deliver on time and finish what they start.',
    imageSrc: Always,
    rating: 5,
  },
  {
    id: 2,
    label: 'Often',
    description: 'They deliver most projects on time and finish what they start.',
    imageSrc: Often,
    rating: 4,
  },
  {
    id: 3,
    label: 'Sometimes',
    description: 'They deliver on some projects and miss on others.',
    imageSrc: Sometimes,
    rating: 3,
  },
  {
    id: 4,
    label: 'Rarely',
    description: 'They have a hard time delivering in general.',
    imageSrc: Rarely,
    rating: 2,
  },
  {
    id: 5,
    label: 'Never',
    description: 'They don’t seem capable of delivering on any projects.',
    imageSrc: Never,
    rating: 1,
  },
];

const EditReview: FC = () => {
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const step5Ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { companyData } = location.state || {};
  console.log('companyData review', companyData);
  //   const userJSON = localStorage.getItem('admin');
  //   const data = userJSON ? JSON.parse(userJSON) : '';
  //   console.log('loggedInUser', data);
  const initialReviewState = {
    company: companyData?.company?._id,
    review_type: '',
    services: [],
    job_start_date: '',
    job_completion_date: '',
    job_due_date: '',
    budget_adherence: '',
    reliability: 0,
    communication: 0,
    work_quality: 0,
    lead_time: 0,
    feedback: [
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      { question: `Pros of working with ${companyData?.companyInfo?.name}.`, answer: '' },
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      { question: `Cons of working with ${companyData?.companyInfo?.name}.`, answer: '' },
      {
        /* eslint-disable @typescript-eslint/restrict-template-expressions */
        question: `Recommendations for others considering ${companyData?.companyInfo?.name}.`,
        answer: '',
      } /* eslint-disable @typescript-eslint/restrict-template-expressions */,
      {
        question: `Warnings for others considering ${companyData?.companyInfo?.name}.`,
        answer: '',
      },
    ],
    is_anonymous: !companyData?.createdBy,
    user: {
      avatar: '',
      name: '',
      designation: '',
      company: '',
      email: companyData?.createdBy?.email ?? '',
      job_title: '',
    },
  };
  const [review, setReview] = React.useState(initialReviewState);
  console.log('review123123', review);
  const [activeStep, setActiveStep] = React.useState<number | null>(0);
  const [selectedBox, setSelectedBox] = React.useState<number | null>(null);
  const [isChecked, setIsChecked] = useState(true);
  const [guidelines, setGuidelines] = useState<Guideline[]>([]);
  console.log('activeStep', activeStep);
  const grayStar = <StarIcon style={{ color: '#A8A8A8' }} fontSize="inherit" />;
  // Function to toggle the checkbox
  const toggleCheckbox = (): void => {
    setIsChecked((prev) => !prev);
  };

  const handleBoxClick = (index: number, item: CheckboxUserData): void => {
    setSelectedBox((prevSelectedBox) => {
      if (prevSelectedBox === index) {
        // If the same box is clicked, uncheck it by resetting `selectedBox` and `user` fields
        setReview((prevReview) => ({
          ...prevReview,
          user: {
            avatar: '',
            name: '',
            designation: '',
            company: '',
            email: companyData?.createdBy?.email ?? '',
            job_title: '',
          },
        }));
        return null;
      } else {
        // If a different box is clicked, select it and update the `user` fields
        setReview((prevReview) => ({
          ...prevReview,
          user: {
            avatar: item?.avatarSrc ?? '',
            name: item?.name ?? '',
            designation: item?.designation ?? '',
            company: item?.companyInfo ?? '',
            email: companyData?.createdBy?.email ?? '',
            job_title: item?.job_title ?? '',
          },
        }));
        return index;
      }
    });
  };
  const handleFeedbackChange = (index: number, newAnswer: string): void => {
    setReview((prevReview) => ({
      ...prevReview,
      feedback: prevReview.feedback.map((item, i) =>
        i === index ? { ...item, answer: newAnswer } : item,
      ),
    }));
  };
  const updateRating = (fieldName: string, newValue: number): void => {
    setReview((prevReview) => ({
      ...prevReview,
      [fieldName]: newValue,
    }));
  };
  const handleSubmit = (): void => {
    request('put', `/review/update/${companyData?._id}`, review)
      .then((res: any) => {
        console.log('res searcchh', res);
        setReview(initialReviewState);
        setIsChecked(false);
        setSelectedBox(null);
        navigate(-1);
        toast(<CustomToast message={'Review updated successfully.'} type="success" />);
      })
      .catch((err: any) => {
        toast(
          <CustomToast message={"You don't have permission to post this review."} type="error" />,
        );

        console.log('err', err);
      });
  };
  // const screenWidth = window.innerWidth;
  const handleNextStep = (stepRef: any): void => {
    if (activeStep === 5) return;
    stepRef?.current?.scrollIntoView({ behavior: 'smooth' });
    const headerHeight = 120; // Height of your fixed header in pixels
    const stepPosition = stepRef?.current?.getBoundingClientRect().top;
    const offsetPosition = stepPosition - headerHeight;

    // Smoothly scroll by the calculated offset
    window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
  };

  // Automatically move to the next step based on state changes
  useEffect(() => {
    // Check if Step 1 (Rules) is not completed
    if (!isChecked) {
      setActiveStep(0);
      handleNextStep(step1Ref); // Scroll to Step 1 if not completed
    } else if (!review.user?.designation) {
      // Check if Step 2 (Visibility) is not completed
      setActiveStep(1);
      handleNextStep(step2Ref); // Scroll to Step 2 if not completed
    } else {
      // Check if Step 3 (Rating) is not completed
      const hasCompletedRatings =
        review.reliability > 0 && review.communication > 0 && review.work_quality > 0;
      const hasLeadTimeRating = review.lead_time > 0;

      if (
        companyData?.companyInfo?.companyType === 'supplier' ||
        companyData?.companyInfo?.companyType === 'distributor'
      ) {
        // If the company is a supplier or distributor, check all 4 ratings
        if (hasCompletedRatings && hasLeadTimeRating) {
          setActiveStep(5); // Skip Step 4 and go directly to Step 5
          handleNextStep(step5Ref);
        } else {
          setActiveStep(2); // Stay on Step 3
          handleNextStep(step3Ref);
        }
      } else {
        // If the company is not a supplier or distributor, check only the first 3 ratings
        if (!hasCompletedRatings) {
          setActiveStep(2); // Stay on Step 3
          handleNextStep(step3Ref);
        } else {
          // Move to Step 4 after completing Step 3
          setActiveStep(3);
          handleNextStep(step4Ref);

          // Check Step 4 (Projects) - specifically for the lead time
          if (hasLeadTimeRating) {
            setActiveStep(5); // Move to Step 5 if lead time is provided
            handleNextStep(step5Ref);
          }
        }
      }
    }
  }, [review, activeStep, isChecked, companyData]);

  useEffect(() => {
    const screenWidth = window.innerWidth;

    const largeScreenGuidelines = [
      {
        src: Success,
        text: 'Base your review on your own experiences only.',
      },
      {
        src: Error,
        text: 'Do not post false, inappropriate or unrelated reviews.',
      },
      {
        src: Success,
        text: 'Only review companies you do business with now or in the past.',
      },
      {
        src: Error,
        text: 'Do not use reviews to advertise or promote yourself.',
      },
    ];

    const smallScreenGuidelines = [
      {
        src: Success,
        text: 'Base your review on your own experiences only.',
      },
      {
        src: Success,
        text: 'Only review companies you do business with now or in the past.',
      },
      {
        src: Error,
        text: 'Do not post false, inappropriate or unrelated reviews.',
      },
      {
        src: Error,
        text: 'Do not use reviews to advertise or promote yourself.',
      },
    ];

    if (screenWidth > 1024) {
      setGuidelines(largeScreenGuidelines);
    } else {
      setGuidelines(smallScreenGuidelines);
    }

    // Optional: Add a listener to handle window resize
    const handleResize = (): any => {
      const newScreenWidth = window.innerWidth;
      if (newScreenWidth > 1024) {
        setGuidelines(largeScreenGuidelines);
      } else {
        setGuidelines(smallScreenGuidelines);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (companyData) {
      setReview({
        company: companyData.company?._id,
        review_type: '',
        services: companyData.services || [],
        job_start_date: companyData.job_start_date || '',
        job_completion_date: companyData.job_completion_date || '',
        job_due_date: companyData.job_due_date || '',
        budget_adherence: companyData.budget_adherence || '',
        reliability: companyData.reliability || 0,
        communication: companyData.communication || 0,
        work_quality: companyData.work_quality || 0,
        lead_time: companyData.lead_time || 0,
        feedback: [
          {
            question: companyData.feedback[0]?.question || '',
            answer: companyData.feedback[0]?.answer || '',
          },
          {
            question: companyData.feedback[1]?.question || '',
            answer: companyData.feedback[1]?.answer || '',
          },
          {
            question: companyData.feedback[2]?.question || '',
            answer: companyData.feedback[2]?.answer || '',
          },
          {
            question: companyData.feedback[3]?.question || '',
            answer: companyData.feedback[3]?.answer || '',
          },
        ],
        is_anonymous: !companyData,
        user: {
          avatar: companyData.user?.avatar || '',
          name: companyData.user?.name || '',
          designation: companyData.user?.designation || '',
          company: companyData.user?.company || '',
          email: companyData.user?.email || '',
          job_title: companyData.user?.job_title || '',
        },
      });
      setSelectedBox(
        companyData?.user?.name?.length
          ? 1
          : companyData?.user?.company?.includes('Sign Company')
          ? 3
          : 2,
      );
    }
  }, [companyData]);

  return (
    <Box width="100%">
      <Box
        position={'fixed'}
        top={'0'}
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={'0 10%'}
        height={'81px'}
        borderBottom={'1px solid #EEEEEE'}
        bgcolor={'#fff'}
        zIndex={2}
        sx={{
          '@media (max-width: 1366px)': {
            padding: '0 5%',
          },
        }}
      >
        <Box
          width={'44px'}
          height={'44px'}
          onClick={() => {
            navigate(-1); // Go back to the previous page
          }}
          sx={{ cursor: 'pointer' }}
        >
          <img style={{ width: '100%', height: '100%' }} src={CrossIcon} />
        </Box>
        <Typography
          color="#000"
          textAlign="center"
          font-family="SF UI Display"
          fontSize="16px"
          fontWeight="700"
          sx={{
            '@media (max-width: 500px)': {
              fontWeight: '600',
            },
          }}
        >
          Write A Review
        </Typography>
        <Button
          disabled={activeStep !== 5}
          onClick={handleSubmit}
          sx={{
            borderRadius: '8px',
            border: '2px solid  rgba(255, 255, 255, 0.12)',
            background: activeStep !== 5 ? '#F7F7F7' : '#00BD57',
            color: '#FFF',
            fontFamily: 'SF UI Display',
            fontSize: '16px',
            fontWeight: '600',
            padding: '5px 40px',
            textTransform: 'capitalize',
            '&:hover': {
              bgcolor: '#00BD57',
            },
            '@media (max-width: 768px)': {
              display: 'none',
            },
          }}
        >
          Submit
        </Button>
        <Box
          onClick={activeStep !== 5 ? undefined : handleSubmit}
          sx={{
            width: '31px',
            height: '31px',
            display: 'none',
            borderRadius: '8px',
            background: activeStep !== 5 ? '#F7F7F7' : '#00BD57',
            fontFamily: 'SF UI Display',
            fontSize: '16px',
            fontWeight: '600',
            cursor: activeStep !== 5 ? 'default' : 'pointer',
            '@media (max-width: 768px)': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          <DoneIcon sx={{ color: activeStep !== 5 ? '#B8B8B8' : '#ffffff' }} />
        </Box>
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        padding={'150px 10% 50px 10%'}
        justifyContent={'space-between'}
        sx={{
          '@media (max-width: 1366px)': {
            padding: '150px 5% 50px 5%',
          },
          '@media (max-width: 992px)': {
            padding: '130px 5% 50px 5%',
          },
          '@media (max-width: 500px)': {
            padding: '120px 5% 50px 5%',
          },
        }}
      >
        <Box
          width={'55%'}
          sx={{
            '@media (max-width: 1366px)': {
              width: '60%',
            },
            '@media (max-width: 992px)': {
              width: '100%',
            },
          }}
        >
          <Box
            ref={step1Ref}
            width={'100%'}
            display={'flex'}
            gap={'30px'}
            sx={{
              '@media (max-width: 500px)': {
                gap: '20px',
              },
            }}
          >
            <Box
              width={'100px'}
              height={'100px'}
              sx={{
                '@media (max-width: 500px)': {
                  width: '75px',
                  height: '75px',
                },
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                  background: '#FFF',
                }}
                src={
                  companyData?.company?.companyInfo?.logo
                    ? /* eslint-disable @typescript-eslint/restrict-template-expressions */
                      `${mediaUrl}/${companyData?.company?.companyInfo?.logo}`
                    : Dummyimage
                }
              />
            </Box>
            <Box width={'fit-content'}>
              <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'10px'}>
                <Rating
                  name="hover-feedback"
                  value={
                    typeof companyData?.company?.averageReviewRating?.overall === 'number'
                      ? companyData?.company?.averageReviewRating.overall
                      : parseFloat(companyData?.company?.averageReviewRating?.overall) || 0
                  }
                  precision={0.1}
                  sx={{
                    color: !companyData?.company?.averageReviewRating
                      ? '#9E9E9E'
                      : companyData?.company?.averageReviewRating?.overall >= 4
                      ? '#00BD57'
                      : companyData?.company?.averageReviewRating?.overall >= 3
                      ? '#FFB800'
                      : '#FD3E14',
                    fontSize: '31px',
                    '@media (max-width: 500px)': {
                      fontSize: '25px',
                    },
                  }}
                  readOnly
                  emptyIcon={grayStar} // Custom gray star for empty rating
                  icon={
                    !companyData?.company?.averageReviewRating ? (
                      grayStar
                    ) : (
                      <StarIcon
                        fontSize="inherit"
                        sx={{
                          color: !companyData?.company?.averageReviewRating
                            ? '#9E9E9E'
                            : companyData?.company?.averageReviewRating?.overall >= 4
                            ? '#00BD57'
                            : companyData?.company?.averageReviewRating?.overall >= 3
                            ? '#FFB800'
                            : '#FD3E14',
                        }}
                      />
                    )
                  } // Gray filled stars if rating is 0
                />
                {companyData?.company?.averageReviewRating?.overall > 0 ? (
                  <Typography
                    fontSize={'32px'}
                    fontWeight={'700'}
                    fontFamily={'SF UI Display'}
                    color={'#000'}
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '20px',
                      },
                    }}
                  >
                    {(
                      Math.floor(companyData?.company?.averageReviewRating?.overall * 10) / 10
                    ).toFixed(1)}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
              <Typography
                fontSize={'20px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                color={'#777'}
                sx={{
                  '@media (max-width: 500px)': {
                    fontSize: '18px',
                  },
                }}
              >{`(${companyData?.company?.averageReviewRating?.count ?? 0} reviews)`}</Typography>
            </Box>
          </Box>
          <Box width={'100%'} margin={'20px 0 '}>
            <Typography
              fontSize={'24px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              textTransform={'capitalize'}
            >
              Review {companyData?.companyInfo?.name}
            </Typography>
            <Typography
              fontSize={'16px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              margin={'10px 0'}
              sx={{
                '@media (max-width: 500px)': {
                  display: 'none',
                },
              }}
            >
              At SignWise, we’re dedicated to making sure your opinion is heard regardless of
              whether its positive or negative.
              <br />
              <br />
              In return, we require that all your reviews follow these guidelines:
            </Typography>
            <Typography
              fontSize={'16px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              margin={'10px 0'}
              lineHeight={'18px'}
              display={'none'}
              sx={{
                '@media (max-width: 500px)': {
                  display: 'block',
                },
              }}
            >
              At SignWise, we’re dedicated to making sure your opinion is heard regardless of
              whether its positive or negative. In return, we require that all your reviews follow
              these guidelines:
            </Typography>
            <Box
              width={'100%'}
              display={'flex'}
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              margin={'30px 0'}
              sx={{
                '@media (max-width: 500px)': {
                  margin: '15px 0',
                },
              }}
            >
              {guidelines?.map((guideline, index) => (
                <Box
                  key={index}
                  display={'flex'}
                  width={'47%'}
                  gap={'10px'}
                  margin={'10px 0'}
                  alignItems={'center'}
                  sx={{
                    '@media (max-width: 1024px)': {
                      width: '100%',
                    },
                  }}
                >
                  <Box
                    width={'33px'}
                    height={'33px'}
                    sx={{
                      '@media (max-width: 500px)': {
                        width: '25px',
                        height: '25px',
                      },
                    }}
                  >
                    <img style={{ width: '100%', height: '100%' }} src={guideline?.src} />
                  </Box>
                  <Typography
                    width={'90%'}
                    fontSize={'15px'}
                    fontWeight={'500'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                    lineHeight={'15px'}
                  >
                    {guideline?.text}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box
              width={'100%'}
              display={'flex'}
              gap={'10px'}
              alignItems={'center'}
              sx={{
                '@media (max-width: 1024px)': {
                  width: 'fit-content',
                  bgcolor: '#F7F7F7',
                  borderRadius: '12px',
                  padding: '15px 10px 15px 5px',
                },
                '@media (max-width: 500px)': {
                  width: '100%',
                  bgcolor: '#F7F7F7',
                  borderRadius: '12px',
                  padding: '15px 10px 15px 5px',
                },
              }}
            >
              <Checkbox
                checked={isChecked}
                onChange={toggleCheckbox} // Toggles the checkbox state
                sx={{
                  color: '#D0D5DD',
                  '&.Mui-checked': {
                    color: '#00BD57', // Set the checked color to green
                  },
                }}
              />
              <Typography
                fontSize={'16px'}
                fontWeight={'400'}
                fontFamily={'SF UI Display'}
                lineHeight={'20px'}
                color={'#000000'}
                sx={{
                  '@media (max-width: 1024px)': {
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '16px',
                  },
                }}
              >
                I understand the review guidelines and agree to follow them.
              </Typography>
            </Box>
          </Box>
          <Box ref={step2Ref} width={'100%'} margin={'50px 0'}>
            <Typography
              fontSize={'20px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '18px',
                  lineHeight: 'normal',
                },
              }}
            >
              How would you like to post your review?
            </Typography>
            <Typography
              fontSize={'16px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              margin={'10px 0'}
              sx={{
                '@media (max-width: 500px)': {
                  lineHeight: '18px',
                },
              }}
            >
              Anyone can review a company publicly or{' '}
              <span style={{ fontWeight: '600', textDecoration: 'underline' }}>anonymously</span> in
              order to protect the business relationship.
            </Typography>
            <Box
              width={'100%'}
              margin={'40px 0'}
              display={'flex'}
              flexDirection={'column'}
              gap={'20px'}
            >
              <Box
                width={'100%'}
                borderRadius={'12px'}
                border={selectedBox === 1 ? '2px solid #00BD57;' : '1px solid #E4E7EC'}
                bgcolor={'#FFF'}
                padding={'20px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                onClick={(): void => {
                  handleBoxClick(1, {
                    avatarSrc: companyData?.createdBy?.photo || '',
                    /* eslint-disable @typescript-eslint/restrict-template-expressions */
                    name: `${companyData?.createdBy?.first_name || ''} ${
                      companyData?.createdBy?.last_name || ''
                    }`,
                    designation: `${
                      companyData?.createdBy?.type === 'owner' ? 'Owner' : 'Team Member'
                    }`,
                    job_title: companyData?.createdBy?.job_title || '',
                    companyInfo: companyData?.createdBy?.company?.companyInfo?.name || '',
                  });
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Avatar
                    src={
                      companyData?.createdBy?.photo
                        ? `${mediaUrl}/${companyData?.createdBy?.photo}`
                        : UserAvatar
                    }
                    sx={{
                      width: '56px',
                      height: '56px',
                    }}
                  />
                  <Box>
                    <Typography
                      fontSize={'16px'}
                      fontWeight={'600'}
                      fontFamily={'SF UI Display'}
                      color={'#000000'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      {`${companyData?.createdBy?.first_name} ${companyData?.createdBy?.last_name}`}
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'400'}
                      fontFamily={'SF UI Display'}
                      color={'#505059'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      {companyData?.createdBy?.job_title}
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'400'}
                      fontFamily={'SF UI Display'}
                      color={'#505059'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      {companyData?.createdBy?.company?.companyInfo?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={'30%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    '@media (max-width: 1024px)': {
                      width: 'fit-content',
                    },
                  }}
                >
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'600'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                    lineHeight={'20px'}
                    borderRadius={'6px'}
                    bgcolor={'#F7F7F7'}
                    boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                    padding={'10px 15px'}
                    whiteSpace={'nowrap'}
                    sx={{
                      '@media (max-width: 1024px)': {
                        display: 'none',
                      },
                    }}
                  >
                    Public Review
                  </Typography>
                  <Checkbox
                    checked={selectedBox === 1}
                    sx={{
                      display: 'none',
                      color: '#000000',
                      borderRadius: '6px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '24px', // Increase the size of the checkbox icon
                      },
                      '&.Mui-checked': {
                        color: '#00BD57', // Set the checked color to green
                      },
                      '@media (max-width: 1024px)': {
                        display: 'block',
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                width={'100%'}
                borderRadius={'12px'}
                border={selectedBox === 2 ? '2px solid #00BD57;' : '1px solid #E4E7EC'}
                bgcolor={'#FFF'}
                padding={'20px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                onClick={(): void => {
                  handleBoxClick(2, {
                    avatarSrc: '',
                    /* eslint-disable @typescript-eslint/restrict-template-expressions */
                    name: '',
                    designation: `${
                      companyData?.createdBy?.type === 'owner' ? 'Owner' : 'Team Member'
                    }`,
                    job_title: companyData?.createdBy?.job_title || '',
                    companyInfo: companyData?.createdBy?.company?.companyInfo?.name || '',
                  });
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Avatar
                    src={UserAvatar}
                    sx={{
                      width: '56px',
                      height: '56px',
                    }}
                  />
                  <Box>
                    <Typography
                      fontSize={'16px'}
                      fontWeight={'600'}
                      fontFamily={'SF UI Display'}
                      color={'#000000'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      Verified User
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'400'}
                      fontFamily={'SF UI Display'}
                      color={'#505059'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      {companyData?.createdBy?.job_title}
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'400'}
                      fontFamily={'SF UI Display'}
                      color={'#505059'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      {companyData?.createdBy?.company?.companyInfo?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={'30%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    '@media (max-width: 1024px)': {
                      width: 'fit-content',
                    },
                  }}
                >
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'600'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                    lineHeight={'20px'}
                    borderRadius={'6px'}
                    bgcolor={'#F7F7F7'}
                    boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                    padding={'10px 15px'}
                    whiteSpace={'nowrap'}
                    sx={{
                      '@media (max-width: 1024px)': {
                        display: 'none',
                      },
                    }}
                  >
                    Company Review
                  </Typography>
                  <Checkbox
                    checked={selectedBox === 2}
                    sx={{
                      display: 'none',

                      color: '#000000',
                      borderRadius: '6px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '24px', // Increase the size of the checkbox icon
                      },
                      '&.Mui-checked': {
                        color: '#00BD57', // Set the checked color to green
                      },
                      '@media (max-width: 1024px)': {
                        display: 'block',
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                width={'100%'}
                borderRadius={'12px'}
                border={selectedBox === 3 ? '2px solid #00BD57;' : '1px solid #E4E7EC'}
                bgcolor={'#FFF'}
                padding={'20px'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                onClick={(): void => {
                  handleBoxClick(3, {
                    avatarSrc: '',
                    /* eslint-disable @typescript-eslint/restrict-template-expressions */
                    name: '',
                    designation: `${
                      companyData?.createdBy?.type === 'owner' ? 'Owner' : 'Team Member'
                    }`,
                    job_title: companyData?.createdBy?.job_title || '',
                    companyInfo: `${companyData?.createdBy?.company?.companyInfo?.companyType} Sign Company`,
                  });
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                  <Avatar
                    src={UserAvatar}
                    sx={{
                      width: '56px',
                      height: '56px',
                    }}
                  />
                  <Box>
                    <Typography
                      fontSize={'16px'}
                      fontWeight={'600'}
                      fontFamily={'SF UI Display'}
                      color={'#000000'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      Verified User
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'400'}
                      fontFamily={'SF UI Display'}
                      color={'#505059'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      {companyData?.createdBy?.job_title}
                    </Typography>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'400'}
                      fontFamily={'SF UI Display'}
                      color={'#505059'}
                      lineHeight={'20px'}
                      letterSpacing={'-0.24px'}
                      textTransform={'capitalize'}
                    >
                      {`${companyData?.createdBy?.company?.companyInfo?.companyType}`} Sign Company
                    </Typography>
                  </Box>
                </Box>
                <Box
                  width={'30%'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    '@media (max-width: 1024px)': {
                      width: 'fit-content',
                    },
                  }}
                >
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'600'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                    lineHeight={'20px'}
                    borderRadius={'6px'}
                    bgcolor={'#F7F7F7'}
                    boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                    padding={'10px 15px'}
                    whiteSpace={'nowrap'}
                    sx={{
                      '@media (max-width: 1024px)': {
                        display: 'none',
                      },
                    }}
                  >
                    Anonymous Review
                  </Typography>
                  <Checkbox
                    checked={selectedBox === 3}
                    sx={{
                      display: 'none',
                      color: '#000000',
                      borderRadius: '6px',
                      '& .MuiSvgIcon-root': {
                        fontSize: '24px', // Increase the size of the checkbox icon
                      },
                      '&.Mui-checked': {
                        color: '#00BD57', // Set the checked color to green
                      },
                      '@media (max-width: 1024px)': {
                        display: 'block',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box ref={step3Ref} width={'100%'} margin={'60px 0'}>
            <Typography
              fontSize={'20px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '18px',
                  lineHeight: 'normal',
                },
              }}
            >
              How would you rate {companyData?.companyInfo?.name}?
            </Typography>
            <Typography
              fontSize={'16px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              margin={'10px 0'}
              sx={{
                '@media (max-width: 500px)': {
                  lineHeight: '18px',
                },
              }}
            >
              Ratings are a quickest way to tell others about your experience.
            </Typography>
            <Box
              width={'100%'}
              margin={'40px 0'}
              display={'flex'}
              flexDirection={'column'}
              gap={'30px'}
            >
              <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'15px'}>
                <Box display={'flex'} width={'100%'} gap={'5px'}>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    1.
                  </Typography>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    {`Rate their ${
                      companyData?.companyInfo?.companyType === 'local'
                        ? 'reliability.'
                        : companyData?.companyInfo?.companyType === 'national'
                        ? 'Professionalism.'
                        : 'quality.'
                    }`}
                  </Typography>
                </Box>
                <Rating
                  name="reliability"
                  value={review?.reliability}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('reliability', newValue);
                    }
                  }}
                  precision={0.5}
                  sx={{
                    fontSize: '51px',
                    marginLeft: '15px',
                    '@media (max-width: 1024px)': {
                      fontSize: '40px',
                    },
                    '@media (max-width: 768px)': {
                      fontSize: '33px',
                    },
                  }}
                  emptyIcon={grayStar} // Custom gray star for empty rating
                  icon={
                    review?.reliability === 0 ? (
                      grayStar
                    ) : (
                      <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
                    )
                  } // Gray filled stars if rating is 0
                />
              </Box>
              <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'15px'}>
                <Box display={'flex'} width={'100%'} gap={'5px'}>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    2.
                  </Typography>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    {`Rate their ${
                      companyData?.companyInfo?.companyType === 'local'
                        ? 'communication.'
                        : companyData?.companyInfo?.companyType === 'national'
                        ? 'communication.'
                        : 'customer service and support.'
                    }`}
                  </Typography>
                </Box>
                <Rating
                  name="communication"
                  value={review?.communication}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('communication', newValue);
                    }
                  }}
                  precision={0.5}
                  sx={{
                    fontSize: '51px',
                    marginLeft: '15px',
                    '@media (max-width: 1024px)': {
                      fontSize: '40px',
                    },
                    '@media (max-width: 768px)': {
                      fontSize: '33px',
                    },
                  }}
                  emptyIcon={grayStar} // Custom gray star for empty rating
                  icon={
                    review?.communication === 0 ? (
                      grayStar
                    ) : (
                      <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
                    )
                  } // Gray filled stars if rating is 0
                />
              </Box>
              <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'15px'}>
                <Box display={'flex'} width={'100%'} gap={'5px'}>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    3.
                  </Typography>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    {`Rate ${
                      companyData?.companyInfo?.companyType === 'local'
                        ? 'the quality of their work.'
                        : companyData?.companyInfo?.companyType === 'national'
                        ? 'how well they pay.'
                        : 'their cost or value.'
                    }`}
                  </Typography>
                </Box>
                <Rating
                  name="workQuality"
                  value={review?.work_quality}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('work_quality', newValue);
                    }
                  }}
                  precision={0.5}
                  sx={{
                    fontSize: '51px',
                    marginLeft: '15px',
                    '@media (max-width: 1024px)': {
                      fontSize: '40px',
                    },
                    '@media (max-width: 768px)': {
                      fontSize: '33px',
                    },
                  }}
                  emptyIcon={grayStar} // Custom gray star for empty rating
                  icon={
                    review?.work_quality === 0 ? (
                      grayStar
                    ) : (
                      <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
                    )
                  } // Gray filled stars if rating is 0
                />
              </Box>
              <Box
                width={'100%'}
                display={
                  companyData?.companyInfo?.companyType === 'supplier' ||
                  companyData?.companyInfo?.companyType === 'distributor'
                    ? 'flex'
                    : 'none'
                }
                flexDirection={'column'}
                gap={'15px'}
              >
                <Box display={'flex'} width={'100%'} gap={'5px'}>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    4.
                  </Typography>
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                  >
                    How often do you recommend them?
                  </Typography>
                </Box>
                <Rating
                  name="leadTime"
                  value={review?.lead_time}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('lead_time', newValue);
                    }
                  }}
                  precision={0.5}
                  sx={{
                    fontSize: '51px',
                    marginLeft: '15px',
                    '@media (max-width: 1024px)': {
                      fontSize: '40px',
                    },
                    '@media (max-width: 768px)': {
                      fontSize: '33px',
                    },
                  }}
                  emptyIcon={grayStar} // Custom gray star for empty rating
                  icon={
                    review?.lead_time === 0 ? (
                      grayStar
                    ) : (
                      <StarIcon fontSize="inherit" sx={{ color: '#00BD57' }} />
                    )
                  } // Gray filled stars if rating is 0
                />
              </Box>
            </Box>
          </Box>
          <Box
            ref={step4Ref}
            width={'100%'}
            margin={'40px 0'}
            display={
              companyData?.companyInfo?.companyType === 'supplier' ||
              companyData?.companyInfo?.companyType === 'distributor'
                ? 'none'
                : 'block'
            }
          >
            <Typography
              fontSize={'20px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '18px',
                  lineHeight: 'normal',
                },
              }}
            >
              {`Does ${companyData?.companyInfo?.name} ${
                companyData?.companyInfo?.companyType === 'local' ? 'deliver' : 'pay'
              } on time?`}
            </Typography>
            <Box
              width={'100%'}
              margin={'40px 0'}
              display={'flex'}
              flexDirection={'column'}
              gap={'20px'}
            >
              {deliveryOptions.map((option) => (
                <Box
                  key={option.id}
                  width={'80%'}
                  borderRadius={'12px'}
                  border={
                    review.lead_time === option.rating ? '2px solid #00BD57' : '2px solid #E4E7EC'
                  }
                  bgcolor={'#FFF'}
                  padding={'15px 20px'}
                  sx={{
                    cursor: 'pointer',
                    '@media (max-width: 1024px)': {
                      width: '100%',
                    },
                    '@media (max-width: 992px)': {
                      width: '70%',
                    },
                    '@media (max-width: 768px)': {
                      width: '90%',
                    },
                    '@media (max-width: 500px)': {
                      width: '100%',
                    },
                  }}
                  onClick={() => {
                    updateRating('lead_time', option.rating);
                  }}
                >
                  <Box display={'flex'} gap={'10px'}>
                    <Box width={'34px'} height={'34px'}>
                      <img
                        style={{ width: '100%', height: '100%' }}
                        src={option.imageSrc}
                        alt={option.label}
                      />
                    </Box>
                    <Box>
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'600'}
                        fontFamily={'SF UI Display'}
                        color={'#000000'}
                        lineHeight={'20px'}
                      >
                        {option.label}
                      </Typography>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'400'}
                        fontFamily={'SF UI Display'}
                        color={'#000000'}
                        lineHeight={'20px'}
                        letterSpacing={'-0.24px'}
                        sx={{
                          '@media (max-width: 500px)': {
                            lineHeight: '15px',
                          },
                        }}
                      >
                        {option.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Box ref={step5Ref} width={'100%'} margin={'40px 0'}>
            <Typography
              fontSize={'20px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              sx={{
                '@media (max-width: 500px)': {
                  fontSize: '18px',
                  lineHeight: 'normal',
                },
              }}
            >
              Give {companyData?.companyInfo?.name} your feedback.
            </Typography>
            <Typography
              fontSize={'16px'}
              fontWeight={'400'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              margin={'10px 0'}
              sx={{
                '@media (max-width: 500px)': {
                  lineHeight: '18px',
                },
              }}
            >
              Feedback helps our community make better choices and purchases. Let us know whats
              working well and what could be improved.
            </Typography>
            <Box
              width={'100%'}
              margin={'40px 0'}
              display={'flex'}
              flexDirection={'column'}
              gap={'40px'}
            >
              {review?.feedback.map((item, index) => (
                <Box
                  key={index}
                  width={'100%'}
                  display={'flex'}
                  flexDirection={'column'}
                  gap={'15px'}
                >
                  <Typography
                    fontSize={'18px'}
                    fontWeight={'400'}
                    fontFamily={'SF UI Display'}
                    color={'#000000'}
                    sx={{
                      '@media (max-width: 500px)': {
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: 'normal',
                      },
                    }}
                  >
                    {item?.question} [Optional]
                  </Typography>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder={`Write your ${item?.question.toLowerCase().split(' ')[0]} here...`}
                    value={item?.answer}
                    onChange={(e) => {
                      handleFeedbackChange(index, e.target.value);
                    }}
                    sx={{
                      borderRadius: '8px',
                      border: '1px solid #D0D5DD',
                      background: '#FFF',
                      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          width={'28%'}
          bgcolor={'#F7F7F7'}
          position={'fixed'}
          top={'150px'}
          right={'10%'}
          borderRadius={'10px'}
          sx={{
            '@media (max-width: 1366px)': {
              right: '5%',
              width: '33%',
            },
            '@media (max-width: 992px)': {
              display: 'none',
            },
          }}
        >
          <Box width={'100%'} padding={'20px 40px'}>
            <Typography
              color={'#000'}
              fontFamily="SF UI Display"
              fontSize="20px"
              fontWeight="800"
              sx={{
                '@media (max-width: 1024px)': {
                  fontSize: '18px',
                },
              }}
            >
              Review Steps
            </Typography>
          </Box>
          <Divider />
          <Box width={'100%'} padding={'20px 25px 20px 35px'}>
            <VerticalLinearStepper activeStep={activeStep} companyData={companyData} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EditReview;
