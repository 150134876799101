import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon'; // Import StepIconProps
import Complete from '../../assets/DesignUpdate/stepperComplete.svg';
import Active from '../../assets/DesignUpdate/stepperActive.svg';
import Pending from '../../assets/DesignUpdate//stepperPending.svg';

interface VerticalLinearStepperProps {
  activeStep?: any;
  companyData?: any;
}

// Custom Connector to style the stepper line
const QontoConnector = styled(StepConnector)(({ theme }): any => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: '0px',
      borderLeft: '2px solid #BBBBBB',
      borderColor: '#BBBBBB',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      border: '0px',
      borderLeft: '2px solid #00BD57',
      borderColor: '#00BD57',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: '0px',
    borderLeft: '2px solid #BBBBBB',
    borderColor: '#BBBBBB',
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));
// Custom StepIcon component using images
function QontoStepIcon(props: StepIconProps): any {
  const { active, completed } = props;

  const getIcon = (): any => {
    if (completed) {
      return Complete; // Replace with your completed icon image path
    } else if (active) {
      return Active; // Replace with your active icon image path
    } else {
      return Pending; // Replace with your default/inactive icon image path
    }
  };

  return (
    <Box width={'24px'} height={'24px'}>
      <Box
        component="img"
        src={getIcon()}
        alt="step icon"
        sx={{
          width: '100%', // Adjust size based on your design
          height: '100%', // Adjust size based on your design
        }}
      />
    </Box>
  );
}

const VerticalLinearStepper: React.FC<VerticalLinearStepperProps> = ({
  activeStep,
  companyData,
}) => {
  const type = companyData?.companyInfo?.companyType;
  const steps = [
    {
      label: 'Rules',
      description: 'Accept our review terms.',
    },
    {
      label: 'Visibility',
      description: 'Choose a public or anonymous review.',
    },
    {
      label: 'Rating',
      description: 'Tell us about your experience.',
    },
    ...(type !== 'supplier' && type !== 'distributor'
      ? [
          {
            label: type === 'local' ? 'Projects' : 'Payments',
            description: 'Share the results of your work history.',
          },
        ]
      : []),
    {
      label: 'Feedback',
      description: 'Give us the positives and negatives.',
    },
  ];
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical" connector={<QontoConnector />}>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={QontoStepIcon} // Using the updated QontoStepIcon
              optional={
                <Typography
                  color={activeStep >= index ? '#000' : '#B8B8B8'}
                  font-family="SF UI Display"
                  font-size="14px"
                  font-weight="400"
                  lineHeight={'20px'}
                  marginLeft="20px"
                  sx={{
                    '@media (max-width: 1024px)': {
                      fontSize: '12px',
                      marginLeft: '12px',
                    },
                  }}
                >
                  {step.description}
                </Typography>
              }
            >
              <Typography
                sx={{
                  color: activeStep >= index ? '#000' : '#B8B8B8',
                  fontFamily: 'SF UI Display',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginLeft: '20px',
                  lineHeight: '20px',
                  '@media (max-width: 1024px)': {
                    fontSize: '14px',
                    marginLeft: '12px',
                  },
                }}
              >
                {step.label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
export default VerticalLinearStepper;
