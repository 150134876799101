import * as React from 'react';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Rating,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
// import { ReactComponent as Check } from '../assets/check.svg';
// import { ReactComponent as Close } from '../assets/close.svg';
// import { ReactComponent as Info } from '../assets/info.svg';
import { IReviews } from '../types/user';
import { mediaUrl } from '../config';
import moment from 'moment';
import LocationIcon from '../assets/DesignUpdate/locationIconUpdated.svg';
import ClanderIcon from '../assets/DesignUpdate/calanderIconUpdate.svg';
import CloseIcon from '@mui/icons-material/Close';
import ReportReason from '../assets/DesignUpdate/ReportReason.svg';
// import { KeyboardArrowDownIcon } from '@mui/icons-material/KeyboardArrowDown';
// import { BorderColorIcon } from '@mui/icons-material/BorderColor';
import { Check, Close } from '@mui/icons-material';

// import FlagModal from '../popups/Flag.popup';
import RemovebuttonIcon from '../assets/DesignUpdate/ReportedButtonIcon.svg';
import usaStatesAndCanadaProvinces from '../utils/states';

interface IReviewModal {
  item: IReviews;
  loading: boolean;
  handleUpdateStatues: (id: string, status: string) => void;
  approveLoad?: any;
  rejectLoad?: any;
  reviewOpen?: any;
  handleReviewClose?: () => void;
  setOpenFlag?: (data: any) => void;
  setReason?: (data: any) => void;
  setReviewId?: (data: any) => void;
  handleReject: (id: any) => void;
}
interface BubbleTextProps {
  bubbleColor?: string;
}

const ReviewModal: React.FC<IReviewModal> = ({
  item,
  handleUpdateStatues,
  loading,
  approveLoad,
  rejectLoad,
  reviewOpen,
  handleReviewClose,
  setOpenFlag,
  setReason,
  handleReject,
  setReviewId,
}) => {
  // const [open, setOpen] = React.useState(false);
  // const [fullReview, setFullReview] = React.useState(false);
  // const handleOpen = (): void => {
  //   setOpen(true);
  // };
  // const handleClose = (): void => {
  //   setOpen(false);
  // };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      border: '1px solid #E0E1E2',
      backgroundColor: '#E0E1E2',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 3,
      backgroundColor: '#00BD57 ',
    },
  }));
  const getColor = (rating: any): any => {
    if (!rating) return '#9E9E9E';
    if (rating >= 4) return '#00BD57';
    if (rating >= 3) return '#FFB800';
    return '#FD3E14';
  };
  function getStateAbbreviation(additional: string): string {
    if (!additional) return '';

    // Split the 'additional' string by comma and trim any whitespace
    const parts = additional.split(',').map((part: string) => part.trim());

    // Extract the city name (first part) and the state name (last part)
    const cityName = parts[0];
    const stateName = parts[parts?.length - 1];

    // Find the state or province in the array and get its abbreviation
    const state = usaStatesAndCanadaProvinces.find(
      (item: any) => item?.name?.toLowerCase() === stateName?.toLowerCase(),
    );

    // Return formatted string as "City, State Abbreviation"
    return state ? `${cityName}, ${state?.abbreviation}` : additional;
  }
  const BubbleText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'bubbleColor',
  })<BubbleTextProps>(({ bubbleColor }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(${bubbleColor === '#FF3D13' ? '-80%' : '-100%'}, -50%)`,
    backgroundColor: bubbleColor ?? '#fff',
    padding: '2px 10px',
    borderRadius: '9999px',
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'SF UI Display',
    fontWeight: '600',
  }));
  const getText = (value: any): any => {
    if (value >= 100) return 'Always'; // 80-100%
    if (value >= 80) return 'Often'; // 60-79%
    if (value >= 60) return 'Sometimes'; // 40-59%
    if (value >= 40) return 'Rarely'; // 20-39%
    return 'Never'; // 0-19%
  };
  const getBubbleColor = (value: number): string => {
    if (value >= 80) return '#00BD57';
    if (value >= 60) return '#FFB800';
    return '#FF3D13';
  };

  const progressValue = (item?.lead_time / 5) * 100;
  const bubbleText = getText(progressValue);
  const bubbleColor = getBubbleColor(progressValue);
  console.log('item Admin', item);
  // const handleFullReview = (feedback: string): string => {
  //   let trimmed;
  //   if (!fullReview) {
  //     trimmed = feedback.slice(0, 200) + '...';
  //   } else {
  //     trimmed = feedback;
  //   }
  //   return trimmed;
  // };
  return (
    <div>
      {/* <Button
        sx={{
          color: '#475467',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          fontFamily: 'Inter',
          cursor: 'pointer',
          textTransform: 'capitalize',
        }}
        onClick={handleOpen}
      >
        {' '}
        <Info />
      </Button> */}
      <Modal
        open={reviewOpen}
        onClose={handleReviewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'var(#0C111D)',
            opacity: 0.7,
            backdropFilter: 'blur(8px)', // Adjust the blur level as needed
          },
        }}
      >
        <Box
          width={'60vw'}
          position={'absolute'}
          top={'50%'}
          left={'50%'}
          bgcolor={'#ffffff'}
          borderRadius={'10px'}
          boxShadow={'24'}
          padding={'50px'}
          sx={{
            transform: 'translate(-50%, -50%)',
            height: 'fit-content',
            // overflowY: 'scroll',
          }}
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} padding={'0.5% 0'}>
            <IconButton onClick={handleReviewClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            // key={key}
            width={'100%'}
            // border={'1px solid #DCDEDF'}
            borderRadius={'5px'}
            // marginBottom={'3%'}
            sx={{
              '@media (max-width: 1000px)': {
                margin: '6% 0',
              },
            }}
          >
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              padding={'2%'}
              border={'1px solid #DCDEDF'}
              flexDirection={'row-reverse'}
              sx={{
                '@media (max-width: 1000px)': {
                  flexDirection: 'column-reverse',
                  alignItems: 'start',
                  justifyContent: 'start',
                },
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Box display={'flex'} gap={'35px'} justifyContent={'center'} alignItems={'center'}>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="20px"
                    color="#000000"
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={'5px'}
                    sx={{
                      '@media (max-width: 600px)': {
                        gap: '20px',
                      },
                    }}
                  >
                    <img src={LocationIcon} alt="." style={{ width: '16px', height: '21px' }} />
                    {getStateAbbreviation(
                      /* eslint-disable @typescript-eslint/restrict-template-expressions */
                      `${
                        item?.createdBy?.company?.companyInfo?.address?.place ??
                        item?.createdBy?.company?.companyInfo?.address?.city ??
                        item?.createdBy?.company?.companyInfo?.address?.region ??
                        item?.createdBy?.company?.companyInfo?.address?.street
                      }, ${item?.createdBy?.company?.companyInfo?.address?.state}`,
                    )}
                  </Typography>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="20px"
                    color="#000000"
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={'5px'}
                  >
                    <img src={ClanderIcon} alt="." style={{ width: '22px', height: '25px' }} />
                    {/* {companyData?.companyInfo?.companyType !== 'local' ? ( */}
                    {moment(item?.createdAt).format('MMMM Do, YYYY')}
                    {/* ) : ( */}
                    {/* <>
                  {moment(item?.job_start_date).format('MMM Do')} -{' '}
                  {moment(item?.job_completion_date).format('MMM Do')}
                </>
              )} */}
                  </Typography>
                </Box>
              </Box>

              <Box
                display={'flex'}
                gap={'10px'}
                sx={{
                  '@media (max-width: 1000px)': {
                    margin: '20px 0',
                    width: '100%',
                  },
                }}
              >
                <Box width={'56px'} height={'55.6px'}>
                  <Avatar
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    src={item?.user?.avatar ? `${mediaUrl}/${item?.user?.avatar}` : ''}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Box>

                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{
                    '@media (max-width: 1000px)': {
                      // flexDirection: 'row',
                      // gap: '30px',
                    },
                  }}
                >
                  {/* name */}
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="600"
                    fontSize="16px"
                    lineHeight="20px"
                    color="#000000"
                    textTransform={'capitalize'}
                    sx={{
                      '@media (max-width: 1000px)': {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '10px',
                      },
                    }}
                  >
                    {item?.user?.name?.length ? item?.user?.name : 'Verified User'}
                  </Typography>
                  {/* job title */}
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="20px"
                    color="#505059"
                    textTransform={'capitalize'}
                    letterSpacing={'-0.24px'}
                    sx={{
                      '@media (max-width: 1000px)': {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '10px',
                      },
                    }}
                  >
                    {item?.user?.job_title?.length ? item?.user?.job_title : 'job title here'}
                  </Typography>
                  {/* company */}
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="20px"
                    color="#505059"
                    textTransform={'capitalize'}
                    letterSpacing={'-0.24px'}
                    sx={{
                      '@media (max-width: 1000px)': {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        gap: '10px',
                      },
                    }}
                  >
                    {item?.user?.company}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              width={'100%'}
              display={'flex'}
              // bgcolor={'orange'}
              borderLeft={'1px solid #DCDEDF'}
              borderBottom={'1px solid #DCDEDF'}
              borderRight={'1px solid #DCDEDF'}
              sx={{
                '@media (max-width: 768px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <Box
                width={'30%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}
                gap={'10px'}
                padding={'2% 0'}
                borderRight={'1px solid #DCDEDF'}
                sx={{
                  '@media (max-width: 768px)': {
                    borderRight: 'none',
                    width: '100%',
                    flexDirection: 'column-reverse',
                    justifyContent: 'start',
                    alignItems: 'start',
                    padding: '2%',
                  },
                }}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'5px'}
                  sx={{
                    '@media (max-width: 768px)': {
                      flexDirection: 'row-reverse',
                    },
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="800"
                    fontSize="28px"
                    lineHeight="31px"
                    color="#000000"
                  >
                    {typeof item?.overall_rating === 'number'
                      ? (Math.floor(item?.overall_rating * 10) / 10).toFixed(1)
                      : '--'}
                  </Typography>
                  <Rating
                    value={item?.overall_rating}
                    readOnly
                    precision={0.1}
                    sx={{
                      color: !item?.overall_rating
                        ? '#9E9E9E'
                        : item?.overall_rating >= 4
                        ? '#00BD57'
                        : item?.overall_rating >= 3
                        ? '#FFB800'
                        : '#FD3E14',
                      fontSize: '23px',
                    }}
                  />
                </Box>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="400"
                  fontSize="20px"
                  lineHeight="23.83px"
                  color="#000000"
                  sx={{
                    '@media (max-width: 500px)': {
                      fontSize: '14px',
                      lineHeight: '16.71px',
                      mt: 2,
                    },
                  }}
                >
                  Overall Review Rating
                </Typography>
              </Box>
              <Box
                width={'70%'}
                display={'flex'}
                padding={'2%'}
                flexWrap={'wrap'}
                justifyContent={'space-between'}
                sx={{
                  '@media (max-width: 768px)': {
                    width: '100%',
                    // display: 'grid',
                    // gridTemplateColumns: '1fr 1fr',
                    // gap: '25px',
                  },
                  '@media (max-width: 500px)': {
                    borderTop: '1px solid #DCDEDF',
                  },
                }}
              >
                <Box
                  width={'47%'}
                  margin={'2% 0'}
                  // sx={{
                  //   '@media (max-width: 768px)': {
                  //     width: '98%',
                  //     margin: '4% 0',
                  //   },
                  // }}
                >
                  <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {item?.company?.companyInfo?.companyType === 'local'
                        ? 'Reliability'
                        : item?.company?.companyInfo?.companyType === 'national'
                        ? 'Professionalism'
                        : 'Quality'}
                    </Typography>
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                    >
                      {typeof item?.reliability === 'number'
                        ? Number.isInteger(item?.reliability)
                          ? item?.reliability.toString()
                          : (Math.floor(item?.reliability * 10) / 10).toFixed(1)
                        : '--'}
                      /5
                    </Typography>
                  </Box>
                  <Box width={'100%'} marginTop={'3%'}>
                    <BorderLinearProgress
                      variant="determinate"
                      sx={{
                        [`& .${linearProgressClasses.bar}`]: {
                          backgroundColor: getColor(item?.reliability),
                        },
                      }}
                      value={(item?.reliability / 5) * 100}
                    />
                  </Box>
                </Box>
                <Box
                  width={'47%'}
                  margin={'2% 0'}
                  // sx={{
                  //   '@media (max-width: 768px)': {
                  //     width: '98%',
                  //   },
                  // }}
                >
                  <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {item?.company?.companyInfo?.companyType === 'local'
                        ? 'Communication'
                        : item?.company?.companyInfo?.companyType === 'national'
                        ? 'Communication'
                        : 'Customer Service'}
                    </Typography>
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                    >
                      {typeof item?.communication === 'number'
                        ? Number.isInteger(item?.communication)
                          ? item?.communication.toString()
                          : (Math.floor(item?.communication * 10) / 10).toFixed(1)
                        : '--'}
                      /5
                    </Typography>
                  </Box>
                  <Box width={'100%'} marginTop={'3%'}>
                    <BorderLinearProgress
                      variant="determinate"
                      sx={{
                        [`& .${linearProgressClasses.bar}`]: {
                          backgroundColor: getColor(item?.communication),
                        },
                      }}
                      value={(item?.communication / 5) * 100}
                    />
                  </Box>
                </Box>
                <Box
                  width={'100%'}
                  display={'none'}
                  sx={{
                    '@media (max-width: 500px)': {
                      display: 'block',
                      margin: '2% 0',
                    },
                  }}
                >
                  <Divider sx={{ width: '100%' }} />
                </Box>

                <Box
                  width={'47%'}
                  margin={'2% 0'}
                  // sx={{
                  //   '@media (max-width: 768px)': {
                  //     width: '98%',
                  //   },
                  // }}
                >
                  <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {item?.company?.companyInfo?.companyType === 'local'
                        ? 'Work Quality'
                        : item?.company?.companyInfo?.companyType === 'national'
                        ? 'Compensation'
                        : 'Cost'}
                    </Typography>
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                    >
                      {typeof item?.work_quality === 'number'
                        ? Number.isInteger(item?.work_quality)
                          ? item?.work_quality.toString()
                          : (Math.floor(item?.work_quality * 10) / 10).toFixed(1)
                        : '--'}
                      /5
                    </Typography>
                  </Box>
                  <Box width={'100%'} marginTop={'3%'}>
                    <BorderLinearProgress
                      sx={{
                        [`& .${linearProgressClasses.bar}`]: {
                          backgroundColor: getColor(item?.work_quality),
                        },
                      }}
                      variant="determinate"
                      value={(item?.work_quality / 5) * 100}
                    />
                  </Box>
                </Box>
                <Box
                  width={'47%'}
                  margin={'2% 0'}
                  // sx={{
                  //   '@media (max-width: 768px)': {
                  //     width: '98%',
                  //   },
                  // }}
                >
                  <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {item?.company?.companyInfo?.companyType === 'local'
                        ? 'On-Time Projects'
                        : item?.company?.companyInfo?.companyType === 'national'
                        ? 'On-Time Payments'
                        : 'Recommendations'}
                    </Typography>
                    <Typography
                      fontFamily="SF UI Display"
                      fontWeight="700"
                      fontSize="16px"
                      lineHeight="19.09px"
                      color="#000000"
                      sx={{
                        '@media (max-width: 500px)': {
                          fontSize: '14px',
                        },
                      }}
                    >
                      {/* {item?.company?.companyInfo?.companyType !== 'local' &&
                        item?.company?.companyInfo?.companyType !== 'national' && ( */}
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="19px"
                        color="#000000"
                      >
                        {typeof item?.lead_time === 'number'
                          ? Number.isInteger(item?.lead_time)
                            ? item?.lead_time.toString()
                            : (Math.floor(item?.lead_time * 10) / 10).toFixed(1)
                          : '--'}
                        /5
                      </Typography>
                      {/* // )} */}
                    </Typography>
                  </Box>
                  {item?.company?.companyInfo?.companyType === 'supplier' ||
                  item?.company?.companyInfo?.companyType === 'distributor' ? (
                    <Box width={'100%'} marginTop={'3%'} position={'relative'}>
                      <BorderLinearProgress
                        sx={{
                          [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: getColor(item?.lead_time),
                          },
                        }}
                        variant="determinate"
                        value={(item?.lead_time / 5) * 100}
                      />
                    </Box>
                  ) : (
                    <Box width={'100%'} marginTop={'3%'} position={'relative'}>
                      <BorderLinearProgress
                        sx={{
                          [`& .${linearProgressClasses.bar}`]: {
                            backgroundColor: getColor(item?.lead_time),
                          },
                        }}
                        variant="determinate"
                        value={progressValue}
                      />
                      <BubbleText
                        bubbleColor={bubbleColor}
                        sx={{
                          left: `${progressValue}%`, // Position text according to progress
                          // backgroundColor: progressValue >= 90 ? '00BD57' : '#fff',
                        }}
                      >
                        {bubbleText}
                      </BubbleText>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {/* {item.feedback && ( */}
            {/* questions answer */}
            <Box
              width="100%"
              minHeight="10vh" // Minimum height to grow with content
              maxHeight="41.5vh"
              pt={1}
              sx={{
                overflowY: 'scroll',
              }}
            >
              {Array.isArray(item?.feedback) &&
                item?.feedback
                  ?.filter((feedbackItem: any) => feedbackItem?.answer?.length > 0)
                  .map((filteredFeedbackItem: any, index: any) => (
                    <Box
                      key={index}
                      width="100%"
                      padding={'1% 0'}
                      sx={{
                        borderBottom: '1px solid #DCDEDF',
                        '&:last-child': {
                          borderBottom: 'none',
                        },
                      }}
                    >
                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="19.09px"
                        color="#000000"
                        pt={2}
                        sx={{
                          '@media (max-width: 500px)': {
                            mt: 2,
                          },
                        }}
                      >
                        {filteredFeedbackItem?.question}
                      </Typography>

                      <Typography
                        fontFamily="SF UI Display"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="16.71px"
                        color="#000000"
                        marginTop="1%"
                        pb={2}
                      >
                        {filteredFeedbackItem?.answer}
                      </Typography>
                    </Box>
                  ))}
              {item?.review_status === 'reported' && (
                <Box pt={5} borderTop={'solid 1px #DCDEDF'}>
                  <Typography fontFamily={'SF UI Display'}>Report Reason</Typography>
                  <Box width={'100%'} display="flex" gap={'10px'} mt={2}>
                    <Box width={'28px'} height="28px">
                      <img src={ReportReason} alt="." style={{ width: '100%', height: '100%' }} />
                    </Box>
                    <Box>
                      <Typography
                        fontFamily={'SF UI Display'}
                        fontWeight={500}
                        fontSize={'16px'}
                        lineHeight={'Line height/text-sm'}
                      >
                        {item?.report?.reason?.title}
                      </Typography>
                      <Typography
                        fontFamily={'SF UI Display'}
                        fontWeight={400}
                        fontSize={'14px'}
                        lineHeight={'Line height/text-sm'}
                        color={'rgba(0, 0, 0, 1)'}
                      >
                        {item?.report?.reason?.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={3} mb={3}>
                    <Typography
                      fontFamily={'SF UI Display'}
                      fontWeight={500}
                      fontSize={'16px'}
                      lineHeight={'Line height/text-sm'}
                    >
                      Report Explanation
                    </Typography>
                    <Typography
                      fontFamily={'SF UI Display'}
                      fontWeight={400}
                      fontSize={'14px'}
                      lineHeight={'Line height/text-sm'}
                      color={'rgba(0, 0, 0, 1)'}
                    >
                      {item?.report?.details}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {/* Buttons here */}
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mt={3}
            mb={1}
          >
            {item?.review_status === 'pending' && (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
                gap={'10px'}
              >
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateStatues(item?._id, 'approved');
                  }}
                  disabled={loading}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid #47B45F',
                    background: '#47B45F',
                    color: 'white',
                    textTransform: 'capitalize',
                    width: '100%',
                    '&:hover': {
                      bgcolor: '#47B45F',
                    },
                    '@media (max-width: 1500px)': {
                      width: '130px',
                    },
                  }}
                >
                  {approveLoad === item?._id ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <>
                      <Box>
                        <Check />
                      </Box>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'600'}
                        fontFamily={'Inter'}
                        lineHeight={'20px'}
                        color={'white'}
                      >
                        Approve
                      </Typography>
                    </>
                  )}
                </Button>
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleUpdateStatues(item?._id, 'rejected');
                  }}
                  disabled={loading}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid #FF5D39',
                    background: '#FFFFFF',
                    color: '#ffffff',
                    textTransform: 'capitalize',
                    width: '100%',

                    '&:hover': {
                      bgcolor: '#FFFFFF',
                    },
                    '@media (max-width: 1500px)': {
                      width: '130px',
                    },
                  }}
                >
                  {rejectLoad === item?._id ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <>
                      <Box>
                        {/* <img src={Close} alt="Check" /> */}
                        <Close
                          sx={{
                            color: '#FF5D39',
                          }}
                        />
                      </Box>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'600'}
                        fontFamily={'Inter'}
                        lineHeight={'20px'}
                        color={'#FF5D39'}
                      >
                        Reject
                      </Typography>
                    </>
                  )}
                </Button>
              </Box>
            )}
            {item?.review_status === 'approved' && (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Button
                  disabled
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid #47B45F',
                    background: '#47B45F',
                    color: 'white',
                    textTransform: 'capitalize',
                    width: '100%',
                    '&:hover': {
                      bgcolor: '#47B45F',
                    },
                    '@media (max-width: 1500px)': {
                      width: '130px',
                    },
                  }}
                >
                  <>
                    <Box>
                      <Check />
                    </Box>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'600'}
                      fontFamily={'Inter'}
                      lineHeight={'20px'}
                      color={'white'}
                    >
                      Approve
                    </Typography>
                  </>
                </Button>
              </Box>
            )}
            {item?.review_status === 'rejected' && (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Button
                  disabled
                  fullWidth
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid rgba(255, 93, 57, 0.50)',
                    background: '#FFFFFF',
                    color: '#FF5D3980',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: '#FFFFFF',
                    },
                  }}
                >
                  <Box>
                    <Close
                      sx={{
                        color: '#FF5D39',
                        opacity: '0.5',
                      }}
                    />
                  </Box>
                  <Typography
                    fontSize={'14px'}
                    fontWeight={'600'}
                    fontFamily={'Inter'}
                    lineHeight={'20px'}
                    color={'#FF5D3980'}
                  >
                    Rejected
                  </Typography>
                </Button>
              </Box>
            )}
            {item?.review_status === 'reported' && (
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'100%'}
                gap={'10px'}
              >
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenFlag?.(true);
                    // setReason?.(item?.reason);
                    setReviewId?.(item?._id);
                  }}
                  disabled={loading}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid rgba(239, 207, 125, 1)',
                    background: 'transparent',
                    color: '#454238B2',
                    textTransform: 'capitalize',
                    width: '100%',
                    // '&:hover': {
                    //   bgcolor: '#47B45F',
                    // },
                    '@media (max-width: 1500px)': {
                      width: '130px',
                    },
                  }}
                >
                  {approveLoad === item?._id ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <>
                      <Box width={'20px'} height={'20px'}>
                        <img
                          src={RemovebuttonIcon}
                          alt="."
                          style={{ width: '100%', height: '100%' }}
                        />
                      </Box>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'600'}
                        fontFamily={'Inter'}
                        lineHeight={'20px'}
                        color={'#454238B2'}
                      >
                        Remove
                      </Typography>
                    </>
                  )}
                </Button>
                <Button
                  // onClick={(e) => {
                  //   e.stopPropagation();
                  //   setOpenFlag?.(true);
                  //   // setReason?.(item?.reason);
                  //   setReviewId?.(item?._id);
                  // }}
                  onClick={() => {
                    handleReject(item?._id);
                  }}
                  disabled={loading}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid #FF5D39',
                    background: '#FFFFFF',
                    color: '#ffffff',
                    textTransform: 'capitalize',
                    width: '100%',

                    '&:hover': {
                      bgcolor: '#FFFFFF',
                    },
                    '@media (max-width: 1500px)': {
                      width: '130px',
                    },
                  }}
                >
                  {rejectLoad === item?._id ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    <>
                      <Box>
                        {/* <img src={Close} alt="Check" /> */}
                        <Close
                          sx={{
                            color: '#FF5D39',
                          }}
                        />
                      </Box>
                      <Typography
                        fontSize={'14px'}
                        fontWeight={'600'}
                        fontFamily={'Inter'}
                        lineHeight={'20px'}
                        color={'#FF5D39'}
                      >
                        Reject
                      </Typography>
                    </>
                  )}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ReviewModal;
