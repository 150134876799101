import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  Checkbox,
  Rating,
  TextField,
  styled,
  Avatar,
  CircularProgress,
  // StepConnector,
  // stepConnectorClasses,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ReviewIcon from '../../assets/reviewIcon.svg';
import ReviewSubmittedIcon from '../../assets/reviewSubmittedIcon.svg';
import { ReactComponent as Calendar } from '../../assets/calender.svg';
import { Review } from '../../popups/EditReviewModal.popup';
import request from '../../api/request';
import Avatarimg from '../../assets/Avatarimg.svg';
import { toast } from 'react-toastify';
import { mediaUrl } from '../../config';
import CustomToast from '../common/CustomToast';
const steps = ['Services', 'Details', 'Rating', 'Visibility'];

const CustomRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty': {
    color: '#A8A8A8',
  },
  '& .MuiRating-iconFilled': {
    color: '#00BD57 !important',
  },
}));
// const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
//   [`& .${stepConnectorClasses.line}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
// }));

interface ReviewStepperProps {
  review?: Review;
  setReview: React.Dispatch<React.SetStateAction<Review>>;
  companyData?: any;
  getAllReviews?: () => void;
}
interface CheckboxUserData {
  avatarSrc?: string;
  name: string;
  designation: string;
  companyInfo: string;
  job_title?: string;
}

const imgStyle = {
  width: '100px',
  height: '100px',
};

const mediaQueryStyle = {
  '@media (max-width: 450px)': {
    width: '70px',
    height: '70px',
  },
};
const ReviewStepper: React.FC<ReviewStepperProps> = ({
  review,
  setReview,
  companyData,
  getAllReviews,
}) => {
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  console.log('userData', userData);
  const ServiceSteps = review?.services?.map((serviceObj: any) => serviceObj.service);
  console.log('ServiceSteps', ServiceSteps);
  const [activeStep, setActiveStep] = React.useState(0);
  //   const [selectedServices, setSelectedServices] = React.useState<string[]>([]);
  const [selectedCheckbox, setSelectedCheckbox] = React.useState<number | null>(null);
  console.log('selectedCheckbox', selectedCheckbox);
  const [startDate, setStartDate] = React.useState<dayjs.Dayjs | null>(
    review?.job_start_date ? dayjs(review.job_start_date) : null,
  );
  const [completionDate, setCompletionDate] = React.useState<dayjs.Dayjs | null>(
    review?.job_completion_date ? dayjs(review.job_completion_date) : null,
  );
  console.log('completionDate', completionDate);
  const [dueDate, setDueDate] = React.useState<dayjs.Dayjs | null>(
    review?.job_due_date ? dayjs(review.job_due_date) : null,
  );
  const [reason, setReason] = React.useState(review?.budget_adherence);
  const [loading, setLoading] = React.useState<boolean>(false);

  console.log('startDate', startDate);
  console.log('review', review);
  console.log('companyData stepper', companyData);
  //   const handleServiceChange = (
  //     event: React.ChangeEvent<HTMLInputElement>,
  //     service: string,
  //   ): void => {
  //     const updatedServices = event.target.checked ? [service] : [];
  //     setSelectedServices(updatedServices);
  //     setReview((prevReview: any) => ({
  //       ...prevReview,
  //       services: updatedServices,
  //     }));
  //   };
  const handleDateChange = (
    date: dayjs.Dayjs | null,
    type: 'start' | 'completion' | 'due',
  ): void => {
    if (date) {
      if (type === 'start') {
        setStartDate(date);
        setReview((prevReview) => ({
          ...prevReview,
          job_start_date: date.toISOString(),
        }));
      } else if (type === 'completion') {
        setCompletionDate(date);
        setReview((prevReview) => ({
          ...prevReview,
          job_completion_date: date.toISOString(),
        }));
      } else if (type === 'due') {
        setDueDate(date);
        setReview((prevReview) => ({
          ...prevReview,
          job_due_date: date.toISOString(),
        }));
      }
    }
  };
  const handleDateInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: 'start' | 'completion' | 'due',
  ): void => {
    if (event.target.value === '') {
      if (type === 'start') {
        setStartDate(null);
        setReview((prevReview) => ({
          ...prevReview,
          job_start_date: '',
        }));
      } else if (type === 'completion') {
        setCompletionDate(null);
        setReview((prevReview) => ({
          ...prevReview,
          job_completion_date: '',
        }));
      } else if (type === 'due') {
        setDueDate(null);
        setReview((prevReview) => ({
          ...prevReview,
          job_due_date: '',
        }));
      }
    }
  };
  const handleReasonChange = (event: SelectChangeEvent<string>): void => {
    setReason(event.target.value);
    setReview((prevReview) => ({
      ...prevReview,
      budget_adherence: event.target.value,
    }));
  };
  const updateRating = (fieldName: string, newValue: number): void => {
    setReview((prevReview) => ({
      ...prevReview,
      [fieldName]: newValue,
    }));
  };
  // const handleVisibilityChange = (event: SelectChangeEvent, id: number): void => {
  //   setVisibility(!visibility);
  // };

  const handleNext = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleSubmit = (): void => {
    setLoading(true);

    // Handle submission logic here
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('put', `/review/update/${companyData?._id}`, review)
      .then((res: any) => {
        // toast.success(
        //   `${review?.review_type === 'complaint' ? 'Complaint' : 'Review'} Submitted Successfully`,
        // );
        toast(
          <CustomToast
            message={`${
              review?.review_type === 'complaint' ? 'Complaint' : 'Review'
            } Submitted Successfully`}
            type="success"
          />,
        );

        console.log('res searcchh', res);
        handleNext();

        setLoading(false);
        getAllReviews?.();
      })
      .catch((err) => {
        // toast.error("You don't have permission to post this review.");
        toast(
          <CustomToast message={"You don't have permission to post this review."} type="error" />,
        );

        console.log('err', err);
      });
    setLoading(false);
  };
  const isStepComplete = (): boolean => {
    switch (activeStep) {
      case 0:
        return (review?.services ?? []).length > 0;
      case 1:
        return (
          startDate !== null &&
          startDate !== undefined &&
          completionDate !== null &&
          completionDate !== undefined &&
          dueDate !== null &&
          dueDate !== undefined &&
          reason !== ''
        );
      case 2:
        return (
          review?.reliability !== 0 &&
          review?.reliability !== undefined &&
          review?.communication !== 0 &&
          review?.communication !== undefined &&
          review?.work_quality !== 0 &&
          review?.work_quality !== undefined &&
          review?.work_quality !== 0 &&
          review?.work_quality !== undefined &&
          review?.feedback !== ''
        );
      // case 3:
      //   return (
      // review?.reliability > 0 &&
      // review?.communication > 0 &&
      // review?.work_quality > 0 &&
      // review?.lead_time > 0 &&
      // selectedCheckbox !== null || !!review?.is_anonymous
      // );
      default:
        return true;
    }
  };
  console.log('isStepComplete', isStepComplete());
  const handleAnonymousToggle = (): void => {
    setReview((prevReview) => ({
      ...prevReview,
      is_anonymous: !prevReview.is_anonymous,
    }));
  };

  const handleCheckboxChange = (index: number, data: CheckboxUserData): void => {
    setSelectedCheckbox(index);
    setReview((prevReview) => ({
      ...prevReview,
      user: {
        avatar: data.avatarSrc ?? '',
        name: data.name ?? '',
        designation: data.designation ?? '',
        company: data.companyInfo ?? '',
        email: review?.user?.email ?? '',
        job_title: data?.job_title ?? '',
      },
    }));
  };
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      marginTop="2%"
    >
      {activeStep !== steps.length && (
        <React.Fragment>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'10px'}
            marginTop={'4%'}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <img
                src={ReviewIcon}
                alt="Verification Logo"
                style={{ ...imgStyle, ...mediaQueryStyle['@media (max-width: 450px)'] }}
              />
            </Box>
            <Box width="100%" display="flex" justifyContent="center" alignItems="center">
              <Typography
                fontSize={'24px'}
                fontWeight={'800'}
                fontFamily={'SF UI Display'}
                color={'#000000'}
                lineHeight={'28px'}
                id="modal-modal-title"
                sx={{
                  '@media (max-width: 450px)': {
                    fontSize: '20px',
                  },
                }}
              >
                Review {companyData?.companyInfo?.name}
              </Typography>
            </Box>
          </Box>
          <Stepper
            // connector={<CustomStepConnector />}
            sx={{
              width: '60%',
              marginTop: '5%',
              alignItems: 'flex-start',
              '& .MuiStepConnector-root': {
                marginTop: '6%',
              },

              '@media (max-width: 650px)': {
                width: '90%',
              },
            }}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (index < activeStep) {
                stepProps.completed = true;
              }

              const stepNumberStyles = {
                color: stepProps.completed
                  ? '#00BD57'
                  : index === activeStep
                  ? '#00BD57'
                  : '#A8A8A8',
                fontSize: '40px',
                '@media (max-width: 650px)': {
                  fontSize: '30px',
                },
              };

              return (
                <Step
                  key={label}
                  {...stepProps}
                  // sx={{
                  //   paddingLeft: '2px',
                  //   paddingRight: '0',
                  // }}
                >
                  <StepLabel
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      fontWeight: '600',
                      // gap: '12px',
                      fontFamily: 'SF UI Display',
                      color: '#000000',
                      '& .MuiStepLabel-iconContainer': {
                        paddingRight: '0px',
                      },
                      '& .MuiStepLabel-label': {
                        fontSize: '16px',
                        fontFamily: 'SF UI Display',
                        fontWeight: '600',
                        color: stepProps.completed
                          ? '#000000'
                          : index === activeStep
                          ? '#000000'
                          : '#A8A8A8',
                      },
                      '@media (max-width: 650px)': {
                        fontSize: '10px',
                        '& .MuiStepLabel-label': {
                          fontSize: '10px',
                        },
                      },
                    }}
                    {...labelProps}
                    StepIconProps={{ style: stepNumberStyles }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </React.Fragment>
      )}

      {activeStep === steps.length ? (
        <React.Fragment>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <img
              src={ReviewSubmittedIcon}
              alt="Verification Logo"
              style={{ width: '100px', height: '100px' }}
            />
          </Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography
              fontSize={'24px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              // bgcolor={'red'}
              lineHeight={'28px'}
              id="modal-modal-title"
              sx={{
                '@media (max-width: 450px)': {
                  fontSize: '15px',
                },
              }}
            >
              {`The ${
                review?.review_type === 'complaint' ? 'complaint' : 'review'
              } has been updated!`}
            </Typography>
          </Box>
          {/* <Typography
            sx={{
              mt: 2,
              mb: 1,
              textAlign: 'center',
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              '@media (max-width: 450px)': {
                fontSize: '14px',
              },
            }}
          >
            Thank you for taking the time to share your experience with us. All reviews are screened
            and verified before posting. If your review does not meet our guidelines for acceptance
            we will reach out by email.
          </Typography> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 && (
            <Box
              width={'100%'}
              marginTop={'4%'}
              height={'200px'}
              sx={{
                overflowY: 'scroll',
              }}
            >
              {review?.services?.map((item: any, index: number) => (
                <Box
                  key={index}
                  display={'flex'}
                  width={'100%'}
                  alignItems={'center'}
                  gap={'3%'}
                  padding={'2% 1%'}
                  border={'1px solid #E8E8E8'}
                  borderRadius={'10px'}
                  margin={'2% 0'}
                >
                  <Checkbox
                    // onChange={(event) => {
                    //   handleServiceChange(event, item);
                    // }}
                    // checked={selectedServices.includes(item)}
                    checked={review?.services?.includes(item)}
                    disabled={review?.services?.includes(item)}
                    sx={{
                      color: '#000000',
                      '&.Mui-checked': {
                        color: '#00BD57',
                      },
                    }}
                  />
                  <Typography
                    fontSize={'16px'}
                    fontWeight={'700'}
                    fontFamily={'SF UI Display'}
                    lineHeight={'19px'}
                    color={'#000000'}
                    sx={{
                      '@media (max-width: 450px)': {
                        fontSize: '10px',
                      },
                    }}
                  >
                    {item}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          {activeStep === 1 && (
            <Box
              width={'100%'}
              marginTop={'4%'}
              display={'flex'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              sx={{
                overflowY: 'scroll',
                '@media (max-width: 650px)': {
                  flexDirection: 'column',
                },
              }}
              height="200px"
            >
              <Box
                width={'48%'}
                padding={'2% 1%'}
                margin={'2% 0'}
                sx={{
                  '@media (max-width: 650px)': {
                    width: '100%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  fontFamily={'SFProDisplay'}
                  lineHeight={'19px'}
                  padding={' 8px 0px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  Job Start Date
                </Typography>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'300'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  marginBottom={'15px'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  When did you hire them?
                </Typography>
                <Box width={'100%'} marginTop={'2%'}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      slots={{
                        openPickerIcon: Calendar,
                      }}
                      slotProps={{
                        textField: {
                          placeholder: 'MM/DD/YYYY',
                          InputProps: {
                            sx: {
                              '&::placeholder': {
                                fontFamily: 'SF UI Display',
                                fontWeight: '300',
                                fontSize: '16px',
                              },
                              fontFamily: 'SF UI Display',
                              fontWeight: startDate ? '400' : '400',
                              fontSize: '16px',
                              color: '#000000',
                            },
                            onChange: (event) => {
                              handleDateInputChange(event, 'start');
                            },
                          },
                        },
                        inputAdornment: {
                          position: 'start',
                        },
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 0',
                        },
                      }}
                      value={startDate}
                      onChange={(date) => {
                        handleDateChange(date, 'start');
                      }}
                      format={startDate ? 'MMMM DD, YYYY' : 'MM/DD/YYYY'}
                      maxDate={(completionDate ?? dueDate) as Dayjs | undefined}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box
                width={'48%'}
                padding={'2% 1%'}
                margin={'2% 0'}
                sx={{
                  '@media (max-width: 650px)': {
                    width: '100%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  fontFamily={'SFProDisplay'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  padding={' 8px 0px'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  Job Completion Date
                </Typography>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'300'}
                  marginBottom={'15px'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  When did they complete the job?
                </Typography>

                <Box width={'100%'} marginTop={'2%'}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      slots={{
                        openPickerIcon: Calendar,
                      }}
                      slotProps={{
                        textField: {
                          placeholder: 'MM/DD/YYYY',
                          InputProps: {
                            sx: {
                              '&::placeholder': {
                                fontFamily: 'SF UI Display',
                                fontWeight: '300',
                                fontSize: '16px',
                              },
                              fontFamily: 'SF UI Display',
                              fontWeight: dueDate ? '400' : '400',
                              fontSize: '16px',
                              color: '#000000',
                            },
                            onChange: (event) => {
                              handleDateInputChange(event, 'completion');
                            },
                          },
                        },
                        inputAdornment: {
                          position: 'start',
                        },
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 0',
                        },
                      }}
                      value={completionDate}
                      onChange={(date) => {
                        handleDateChange(date, 'completion');
                      }}
                      format={completionDate ? 'MMMM DD, YYYY' : 'MM/DD/YYYY'}
                      minDate={startDate ?? undefined}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box
                width={'48%'}
                padding={'2% 1%'}
                margin={'2% 0'}
                sx={{
                  '@media (max-width: 650px)': {
                    width: '100%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  fontFamily={'SFProDisplay'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  padding={' 8px 0px'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  Job Due Date
                </Typography>
                <Typography
                  fontSize={'16px'}
                  marginBottom={'15px'}
                  fontWeight={'300'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  Did they finish the job on time?
                </Typography>

                <Box width={'100%'} marginTop={'2%'}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      slots={{
                        openPickerIcon: Calendar,
                      }}
                      slotProps={{
                        textField: {
                          placeholder: 'MM/DD/YYYY',
                          InputProps: {
                            sx: {
                              '&::placeholder': {
                                fontFamily: 'SF UI Display',
                                fontWeight: '300',
                                fontSize: '16px',
                              },
                              fontFamily: 'SF UI Display',
                              fontWeight: dueDate ? '400' : '400',
                              fontSize: '16px',
                              color: '#000000',
                            },
                            onChange: (event) => {
                              handleDateInputChange(event, 'due');
                            },
                          },
                        },
                        inputAdornment: {
                          position: 'start',
                        },
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiOutlinedInput-input': {
                          padding: '8px 0',
                        },
                      }}
                      value={dueDate}
                      onChange={(date) => {
                        handleDateChange(date, 'due');
                      }}
                      format={dueDate ? 'MMMM DD, YYYY' : 'MM/DD/YYYY'}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box
                width={'48%'}
                padding={'2% 1%'}
                margin={'2% 0'}
                sx={{
                  '@media (max-width: 650px)': {
                    width: '100%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'500'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  padding={' 8px 0px'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  Budget Adherence
                </Typography>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'300'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  marginBottom={'15px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  Did they come in on Budget?
                </Typography>
                <Box width={'100%'} marginTop={'2%'}>
                  <FormControl
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        padding: '8px 14px',
                      },
                    }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reason}
                      onChange={handleReasonChange}
                      displayEmpty
                      renderValue={(selected) => {
                        if (selected) {
                          return (
                            <Typography
                              sx={{
                                fontFamily: 'SFProDisplay',
                                fontWeight: '400',
                                fontSize: '16px',
                              }}
                            >
                              {selected}
                            </Typography>
                          );
                        }
                        return <em>Select one</em>;
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select one
                      </MenuItem>
                      <MenuItem
                        value={'Yes they did.'}
                        sx={{ fontFamily: 'SF UI Display', fontWeight: '400' }}
                      >
                        Yes they did.
                      </MenuItem>
                      <MenuItem
                        value={"No they didn't"}
                        sx={{ fontFamily: 'SF UI Display', fontWeight: '400' }}
                      >
                        No they didn&apos;t
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          )}
          {activeStep === steps.length - 2 && (
            <Box
              width={'100%'}
              marginTop={'4%'}
              height={'250px'}
              marginBottom={'4%'}
              display={'flex'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              sx={{
                overflowY: 'scroll',

                '@media (max-width: 650px)': {
                  flexDirection: 'column',
                  flexWrap: 'unset',
                },
              }}
            >
              <Box
                width={'48%'}
                padding={'2% 1%'}
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  Reliability
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="reliability"
                  value={review?.reliability}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('reliability', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>
              <Box
                width={'48%'}
                padding={'2% 1%'}
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
              >
                {' '}
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  Communication
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="communication"
                  value={review?.communication}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('communication', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>
              <Box
                width={'48%'}
                padding={'2% 1%'}
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
              >
                {' '}
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  Work Quality
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="workQuality"
                  value={review?.work_quality}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('work_quality', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>
              <Box
                width={'48%'}
                padding={'2% 1%'}
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
              >
                {' '}
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  Lead Time
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="leadTime"
                  value={review?.lead_time}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('lead_time', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>

              <Box
                width={'100%'}
                padding={'2% 1%'}
                display={'flex'}
                flexDirection={'column'}
                gap={'10px'}
              >
                {' '}
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  Feedback
                </Typography>
                <TextField
                  sx={{ marginTop: '2%' }}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  placeholder="Write Feedback Here..."
                  value={review?.feedback}
                  onChange={(event) => {
                    setReview((prevReview) => ({
                      ...prevReview,
                      feedback: event.target.value,
                    }));
                  }}
                />
              </Box>
            </Box>
          )}
          {activeStep === steps.length - 1 && (
            <Box
              width={'100%'}
              marginTop={'4%'}
              display={'flex'}
              justifyContent={'center'}
              flexWrap={'wrap'}
            >
              <Box
                width={'48%'}
                padding={'2% 1%'}
                margin={'2% 0'}
                display={'flex'}
                flexDirection={'column'}
                gap={'20px'}
                sx={{
                  '@media (max-width: 650px)': {
                    width: '90%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'400'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  textAlign={'center'}
                  whiteSpace={'nowrap'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  {review?.user
                    ? `The ${
                        review?.review_type === 'complaint' ? 'complaint' : 'review'
                      } was submitted as`
                    : 'Most Anonymously.'}{' '}
                </Typography>
                {review?.user ? (
                  <Box>
                    <Box display="flex" alignItems="center" marginTop="8px" gap={'20px'}>
                      <Checkbox
                        checked={true}
                        disabled={true}
                        sx={{
                          color: '#000000',
                          '&.Mui-checked': {
                            color: '#00BD57',
                          },
                        }}
                        onChange={() => {
                          handleCheckboxChange(1, {
                            avatarSrc: review?.user?.avatar ?? '',
                            name: review?.user?.name ?? '',
                            designation: review?.user?.designation ?? '',
                            job_title: review?.user?.job_title ?? '',
                            companyInfo: review?.user?.company ?? '',
                          });
                        }}
                      />
                      <Avatar
                        /* eslint-disable @typescript-eslint/restrict-template-expressions */

                        src={
                          review?.user?.avatar ? `${mediaUrl}/${review?.user?.avatar}` : Avatarimg
                        }
                      />
                      <Box>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'600'}
                          color={'#000000'}
                          fontFamily={'SF UI Display'}
                        >
                          {review?.user?.name
                            ? review?.user?.name
                            : review?.user?.job_title ?? 'N/A'}
                        </Typography>
                        <Typography
                          fontSize={'14px'}
                          fontWeight={'600'}
                          color={'#606060'}
                          fontFamily={'SF UI Display'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {review?.user?.company ?? 'N/A'}
                        </Typography>
                      </Box>
                    </Box>

                    {/* <Box display="flex" alignItems="center" marginTop="8px" gap={'20px'}>
                      <Checkbox
                        checked={selectedCheckbox === 2}
                        onChange={() => {
                          handleCheckboxChange(2, {
                            avatarSrc: '',
                            name: '',
                            designation: '',
                            job_title: userData?.job_title || '',
                            companyInfo: userData?.company?.companyInfo?.name || '',
                          });
                        }}
                        sx={{
                          color: '#000000',
                          '&.Mui-checked': {
                            color: '#00BD57',
                          },
                        }}
                      />
                      <Avatar src={Avatarimg} />
                      <Box>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'600'}
                          color={'#000000'}
                          fontFamily={'SF UI Display'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.type === 'owner' ? 'Owner' : 'Team Member'}
                        </Typography>
                        <Typography
                          fontSize={'14px'}
                          fontWeight={'500'}
                          color={'#606060'}
                          fontFamily={'SF UI Display'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.company?.companyInfo?.name}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" marginTop="8px" gap={'20px'}>
                      <Checkbox
                        checked={selectedCheckbox === 3}
                        onChange={() => {
                          handleCheckboxChange(3, {
                            avatarSrc: '',
                            name: '',
                            designation: '',
                            job_title: userData?.job_title || '',
                            companyInfo: userData?.company?.companyInfo?.companyType || '',
                          });
                        }}
                        sx={{
                          color: '#000000',
                          '&.Mui-checked': {
                            color: '#00BD57',
                          },
                        }}
                      />
                      <Avatar src={Avatarimg} />
                      <Box>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'600'}
                          color={'#000000'}
                          fontFamily={'SF UI Display'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.type === 'owner' ? 'Owner' : 'Team Member'}
                        </Typography>
                        <Typography
                          fontSize={'14px'}
                          fontWeight={'600'}
                          color={'#606060'}
                          fontFamily={'SF UI Display'}
                          textTransform={'capitalize'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.company?.companyInfo?.companyType} Sign Company
                        </Typography>
                      </Box>
                    </Box> */}
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" marginTop="8px" gap={'20px'}>
                    <Checkbox
                      checked={review?.is_anonymous}
                      onChange={handleAnonymousToggle}
                      disabled={true}
                      style={{ color: '#00BD57' }}
                    />
                    <Avatar src={Avatarimg} />
                    <Box>
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'600'}
                        color={'#000000'}
                        fontFamily={'SF UI Display'}
                        sx={{
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                          },
                        }}
                      >
                        Anonymous
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <Box width="100%" display="flex" pt="2" justifyContent="right" marginTop={'10px'}>
            <Button
              sx={{
                bgcolor: !isStepComplete() ? '#DCDEDF' : '#00BD57',
                color: '#ffffff',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '19px',
                fontFamily: 'SF UI Display',
                width: '86px',
                padding: '1.5% 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
                '@media (max-width:650px)': {
                  fontSize: '12px',
                  width: '10%',
                },
              }}
              onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
              disabled={!isStepComplete() || loading}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" />
              ) : activeStep === steps.length - 1 ? (
                'Submit'
              ) : (
                'Next'
              )}{' '}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ReviewStepper;
