import React, { FC, useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import Logo from '../assets/logo.svg';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SplashScreen from '../pages/SplashScreen.pages';
import request from '../api/request';
import CustomToast from '../components/common/CustomToast';

interface LoginState {
  email: string;
  password: string;
  token?: string;
  admin?: string;
}

const SignIn: FC = () => {
  const nav = useNavigate();
  const [loginState, setLoginState] = useState<LoginState>({ email: '', password: '' });
  const [showSplash, setShowSplash] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 2000); // 2 seconds
    return () => {
      clearTimeout(timer);
    }; // Cleanup the timer on component unmount
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setLoginState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (): void => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    const { email, password } = loginState;
    const isValidEmail = emailRegex.test(email);
    if (!email || !password) {
      // toast.error(Enter both email and password to sign in);
      toast(<CustomToast message={'Enter both email and password to sign in'} type="error" />);
    } else if (!isValidEmail) {
      // toast.error('Enter Valid Email');
      toast(<CustomToast message={'Enter Valid Email'} type="error" />);
    } else {
      setLoading(true);

      request('post', `/admins/login`, loginState)
        .then((res: LoginState) => {
          // toast.success('Logged in successfully');
          toast(<CustomToast message={'Logged in successfully'} type="success" />);

          if (res) {
            localStorage.setItem('token', res?.token ?? '');
            localStorage.setItem('admin', JSON.stringify(res?.admin));

            window.location.reload();
            nav('/dashboard/home');
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);

          console.log('err', err);
          // toast.error(err?.response?.data?.message);
          toast(<CustomToast message={err?.response?.data?.message} type="error" />);
        });
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  if (showSplash) {
    return <SplashScreen />;
  }

  return (
    <Box
      width={'100vw'}
      display={'flex'}
      height={'100vh'}
      justifyContent={'center'}
      alignItems={'center'}
      bgcolor={'#FFFFFF'}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'30px'}
        borderRadius={'20px'}
        gap={'30px'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'40px'}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box width={'50%'} display={'flex'} justifyContent={'center'}>
            <img src={Logo} alt="Logo" />
          </Box>

          <Typography
            fontSize={'16px'}
            fontWeight={'500'}
            color={'#000000'}
            width={'40%'}
            fontFamily={'SF UI Display'}
            letterSpacing={'unset'}
            textAlign={'center'}
          >
            Sign In as Admin{' '}
          </Typography>
        </Box>

        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          gap={'22px'}
          justifyContent={'center'}
          alignItems={'center'}
          marginTop={'4%'}
        >
          <Box width={'70%'}>
            <TextField
              required
              type="email"
              placeholder="Email"
              value={loginState.email}
              name="email"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                width: '100%',
                margin: 'auto',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '8px',
                },
              }}
            />
          </Box>
          <Box width={'70%'}>
            <TextField
              required
              type="password"
              placeholder="Password"
              value={loginState.password}
              name="password"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                width: '100%',
                margin: 'auto',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                },
              }}
              InputProps={{
                style: {
                  borderRadius: '8px',
                },
              }}
            />
          </Box>
          <Box width={'70%'} marginTop={'3%'}>
            <Button
              onClick={handleSubmit}
              fullWidth
              sx={{
                padding: '12px 24px',
                bgcolor: 'black',
                borderRadius: '12px',
                color: 'white',
                textTransform: 'unset',
                fontSize: '14px',
                fontFamily: 'SF UI Display',
                fontWeight: '600',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  bgcolor: 'black',
                  color: 'white',
                },
              }}
            >
              {loading ? <CircularProgress size={15} color="inherit" /> : '            Sign In  '}{' '}
            </Button>
          </Box>

          <Typography
            fontSize={'14px'}
            fontWeight={'500'}
            color={'#000000'}
            width={'58%'}
            textAlign={'center'}
            marginTop={'2%'}
          >
            By continuing you indicate that you agree to SignWise&nbsp;
            <NavLink
              to={'/terms-and-conditions'}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Terms & Conditions
            </NavLink>
            &nbsp;and&nbsp;
            <NavLink
              to={'/privacy-policy'}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              Privacy Policy.
            </NavLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
