import React, { FC } from 'react';
import { Box } from '@mui/material';
import Logo from '../assets/logo.svg';

const SplashScreen: FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
      bgcolor="white"
    >
      <Box width={'40%'}>
        <img style={{ width: '100%' }} src={Logo} alt="Logo" />
      </Box>
    </Box>
  );
};

export default SplashScreen;
