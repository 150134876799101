import React, { ReactElement } from 'react';
import SignIn from '../unAuth/Signin.unAuth';
import Request from '../pages/Request.pages';
import Home from '../pages/Dashboard.pages';
import AddAccount from '../layout/AddAccount.layout';
import AddUsers from '../pages/AddUsers.pages';
import Review from '../pages/Review.pages';
import TopSearch from '../pages/TopSearch.pages';
import RequestDetail from '../pages/RequestDetail.pages';
import ClaimCompany from '../pages/ClaimCompany.page';
import ResetPassword from '../pages/ResetPassword';
import Companies from '../pages/Companies.pages';
import Users from '../pages/Users.pages';
import ClaimDetails from './../pages/ClaimDetails.pages';
import EditReview from '../pages/EditReview.pages';
import Messages from '../pages/Messages.pages';
import TermsAndConditions from '../pages/TermsAndConditions.pages';
import PrivacyPolicy from '../pages/PrivacyPolicy.pages';

// Types
interface BaseRoute {
  path: string;
  protected?: boolean;
  failureRedirect?: string;
  children?: Route[] | UnAuthRoute[];
}

interface Element extends BaseRoute {
  element: ReactElement;
  navigate?: never;
}

interface Navigate extends BaseRoute {
  navigate: string;
  element?: never;
}

type Route = Element | Navigate;
type UnAuthRoute = Element | Navigate;

// Authenticated Routes
const routes: Route[] = [
  {
    path: '/dashboard/home',
    element: <Home />,
  },
  {
    path: '/dashboard/companies',
    element: <Companies />,
  },
  {
    path: '/dashboard/users',
    element: <Users />,
  },
  {
    path: '/dashboard/request',
    element: <Request />,
  },
  {
    path: '/dashboard/claimcompany',
    element: <ClaimCompany />,
  },
  {
    path: '/dashboard/requestdetail/:companyId',
    element: <RequestDetail />,
  },
  {
    path: '/dashboard/claimdetails/:claimId',
    element: <ClaimDetails />,
  },
  {
    path: '/dashboard/review',
    element: <Review />,
  },
  {
    path: '/dashboard/messages',
    element: <Messages />,
  },
  {
    path: '/dashboard/edit-review',
    element: <EditReview />,
  },
  {
    path: '/dashboard/topsearch',
    element: <TopSearch />,
  },
  {
    path: '/addcompany/*',
    element: <AddAccount />,
  },
  {
    path: '/adduser',
    element: <AddUsers />,
  },
  {
    path: '/resetpassword',
    element: <ResetPassword />,
  },
  {
    path: '*',
    navigate: '/dashboard/home',
  },
];

// Unauthenticated Routes
const unAuthRoutes: UnAuthRoute[] = [
  {
    path: '/',
    element: <SignIn />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: '*',
    navigate: '/',
  },
];

export { routes, unAuthRoutes };
