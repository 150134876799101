import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
// import { Link, Route, Routes } from 'react-router-dom';
import Header from '../components/Header.component';

import { LocalSignRoutes } from '../routes/routes';
import AddLocalCompany from '../pages/addCompany/AddLocalCompany.pages';
import AddNationalCompany from '../pages/addCompany/AddNationalCompany.pages';
import AddSupplier from '../pages/addCompany/AddSupplier.pages';
import { useLocation } from 'react-router-dom';
import AddDistributor from '../pages/addCompany/AddDistributor.pages';

const AddAccount: FC = () => {
  const [activePath, setActivePath] = useState('Local Company');
  const location = useLocation();
  const { user, domain, company } = location.state || {};
  const concatedUrl = (layout: string, path: string): string => {
    return layout + path;
  };
  const handleClick = (path: string): void => {
    setActivePath(path);
  };
  useEffect(() => {
    if (company?._id) {
      const type = company?.companyInfo?.companyType;
      const pathname =
        type === 'local'
          ? 'Local Company'
          : type === 'national'
          ? 'National Company'
          : type === 'supplier'
          ? 'Suppliers'
          : 'Distributors';
      setActivePath(pathname);
    }
  }, [company]);
  return (
    <Box width="100%" display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Header hideLinks={true} />
      <Box width={'74%'}>
        <Box width={'100%'} margin={'4% 0'}>
          <Typography
            fontSize={'24px'}
            fontFamily={'Inter'}
            fontWeight={'600'}
            lineHeight={'20px'}
            color={'#000000'}
          >
            Choose Company Type Below:
          </Typography>
        </Box>
        <Box width={'100%'} margin={'2% 0'} display={'flex'} gap={'10px'}>
          {LocalSignRoutes.map((item, index) => {
            const path = concatedUrl(item.layout, item.path);
            const isDisabled = path !== '/addcompany/localcompany';
            return (
              <Box
                key={item.path}
                width={'90%'}
                border={
                  activePath === item.name
                    ? '2px solid transparent'
                    : isDisabled
                    ? '2px solid rgba(71, 180, 95, 0.40)'
                    : '2px solid #47B45F66'
                }
                borderRadius={'12px'}
                // padding={'2%'}
                bgcolor={
                  activePath === item.name ? '#47B45F33' : isDisabled ? '#f0f0f0' : '#ffffff'
                }
                sx={{
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                  opacity: isDisabled ? '0.4' : '1',
                }}
              >
                {isDisabled ? (
                  <Box width={'100%'} padding={'16px'}>
                    <Box
                      width={'100%'}
                      display={'flex'}
                      alignItems={'center'}
                      gap={'8px'}
                      marginBottom={'9px'}
                    >
                      <Box width={'24px'} height={'24px'}>
                        <img style={{ width: '100%' }} src={item?.icon} alt="Icon" />
                      </Box>
                      <Typography
                        fontSize={'16px'}
                        fontFamily={'Inter'}
                        fontWeight={'700'}
                        lineHeight={'20px'}
                        color={'#000000'} // Gray color for disabled text
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                    <Typography
                      fontSize={'12px'}
                      fontFamily={'Inter'}
                      fontWeight={'400'}
                      lineHeight={'20px'}
                      color={'#000000'} // Gray color for disabled text
                      width={'100%'}
                    >
                      {item?.desp}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    onClick={() => {
                      handleClick(item.name);
                    }}
                    width={'100%'}
                    padding={'16px'}
                  >
                    <Box
                      width={'100%'}
                      display={'flex'}
                      alignItems={'center'}
                      gap={'8px'}
                      marginBottom={'9px'}
                    >
                      <Box width={'24px'} height={'24px'}>
                        <img style={{ width: '100%' }} src={item?.icon} alt="Icon" />
                      </Box>
                      <Typography
                        fontSize={'16px'}
                        fontFamily={'Inter'}
                        fontWeight={'700'}
                        lineHeight={'20px'}
                        color={'#000000'}
                        whiteSpace="pre"
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                    <Typography
                      fontSize={'12px'}
                      fontFamily={'Inter'}
                      fontWeight={'400'}
                      lineHeight={'20px'}
                      color={'#000000'}
                      // width={'100%'}
                    >
                      {item?.desp}
                    </Typography>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>

        <Box width={'100%'} display="flex" bgcolor="#FFFFFF" height="100%" marginBottom={'2%'}>
          {activePath.includes('Local') ? (
            <AddLocalCompany user={user} domain={domain} company={company} />
          ) : activePath.includes('National') ? (
            <AddNationalCompany user={user} domain={domain} company={company} />
          ) : activePath.includes('Supplier') ? (
            <AddSupplier user={user} domain={domain} company={company} />
          ) : (
            <AddDistributor user={user} domain={domain} company={company} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddAccount;
