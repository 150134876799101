import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../components/Header.component';
import request from '../api/request';
import { toast } from 'react-toastify';
// import { Visibility, VisibilityOff } from '@mui/icons-material';
import VisibilityOff from '../assets/EyeoffIcon.svg';
import Visibility from '../assets/eyeicon.svg';
import CustomToast from '../components/common/CustomToast';

const ResetPassword: FC = () => {
  const adminJSON = localStorage.getItem('admin');
  const adminData = adminJSON ? JSON.parse(adminJSON) : '';
  const [loading, setLoading] = React.useState(false);

  const [passwords, setPasswords] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });
  console.log('passwords', passwords);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };
  const handleClickShowPassword = (passwordType: string): void => {
    setShowPassword((prevShowPassword: any) => ({
      ...prevShowPassword,
      [passwordType]: !prevShowPassword[passwordType],
    }));
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };

  const handleSubmit = (): void => {
    if (adminData?._id) {
      setLoading(true);
      /* eslint-disable @typescript-eslint/restrict-template-expressions */
      request('patch', `/admins/${adminData?._id}`, passwords)
        .then((res: any) => {
          console.log('res searcchh', res);
          // toast.success('Password updated successfully');
          toast(<CustomToast message={'Password updated successfully'} type="Success" />);
          setPasswords({
            oldPassword: '',
            password: '',
            confirmPassword: '',
          });
          setShowPassword({
            oldPassword: false,
            password: false,
            confirmPassword: false,
          });
          setLoading(false);
        })
        .catch((err) => {
          // toast.error(err?.response?.data?.message);
          toast(<CustomToast message={err?.response?.data?.message} type="Success" />);
          console.log('err', err);
          setLoading(false);
        });
    }
  };
  const isDisabled =
    !passwords.oldPassword || !passwords.password || !passwords.confirmPassword || loading;
  return (
    <Box width="100%" bgcolor="white">
      <Header />
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        height={'90vh'}
      >
        <Box
          width="30%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            '@media (max-width: 1024px)': {
              width: '50%',
            },
            '@media (max-width: 600px)': {
              width: '80%',
            },
            '@media (max-width: 420px)': {
              width: '90%',
            },
          }}
        >
          <Typography
            marginBottom={'5%'}
            variant="h4"
            fontWeight={'600'}
            fontFamily={'SF UI Display'}
          >
            Reset Password
          </Typography>
          <TextField
            label="Old Password"
            type={showPassword.oldPassword ? 'text' : 'password'}
            name="oldPassword"
            value={passwords.oldPassword}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle old password visibility"
                    onClick={() => {
                      handleClickShowPassword('oldPassword');
                    }}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword.oldPassword ? (
                      <img src={VisibilityOff} alt=".." />
                    ) : (
                      <img src={Visibility} alt="." />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New Password"
            type={showPassword.password ? 'text' : 'password'}
            name="password"
            value={passwords.password}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle new password visibility"
                    onClick={() => {
                      handleClickShowPassword('password');
                    }}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword.password ? (
                      <img src={VisibilityOff} alt=".." />
                    ) : (
                      <img src={Visibility} alt="." />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={showPassword.confirmPassword ? 'text' : 'password'}
            name="confirmPassword"
            value={passwords.confirmPassword}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={() => {
                      handleClickShowPassword('confirmPassword');
                    }}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword.confirmPassword ? (
                      <img src={VisibilityOff} alt=".." />
                    ) : (
                      <img src={Visibility} alt="." />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            fullWidth
            variant="contained"
            // color="primary"
            onClick={handleSubmit}
            disabled={isDisabled}
            sx={{
              mt: 2,
              textTransform: 'capitalize',
              bgcolor: '#00BD57',
              padding: '10px 0',
              fontWeight: '600',
              fontFamily: 'SF UI Display',
              '&:hover': {
                bgcolor: '#00BD57',
              },
            }}
          >
            {loading ? <CircularProgress size={15} color="inherit" /> : 'Update'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
