/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DefaultAvatar from '../../assets/avatar.svg';

// import CompanyImage from '../../assets/companyAvatar.png';s
import { Link, useNavigate } from 'react-router-dom';
import request from '../../api/request';
import Add from '../../assets/addicon.svg';
import { mediaUrl } from '../../config';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import CustomToast from '../common/CustomToast';

const RegisteredUsers: FC = () => {
  const nav = useNavigate();
  const pathname = window.location.pathname;
  const [page, setPage] = useState(1);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>();
  const handleOpenDeleteConfirmation = (user: any): void => {
    setSelectedUser(user);
    setOpenDeleteConfirmation(true);
  };
  const handleCloseDeleteConfirmation = (): void => {
    setSelectedUser('');
    setOpenDeleteConfirmation(false);
  };
  console.log('allUsers', allUsers);

  const itemsPerPage = pathname === '/dashboard/home' ? 5 : 30;
  const totalPages = Math.ceil(allUsers.length / itemsPerPage);

  const handlePreviousPage = (): void => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (): void => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const getAllUsers = (): void => {
    setLoading(true);
    request('get', `/users`)
      .then((res: any) => {
        // toast.success('Success');
        console.log('res searcchh', res);
        setAllUsers(res?.users ?? []);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
      });
  };
  const handleDeleteUser = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('delete', `/users/${selectedUser}`)
      .then((res: any) => {
        console.log('delete user res', res);
        // toast.success('User deleted successfully');
        toast(<CustomToast message={'User deleted successfully'} type="success" />);
        handleCloseDeleteConfirmation();
        getAllUsers();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = allUsers.slice(startIndex, endIndex);
  console.log('paginatedRows user', paginatedRows);
  useEffect(() => {
    getAllUsers();
  }, []);
  return (
    <Box
      width={'74%'}
      marginBottom={'2%'}
      borderRadius={'12px'}
      border={'1px solid #EAECF0'}
      bgcolor={'#FFF'}
      boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
      minHeight={'50vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box width={'100%'}>
        <Box
          width={'100%'}
          padding={'20px 24px'}
          borderBottom={'1px solid #EAECF0'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <Typography
              color={'#101828'}
              fontFamily={'Inter'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'28px'}
            >
              Registered User{' '}
            </Typography>
            <Typography
              color={'#475467'}
              fontFamily={'Inter'}
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
            >
              Below users have registered recently{' '}
            </Typography>
          </Box>
          <Link to={'/adduser'}>
            <Button
              sx={{
                bgcolor: '#00BD57',
                border: '1px solid #00BD57',
                borderRadius: '8px',
                padding: '10px 14px',
                color: '#ffffff',
                textTransform: 'capitalize',
                fontSize: '14px',
                fontWeight: '600',
                gap: '12px',
                lineHeight: '20px',
                '&:hover': {
                  bgcolor: '#00BD57',
                },
              }}
            >
              Add User
              <img src={Add} alt="Add" />{' '}
            </Button>
          </Link>
        </Box>
        <Box width={'100%'}>
          <TableContainer sx={{ display: 'flex', flexDirection: 'column' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: '30%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                      padding: '0 24px',
                    }}
                  >
                    User Name
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '20%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Job Title
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '15%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Role
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '30%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Company
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '5%',
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              {paginatedRows?.length ? (
                <TableBody>
                  {paginatedRows.map((item: any, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '&:hover': { backgroundColor: '#FADE701A' },
                      }}
                    >
                      <TableCell
                        sx={{
                          width: '30%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                          padding: '16px 24px',
                        }}
                        component="th"
                        scope="row"
                      >
                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'12px'}>
                          {/* <Box
                          width={'40px'}
                          height={'40px'}
                          borderRadius={'20px'}
                          overflow={'hidden'}
                        >
                          <img style={{ width: '100%' }} src={item?.logo} alt="CompanyImage" />
                        </Box> */}
                          <Box>
                            <Typography
                              color={'#101828'}
                              fontFamily={'Inter'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'20px'}
                            >
                              {item?.first_name} {item?.last_name}
                            </Typography>
                            <Typography
                              color={'#475467'}
                              fontFamily={'Inter'}
                              fontSize={'14px'}
                              fontWeight={'400'}
                              lineHeight={'20px'}
                            >
                              {item?.email}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '20%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Typography
                          textTransform={'capitalize'}
                          color={'#475467'}
                          fontFamily={'Inter'}
                          fontSize={'14px'}
                          fontWeight={'400'}
                          lineHeight={'20px'}
                        >
                          {item.job_title ? item.job_title : '-'}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '15%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          color={item.type === 'owner' ? '#067647' : '#FFFFFF'}
                          fontFamily={'Inter'}
                          fontSize={'12px'}
                          fontWeight={'400'}
                          lineHeight={'24px'}
                          bgcolor={item.type === 'owner' ? '#ECFDF3' : '#000000'}
                          border={'1px solid ' + (item.type === 'owner' ? '#ABEFC6' : '#000000')}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'4px'}
                          borderRadius={'50px'}
                          boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                          padding={'2px 6px'}
                          width={'fit-content'}
                          textTransform={'capitalize'}
                        >
                          {item.type === 'owner' ? `Page ${item.type}` : 'Team Member'}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '30%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'10px'}>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              alt="Avatar"
                              src={
                                /* eslint-disable @typescript-eslint/restrict-template-expressions */ item
                                  .company?.companyInfo?.logo
                                  ? `${mediaUrl}/${item?.company?.companyInfo?.logo}`
                                  : DefaultAvatar
                              }
                              sx={{ width: '40px', height: '40px' }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              color={'rgba(16, 24, 40, 1)'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'20px'}
                              textTransform={'capitalize'}
                            >
                              {item?.company?.companyInfo?.name}
                            </Typography>
                            <Typography
                              color={'rgba(71, 84, 103, 1)'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'300'}
                              lineHeight={'20px'}
                            >
                              {item?.company?.companyInfo?.address?.city && (
                                <>{item?.company?.companyInfo?.address?.city}</>
                              )}
                              {item?.company?.companyInfo?.address?.state && (
                                <>
                                  {item?.company?.companyInfo?.address?.city && ', '}
                                  {item?.company?.companyInfo?.address?.state}
                                </>
                              )}
                              {item?.company?.companyInfo?.address?.country && (
                                <>
                                  {(item?.company?.companyInfo?.address?.city ||
                                    item.company?.companyInfo?.address?.state) &&
                                    ', '}
                                  {item?.company?.companyInfo?.address?.country}
                                </>
                              )}
                              {/* {item?.company?.companyInfo?.description
                                ? item.company.companyInfo.description.length > 30
                                  ? (item.company.companyInfo.description as string).substring(
                                      0,
                                      30,
                                    ) + '...'
                                  : item.company.companyInfo.description
                                : 'No Description'} */}
                              {/*  {item?.feedback && item.feedback.length > 36
                            ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                              `${item.feedback.slice(0, 36)}...`
                            : item.feedback} */}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '5%',
                        }}
                      >
                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'10px'}>
                          <DeleteIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleOpenDeleteConfirmation(item?._id);
                            }}
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                ''
              )}
            </Table>
            {!paginatedRows?.length ? (
              <Typography
                color={'#101828'}
                fontFamily={'Inter'}
                fontSize={'18px'}
                fontWeight={'600'}
                lineHeight={'28px'}
                padding={'2% 5%'}
                whiteSpace={'nowrap'}
              >
                {loading ? <CircularProgress size={15} color="inherit" /> : ' No Users Found'}
              </Typography>
            ) : (
              ''
            )}
          </TableContainer>
        </Box>
      </Box>
      {allUsers?.length ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="32px"
          sx={{
            '@media (max-width:520px)': {
              flexDirection: 'column',
              gap: '10px',
            },
          }}
        >
          <Box display={'flex'} alignItems={'center'} gap={'12px'}>
            <Button
              variant="outlined"
              onClick={handlePreviousPage}
              disabled={page === 1}
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D0D5DD',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#344054',
                padding: '8px 12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
                '&.Mui-disabled': {
                  borderColor: '#E0E0E0',
                  color: '#E0E0E0',
                },
              }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={handleNextPage}
              disabled={page === totalPages}
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D0D5DD',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#344054',
                padding: '8px 12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
                '&.Mui-disabled': {
                  borderColor: '#E0E0E0',
                  color: '#E0E0E0',
                },
              }}
            >
              Next
            </Button>
          </Box>
          {pathname === '/dashboard/home' ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D0D5DD',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#344054',
                padding: '8px 12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: 'transparent',
                },
                '&.Mui-disabled': {
                  borderColor: '#E0E0E0',
                  color: '#E0E0E0',
                },
              }}
              onClick={() => {
                nav('/dashboard/users');
              }}
            >
              View All
            </Button>
          ) : (
            <Typography
              fontFamily="Inter"
              lineHeight="20px"
              fontSize="14px"
              color="#344054"
              fontWeight="500"
            >
              Page {page} of {Math.ceil(allUsers?.length / itemsPerPage)}
            </Typography>
          )}
        </Box>
      ) : (
        ''
      )}
      <Modal open={openDeleteConfirmation} onClose={handleCloseDeleteConfirmation}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Are you sure you want to delete?
          </Typography>
          <Box
            mt={2}
            width={'100%'}
            display={'flex'}
            justifyContent="space-between"
            alignItems={'center'}
            gap={'20px'}
          >
            <Button
              onClick={handleCloseDeleteConfirmation}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                textTransform: 'capitalize',
                border: 'solid 1px black',
                color: 'black',
                bgcolor: 'white',
                '&:hover': {
                  bgcolor: 'white',
                  border: 'solid 1px black',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{
                textTransform: 'capitalize',
                border: 'solid 1px red',
                color: 'white',
                bgcolor: 'red',
                '&:hover': {
                  bgcolor: 'red',
                  border: 'solid 1px red',
                },
              }}
              onClick={handleDeleteUser}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RegisteredUsers;
