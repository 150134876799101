import * as React from 'react';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '../assets/beta/close.svg';
import { Typography } from '@mui/material';

interface MessageModalProps {
  singleMessage?: any;
  messageOpen?: any;
  handleMessageClose?: (data: any) => void;
}

const MessageModal: React.FC<MessageModalProps> = ({
  singleMessage,
  messageOpen,
  handleMessageClose,
}) => {
  console.log('singleMessage', singleMessage);
  return (
    <div>
      <Modal
        open={messageOpen}
        onClose={handleMessageClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'var(#0C111D)',
            opacity: 0.7,
            backdropFilter: 'blur(8px)', // Adjust the blur level as needed
          },
        }}
      >
        <Box
          width={'40vw'}
          position={'absolute'}
          top={'50%'}
          left={'50%'}
          bgcolor={'#ffffff'}
          borderRadius={'10px'}
          boxShadow={'24'}
          padding={'24px'}
          sx={{
            transform: 'translate(-50%, -50%)',
            height: 'fit-content',
            // overflowY: 'scroll',
          }}
        >
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
            <img
              onClick={handleMessageClose}
              style={{ width: '24px', height: '24px', cursor: 'pointer' }}
              src={CloseIcon}
              alt="close"
            />
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            margin={'30px 0'}
          >
            <Box width={'47%'} marginBottom={'30px'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
                marginBottom={'10px'}
              >
                First Name
              </Typography>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'24px'}
              >
                {singleMessage?.first_name}
              </Typography>
            </Box>
            <Box width={'47%'} marginBottom={'30px'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
                marginBottom={'10px'}
              >
                Last Name
              </Typography>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'24px'}
              >
                {singleMessage?.last_name}
              </Typography>
            </Box>
            <Box width={'47%'} marginBottom={'30px'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
                marginBottom={'10px'}
              >
                Email
              </Typography>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'24px'}
              >
                {singleMessage?.email}
              </Typography>
            </Box>
            <Box width={'47%'} marginBottom={'30px'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
                marginBottom={'10px'}
              >
                Phone Number
              </Typography>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'24px'}
              >
                {singleMessage?.phone?.length ? singleMessage?.phone : 'Not Provided'}
              </Typography>
            </Box>
            <Box width={'47%'} marginBottom={'30px'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
                marginBottom={'10px'}
              >
                Company
              </Typography>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'24px'}
              >
                {singleMessage?.sender_company}
              </Typography>
            </Box>
            <Box width={'47%'} marginBottom={'30px'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
                marginBottom={'10px'}
              >
                Job Title
              </Typography>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'600'}
                lineHeight={'24px'}
              >
                {singleMessage?.job_title}
              </Typography>
            </Box>
            <Box width={'100%'} marginBottom={'30px'}>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
                marginBottom={'10px'}
              >
                Message
              </Typography>
              <Typography
                color={'#000000'}
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                fontWeight={'500'}
                lineHeight={'24px'}
              >
                {singleMessage?.message}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default MessageModal;
