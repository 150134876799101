import React, { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../components/Header.component';
import AddTask from '../assets/addTask.svg';
import AllRegisteredCompanies from '../components/home/AllRegisteredCompanies.components';
import RegisteredUsers from '../components/home/RegisteredUsers.components';
import request from '../api/request';
import { IStatsResponse } from '../types/user';

const Home: FC = () => {
  const [stats, setStats] = useState<IStatsResponse>();
  const getStats = (): void => {
    request<IStatsResponse>('get', '/companies/dashboard')
      .then((res) => {
        console.log(res);
        setStats(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dashboardData = [
    {
      icon: AddTask,

      amount: stats?.companyCount ? stats?.companyCount : 0,
      category: 'Registered Companies',
    },
    {
      icon: AddTask,
      amount: stats?.companyNeedsAdminApprovalsCount ? stats?.companyNeedsAdminApprovalsCount : 0,
      category: 'Pending Company Requests',
    },
    // {
    //   icon: AddTask,

    //   amount: stats?.reviewCount ? stats?.reviewCount : 0,
    //   category: 'Reviews Received',
    // },
    {
      icon: AddTask,
      amount: stats?.searchCountToday ? stats?.searchCountToday : 0,
      category: 'Total Searches Today.',
    },
  ];

  useEffect(() => {
    getStats();
  }, []);
  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Header />
      <Box width={'74%'} margin={'2% 0'} display={'flex'} gap={'10px'}>
        {dashboardData.map((item, index) => (
          <Box
            key={index}
            width={'100%'}
            border={'2px solid rgba(71, 180, 95, 0.40)'}
            borderRadius={'12px'}
            padding={'2%'}
          >
            <Box width={'42px'} height={'42px'}>
              <img style={{ width: '100%' }} src={item?.icon} alt="Add Task" />
            </Box>
            <Typography
              fontSize={'26px'}
              fontFamily={'Inter'}
              fontWeight={'600'}
              lineHeight={'32px'}
              color={'#454238'}
              margin={'10px 0'}
            >
              {item?.amount}
            </Typography>
            <Typography
              fontSize={'16px'}
              fontFamily={'Inter'}
              fontWeight={'300'}
              lineHeight={'20px'}
              color={'#919B9B'}
              sx={{
                '@media (max-width:1566px)': {
                  fontSize: '14px',
                },
              }}
            >
              {item?.category}
            </Typography>
          </Box>
        ))}
      </Box>
      <RegisteredUsers />
      <AllRegisteredCompanies />
    </Box>
  );
};

export default Home;
