import React, { FC, useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const AccessTOKEN =
  'pk.eyJ1IjoiZmFsY29uaXQiLCJhIjoiY2x4MXI5MW85MGNxbzJoc2Z1enRsanBhZSJ9.d9xvqAxL0P4ToV5p5wsOuA';
mapboxgl.accessToken = AccessTOKEN;

interface editServiceAreaMapProps {
  data?: any;
  setBoundaryCoordinates?: React.Dispatch<React.SetStateAction<any>>;
  setData?: React.Dispatch<React.SetStateAction<any>>;
  radiusInMiles: number; // New prop for radius in miles
}

const MapView: FC<editServiceAreaMapProps> = ({
  data,
  radiusInMiles,
  setData,
  setBoundaryCoordinates,
}) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [markers, setMarkers] = useState<mapboxgl.Marker[]>([]);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);

  // Convert miles to meters (1 mile = 1609.34 meters)
  const radiusInMeters = radiusInMiles * 1609.34;

  useEffect(() => {
    if (mapContainerRef?.current && data?.coordinates?.length) {
      const mapInstance = new mapboxgl.Map({
        container: mapContainerRef?.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        attributionControl: false,
      });

      mapInstance?.on('load', () => {
        const bounds = new mapboxgl.LngLatBounds();

        const { coordinates }: { coordinates: [number, number] } = data || [0, 0];

        const el = document.createElement('div');
        el.innerHTML = `
  <svg width="40" height="60" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path fill="#00BD57"  stroke="white" stroke-width="1"
      d="M12 2C8.13 2 5 5.13 5 9c0 1.85.63 3.53 1.69 4.85L12 22l5.31-8.15C18.37 12.53 19 10.85 19 9c0-3.87-3.13-7-7-7z"/>
    <circle cx="12" cy="9" r="2.5" fill="white" />
  </svg>
`;
        el.style.width = '40px';
        el.style.height = '60px';

        // Add the marker
        const marker = new mapboxgl.Marker({
          element: el,
        })?.setLngLat(coordinates);
        marker?.addTo(mapInstance); // Add the marker to the map
        bounds?.extend(coordinates); // Extend the bounds to include the marker

        mapInstance?.fitBounds(bounds, { padding: 50, maxZoom: 5.15 }); // Fit map to marker
        setMarkers([marker]);
        setMap(mapInstance);

        // Add the circle layer to represent the radius
        mapInstance?.addSource('radius-circle', {
          type: 'geojson',
          data: createCircle(coordinates, radiusInMeters),
        });
        mapInstance?.addLayer({
          id: 'radius-circle-fill',
          type: 'fill',
          source: 'radius-circle', // Your existing source name
          paint: {
            'fill-color': 'rgba(0, 0, 0, 0.14)', // Set fill color
            'fill-opacity': 0.5, // Adjust opacity
          },
        });

        mapInstance?.addLayer({
          id: 'radius-circle-stroke',
          type: 'line',
          source: 'radius-circle', // Your existing source name
          paint: {
            'line-color': '#00BD57', // Set stroke color
            'line-width': 2, // Set stroke width
          },
        });
      });

      // Cleanup function
      return () => {
        if (mapInstance) {
          markers?.forEach((marker) => marker?.remove()); // Remove each marker from the map
          mapInstance?.remove(); // Remove the map instance
        }
      };
    }
  }, [data]);

  // Update the circle when the slider value changes
  useEffect(() => {
    if (map && data?.coordinates?.length) {
      const { coordinates }: { coordinates: [number, number] } = data ?? [0, 0];
      console.log('coordinates', coordinates);
      const source = map.getSource('radius-circle');
      if (source) {
        // Update the circle source with the new radius

        const updatedCircle = createCircle(coordinates, radiusInMeters);
        if (
          updatedCircle &&
          typeof updatedCircle !== 'string' &&
          'geometry' in updatedCircle &&
          updatedCircle.geometry?.type === 'Polygon'
        ) {
          // const polygonGeometry = updatedCircle.geometry as GeoJSON.Polygon;
          const polygonGeometry = updatedCircle.geometry;
          console.log('polygonGeometry', polygonGeometry);
          setBoundaryCoordinates?.([polygonGeometry.coordinates[0]]);
          // Set the new coordinates in the setAccount state
          setData?.((prevAccount: any) => ({
            ...prevAccount,
            serviceArea: [
              {
                location: {
                  type: 'Polygon',
                  coordinates: [polygonGeometry.coordinates[0]], // Set the circle's coordinates
                },
                distance: radiusInMiles, // Set the distance if needed
                // name: 'Polygon',
              },
            ],
          }));

          // Update the source data with the updated circle
          (source as mapboxgl.GeoJSONSource)?.setData(updatedCircle);
        }
      }
    }
  }, [radiusInMiles]);

  // Helper function to create a GeoJSON circle
  const createCircle = (
    center: [number, number],
    radiusInMeters: number,
  ): mapboxgl.GeoJSONSourceRaw['data'] => {
    const points = 64; // Number of points in the circle
    const coords = {
      latitude: center[1],
      longitude: center[0],
    };

    const km = radiusInMeters / 1000;
    const ret = [];
    const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;

    for (let i = 0; i < points; i++) {
      const theta = (i / points) * (2 * Math.PI);
      const x = distanceX * Math.cos(theta);
      const y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]); // Close the circle

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [ret],
      },
      properties: {}, // You can add custom properties here if needed
    };
  };

  return (
    <div ref={mapContainerRef} style={{ width: '100%', height: '100%', borderRadius: '10px' }} />
  );
};

export default MapView;
