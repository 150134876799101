/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  Checkbox,
  Rating,
  TextField,
  styled,
  Avatar,
  CircularProgress,
  // StepConnector,
} from '@mui/material';
import { TReview } from '../../popups/EditReviewModal.popup';

import Avatarimg from '../../assets/Avatarimg.svg';

import ReviewIcon from '../../assets/reviewIcon.svg';
import ReviewSubmittedIcon from '../../assets/reviewSubmittedIcon.svg';
import request from '../../api/request';
import { toast } from 'react-toastify';
import { mediaUrl } from '../../config';
import CustomToast from '../common/CustomToast';

interface ReviewStepperProps {
  review?: TReview;
  setReview: React.Dispatch<React.SetStateAction<TReview>>;
  userData?: any;
  companyData?: any;
  getAllReviews?: () => void;
}
interface CheckboxUserData {
  avatarSrc?: string;
  name: string;
  designation: string;
  companyInfo: string;
  job_title?: string;
}
const imgStyle = {
  width: '100px',
  height: '100px',
};

const mediaQueryStyle = {
  '@media (max-width: 450px)': {
    width: '70px',
    height: '70px',
  },
};

const steps = ['Rating', 'Visibility'];

const CustomRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty': {
    color: '#A8A8A8',
  },
  '& .MuiRating-iconFilled': {
    color: '#00BD57 !important',
  },
}));
// const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
//   '& .MuiStepConnector-line': {
//     left: 'calc(46% + 16px)',
//     right: 'calc(-52.3% + 16px)',
//   },
// }));

const NationalReviewStepper: React.FC<ReviewStepperProps> = ({
  review,
  setReview,
  userData,
  companyData,
  getAllReviews,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedCheckbox, setSelectedCheckbox] = React.useState<number | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  console.log('selectedCheckbox', selectedCheckbox);
  // const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  console.log('userData', userData);
  const handleNext = (): void => {
    // if (isStepComplete()) {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
  };

  const handleSubmit = (): void => {
    setLoading(true);
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('put', `/review/update/${companyData?._id}`, review)
      .then((res: any) => {
        // toast.success(
        //   `${review?.review_type === 'complaint' ? 'Complaint' : 'Review'} Submitted Successfully`,
        // );
        toast(
          <CustomToast
            message={`${
              review?.review_type === 'complaint' ? 'Complaint' : 'Review'
            } Submitted Successfully`}
            type="success"
          />,
        );
        console.log('res searcchh', res);
        handleNext();
        setLoading(false);
        getAllReviews?.();
      })
      .catch((err) => {
        // toast.error("You don't have permission to post this review.");
        toast(
          <CustomToast message={"You don't have permission to post this review."} type="error" />,
        );
        console.log('err', err);
        setLoading(false);
      });
  };

  // const handleChange = (name: string, value: number | string | boolean | null): void => {
  //   setData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  // const handleVisibilityChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   avatar: string,
  // ): void => {
  //   handleChange('is_anonymous', event.target.checked);
  //   handleChange('user.avatar', avatar);
  // };
  const updateRating = (fieldName: string, newValue: number): void => {
    setReview((prevReview) => ({
      ...prevReview,
      [fieldName]: newValue,
    }));
  };
  const handleCheckboxChange = (index: number, data: CheckboxUserData): void => {
    setSelectedCheckbox(index);
    setReview((prevReview) => ({
      ...prevReview,
      user: {
        avatar: data.avatarSrc ?? '',
        name: data.name ?? '',
        designation: data.designation ?? '',
        company: data.companyInfo ?? '',
        email: review?.user?.email ?? '',
        job_title: data?.job_title ?? '',
      },
    }));
  };
  const handleAnonymousToggle = (): void => {
    setReview((prevReview) => ({
      ...prevReview,
      is_anonymous: !prevReview.is_anonymous,
    }));
  };

  const isStepComplete = (): boolean => {
    switch (activeStep) {
      case 0:
        return (
          review?.reliability !== 0 &&
          review?.reliability !== undefined &&
          review?.communication !== 0 &&
          review?.communication !== undefined &&
          review?.work_quality !== 0 &&
          review?.work_quality !== undefined &&
          review?.work_quality !== 0 &&
          review?.work_quality !== undefined &&
          review?.feedback !== ''
        );
      // case 1:
      //   return (
      // review?.reliability > 0 &&
      // review?.communication > 0 &&
      // review?.work_quality > 0 &&
      // review?.lead_time > 0 &&

      //   selectedCheckbox !== null || !!review?.is_anonymous
      // );
      default:
        return true;
    }
  };

  console.log(activeStep, 'activeStep');
  console.log(steps.length, 'activeStep steps.length');

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      marginTop="2%"
    >
      {activeStep !== steps.length && (
        <React.Fragment>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            gap={'10px'}
            marginTop={'4%'}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <img
                src={ReviewIcon}
                alt="Verification Logo"
                style={{ ...imgStyle, ...mediaQueryStyle['@media (max-width: 450px)'] }}
              />
            </Box>
            <Box width="100%" display="flex" justifyContent="center" alignItems="center">
              <Typography
                fontSize={'24px'}
                fontWeight={'800'}
                fontFamily={'SF UI Display'}
                color={'#000000'}
                lineHeight={'28px'}
                id="modal-modal-title"
                sx={{
                  '@media (max-width: 450px)': {
                    fontSize: '20px',
                  },
                }}
              >
                Review {companyData?.companyInfo?.name}
              </Typography>
            </Box>
          </Box>
          <Stepper
            // connector={<CustomStepConnector />}
            sx={{
              width: '30%',

              marginTop: '5%',
              alignItems: 'flex-start',
              '& .MuiStepConnector-root': {
                marginTop: '10%',
              },
              // '& .css-z7uhs0-MuiStepConnector-line': {
              //   right: 'calc(46% + 16px)',
              //   left: 'calc(-52.3% + 16px)',
              // },
              '@media (max-width: 650px)': {
                width: '70%',
              },
            }}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              if (index < activeStep) {
                stepProps.completed = true;
              }

              const stepNumberStyles = {
                color: stepProps.completed
                  ? '#00BD57'
                  : index === activeStep
                  ? '#00BD57'
                  : '#A8A8A8',
                fontSize: '40px',
                '@media (max-width: 650px)': {
                  fontSize: '30px',
                },
              };

              return (
                <Step
                  key={label}
                  {...stepProps}
                  // sx={{
                  //   paddingLeft: '2px',
                  //   paddingRight: '0',
                  // }}
                >
                  <StepLabel
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px',
                      fontWeight: '600',
                      fontFamily: 'SF UI Display',
                      color: '#000000',
                      '& .MuiStepLabel-label': {
                        fontSize: '16px',
                        fontFamily: 'SF UI Display ',
                        fontWeight: '600',
                        color: stepProps.completed
                          ? '#000000'
                          : index === activeStep
                          ? '#000000'
                          : '#A8A8A8',
                      },
                      '@media (max-width: 650px)': {
                        fontSize: '10px',
                        '& .MuiStepLabel-label': {
                          fontSize: '10px',
                        },
                      },
                    }}
                    {...labelProps}
                    StepIconProps={{ style: stepNumberStyles }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </React.Fragment>
      )}

      {activeStep === steps.length ? (
        <React.Fragment>
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <img
              src={ReviewSubmittedIcon}
              alt="Verification Logo"
              style={{ width: '100px', height: '100px' }}
            />
          </Box>
          <Box width="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography
              fontSize={'24px'}
              fontWeight={'800'}
              fontFamily={'SF UI Display'}
              color={'#000000'}
              lineHeight={'28px'}
              id="modal-modal-title"
              sx={{
                '@media (max-width: 450px)': {
                  fontSize: '15px',
                },
              }}
            >
              {`The ${
                review?.review_type === 'complaint' ? 'complaint' : 'review'
              } has been updated!`}
            </Typography>
          </Box>
          {/* <Typography
            sx={{
              mt: 2,
              mb: 1,
              textAlign: 'center',
              fontFamily: 'SF UI Display',
              fontSize: '16px',
              '@media (max-width: 450px)': {
                fontSize: '14px',
              },
            }}
          >
            {' '}
            Thank you for taking the time to share your experience with us. All reviews are screened
            and verified before posting. If your review does not meet our guidelines for acceptance
            we will reach out by email.
          </Typography> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === steps.length - 2 && (
            <Box
              width={'100%'}
              height={'250px'}
              marginBottom={'4%'}
              marginTop={'4%'}
              display={'flex'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              sx={{
                overflowY: 'scroll',
                '@media (max-width: 650px)': {
                  flexDirection: 'column',
                  flexWrap: 'unset',
                },
              }}
            >
              <Box width={'48%'} padding={'2% 1%'}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {companyData?.companyInfo?.companyType === 'national'
                    ? 'Professionalism'
                    : 'Quality'}
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="reliability"
                  value={review?.reliability}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('reliability', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>
              <Box width={'48%'} padding={'2% 1%'}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {companyData?.companyInfo?.companyType === 'national'
                    ? 'Communication'
                    : 'Customer Service'}
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="communication"
                  value={review?.communication}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('communication', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>
              <Box width={'48%'} padding={'2% 1%'}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {companyData?.companyInfo?.companyType === 'national' ? 'Compensation' : 'Cost'}
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="workQuality"
                  value={review?.work_quality}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('work_quality', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>
              <Box width={'48%'} padding={'2% 1%'}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  {companyData?.companyInfo?.companyType === 'national'
                    ? 'On-Time Payments'
                    : 'Recommendations'}
                </Typography>
                <CustomRating
                  sx={{ margin: '3% 0', fontSize: '3rem' }}
                  name="leadTime"
                  value={review?.lead_time}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      updateRating('lead_time', newValue);
                    }
                  }}
                  precision={0.5}
                />
              </Box>

              <Box width={'100%'} padding={'2% 1%'}>
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '12px',
                    },
                  }}
                >
                  Feedback
                </Typography>
                <TextField
                  sx={{ marginTop: '2%' }}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  placeholder="Enter your feedback here"
                  value={review?.feedback}
                  onChange={(event) => {
                    setReview((prevReview) => ({
                      ...prevReview,
                      feedback: event.target.value,
                    }));
                  }}
                />
              </Box>
            </Box>
          )}
          {activeStep === steps.length - 1 && (
            <Box
              width={'100%'}
              marginTop={'4%'}
              display={'flex'}
              justifyContent={'center'}
              flexWrap={'wrap'}
            >
              <Box
                width={'48%'}
                padding={'2% 1%'}
                margin={'2% 0'}
                display={'flex'}
                flexDirection={'column'}
                gap={'20px'}
                sx={{
                  '@media (max-width: 650px)': {
                    width: '90%',
                  },
                }}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'700'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19px'}
                  color={'#000000'}
                  textAlign={'center'}
                  whiteSpace={'nowrap'}
                  sx={{
                    '@media (max-width: 450px)': {
                      fontSize: '10px',
                    },
                  }}
                >
                  {review?.user
                    ? `The ${
                        review?.review_type === 'complaint' ? 'complaint' : 'review'
                      } was submitted as`
                    : 'Most Anonymously.'}{' '}
                </Typography>
                {review?.user ? (
                  <Box>
                    <Box display="flex" alignItems="center" marginTop="8px" gap={'16px'}>
                      <Checkbox
                        // checked={selectedCheckbox === 1}
                        checked={true}
                        disabled={true}
                        style={{ color: '#00BD57' }}
                        onChange={() => {
                          handleCheckboxChange(1, {
                            avatarSrc: review?.user?.avatar ?? '',
                            name: review?.user?.name ?? '',
                            designation: review?.user?.designation ?? '',
                            job_title: review?.user?.job_title ?? '',
                            companyInfo: review?.user?.company ?? '',
                          });
                        }}
                      />
                      <Avatar
                        /* eslint-disable @typescript-eslint/restrict-template-expressions */
                        src={
                          review?.user?.avatar ? `${mediaUrl}/${review?.user?.avatar}` : Avatarimg
                        }
                      />
                      <Box>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'600'}
                          color={'#000000'}
                          fontFamily={'SF UI Display'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {review?.user?.name
                            ? review?.user?.name
                            : review?.user?.job_title ?? 'N/A'}
                        </Typography>
                        <Typography
                          fontSize={'14px'}
                          fontWeight={'600'}
                          color={'#606060'}
                          fontFamily={'SF UI Display'}
                        >
                          {review?.user?.company ?? 'N/A'}
                        </Typography>
                      </Box>
                    </Box>

                    {/* <Box display="flex" alignItems="center" marginTop="8px" gap={'16px'}>
                      <Checkbox
                        checked={selectedCheckbox === 2}
                        onChange={() => {
                          handleCheckboxChange(2, {
                            avatarSrc: '',
                            name: '',
                            designation: '',
                            job_title: userData?.job_title || '',
                            
                            companyInfo: userData?.company?.companyInfo?.name || '',
                          });
                        }}
                        style={{ color: '#00BD57' }}
                      />
                      <Avatar src={Avatarimg} />
                      <Box>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'600'}
                          color={'#000000'}
                          fontFamily={'SF UI Display'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.job_title || 'N/A'}
                        </Typography>
                        <Typography
                          fontSize={'14px'}
                          fontWeight={'600'}
                          color={'#606060'}
                          fontFamily={'SF UI Display'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.company?.companyInfo?.name}
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" alignItems="center" marginTop="8px" gap={'16px'}>
                      <Checkbox
                        checked={selectedCheckbox === 3}
                        onChange={() => {
                          handleCheckboxChange(3, {
                            avatarSrc: '',
                            name: '',
                            designation: '',
                            job_title: userData?.job_title || '',
                            companyInfo: userData?.company?.companyInfo?.companyType || '',
                          });
                        }}
                        style={{ color: '#00BD57' }}
                      />
                      <Avatar src={Avatarimg} />
                      <Box>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={'600'}
                          color={'#000000'}
                          fontFamily={'SF UI Display'}
                          textTransform={'capitalize'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.job_title || 'N/A'}
                        </Typography>
                        <Typography
                          fontSize={'14px'}
                          fontWeight={'600'}
                          color={'#606060'}
                          fontFamily={'SF UI Display'}
                          textTransform={'capitalize'}
                          sx={{
                            '@media (max-width: 450px)': {
                              fontSize: '10px',
                            },
                          }}
                        >
                          {userData?.company?.companyInfo?.companyType} Sign Company
                        </Typography>
                      </Box>
                    </Box> */}
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" marginTop="8px" gap={'16px'}>
                    <Checkbox
                      checked={review?.is_anonymous}
                      onChange={handleAnonymousToggle}
                      disabled={true}
                      style={{ color: '#00BD57' }}
                    />
                    <Avatar src={Avatarimg} />
                    <Box>
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'600'}
                        color={'#000000'}
                        fontFamily={'SF UI Display'}
                        sx={{
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                          },
                        }}
                      >
                        Anonymous
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          <Box width="100%" display="flex" pt="2" justifyContent="right">
            <Button
              sx={{
                bgcolor: !isStepComplete() ? '#DCDEDF' : '#00BD57',
                color: '#ffffff',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '19px',
                fontFamily: 'SF UI Display',
                width: '86px',
                padding: '1.5% 0',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: !isStepComplete() ? '#DCDEDF' : '#00BD57',
                },
                '@media (max-width:650px)': {
                  fontSize: '12px',
                  width: '10%',
                },
              }}
              disabled={!isStepComplete() || loading}
              onClick={activeStep === steps.length - 1 ? handleSubmit : handleNext}
            >
              {loading ? (
                <CircularProgress size={15} color="inherit" />
              ) : activeStep === steps.length - 1 ? (
                'Submit'
              ) : (
                'Next'
              )}{' '}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default NationalReviewStepper;
