import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Success from '../../assets/common/toastSuccess.svg';
import Error from '../../assets/common/toastError.svg';
import Close from '../../assets/common/toastClose.svg';

// Custom Toast Component
const CustomToast = (props: any): any => {
  const { message, type } = props;
  return (
    <div style={toastContainerStyle}>
      <div style={iconContainerStyle}>
        <img src={type === 'error' ? Error : Success} alt="Icon" />
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style={checkIconStyle}
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
        </svg> */}
      </div>
      <div style={messageContainerStyle}>{message}</div>
      <img
        src={Close}
        style={closeButtonStyle}
        onClick={() => {
          toast.dismiss();
        }}
      />
    </div>
  );
};

// Styling for the toast component
const toastContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  backgroundColor: '#fff',
  width: '100%',
};

const iconContainerStyle = {
  padding: '5px',
  marginRight: '10px',
};

const messageContainerStyle = {
  flex: 1,
  fontSize: '16px',
  fontWeight: '700',
  color: '#000',
  fontFamily: 'SF UI Display',
  fonStyle: 'normal',
};

const closeButtonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '18px',
  fontWeight: 'bold',
  cursor: 'pointer',
  color: '#000',
};

export default CustomToast;
