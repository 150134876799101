import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, styled } from '@mui/material';

// import ReviewStepper from '../../../components/phase1a/local/ReviewStepper.component';

import CloseIcon from '../assets/CloseIcon.svg';
import ReviewIcon from '../assets/reviewIcon.svg';
import ThumbsUp from '../assets/thumbsupIcon.svg';
import Complaint from '../assets/Vector.svg';
import CheckCircleIcon from '../assets/checkedfilled.svg';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
import ReviewStepper from '../components/review/ReviewStepper.component';
import NationalReviewStepper from '../components/review/ReviewNationalStepper.component';

interface ReviewModalProps {
  open: boolean;
  onClose: () => void;
  companyData?: any;
  getAllReviews: () => void;
}
const imgStyle = {
  width: '100px',
  height: '100px',
};

const mediaQueryStyle = {
  '@media (max-width: 450px)': {
    width: '70px',
    height: '70px',
  },
};
const StyledCheckIcon = styled('img')({
  position: 'absolute',
  top: '8px',
  right: '10px',
  width: '31px',
  height: '31px',
  '@media (max-width: 450px)': {
    width: '20px',
    height: '20px',
    top: '5px',
  },
});
const StyledThumbsUpIcon = styled('img')({
  width: '55px',
  height: '55px',
  '@media (max-width: 450px)': {
    width: '35px',
    height: '35px',
  },
});
const StyledIcon = styled('img')({
  width: '55px',
  height: '55px',
  '@media (max-width: 450px)': {
    width: '35px',
    height: '35px',
  },
});
type BoxType = 'recommendation' | 'complaint';

export interface Review {
  //   company?: string;
  review_type?: string;
  services?: string[];
  job_start_date?: string; // ISO 8601 date string
  job_completion_date?: string; // ISO 8601 date string
  job_due_date?: string; // ISO 8601 date string
  budget_adherence?: string;
  reliability?: number | undefined;
  communication?: number | undefined;
  work_quality?: number | undefined;
  lead_time?: number | undefined;
  feedback?: string;
  is_anonymous?: boolean;
  user: {
    avatar?: string;
    name?: string;
    designation?: string;
    company?: string;
    email?: string;
    job_title?: string;
  };
}
export interface TReview {
  // company?: string;
  review_type?: string;
  reliability?: number | undefined;
  communication?: number | undefined;
  work_quality?: number | undefined;
  lead_time?: number | undefined;
  feedback?: string;
  is_anonymous?: boolean;
  user: {
    avatar?: string;
    name?: string;
    designation?: string;
    company?: string;
    email?: string;
    job_title?: string;
  };
}
const EditReviewModal: React.FC<ReviewModalProps> = ({
  open,
  onClose,
  companyData,
  getAllReviews,
}) => {
  const userJSON = localStorage.getItem('user');
  const userData = userJSON ? JSON.parse(userJSON) : '';
  const initialLocalReviewState: Review = {
    // company: companyData?._id,
    review_type: '',
    services: [],
    job_start_date: '',
    job_completion_date: '',
    job_due_date: '',
    budget_adherence: '',
    reliability: 0,
    communication: 0,
    work_quality: 0,
    lead_time: 0,
    feedback: '',
    is_anonymous: !userData,
    user: {
      avatar: '',
      name: '',
      designation: '',
      company: '',
      email: '',
      job_title: '',
    },
  };
  const initialReviewState: TReview = {
    // company: companyData?._id,
    review_type: '',
    reliability: 0,
    communication: 0,
    work_quality: 0,
    lead_time: 0,
    feedback: '',
    is_anonymous: !userData,
    user: {
      avatar: '',
      name: '',
      designation: '',
      company: '',
      email: '',
      job_title: '',
    },
  };
  console.log('userData', userData);
  const [review, setReview] = React.useState<Review>(initialLocalReviewState);
  const [tReview, setTReview] = React.useState<TReview>(initialReviewState);
  console.log('companyData 1', companyData);
  console.log(review, 'revieew', tReview);
  const [selectedBox, setSelectedBox] = React.useState<BoxType>(companyData?.review_type);
  console.log('selectedBox', selectedBox);
  const [isNextClicked, setIsNextClicked] = React.useState(false);
  console.log('review', review);
  const handleClose = (): void => {
    setIsNextClicked(false);
    setReview(initialLocalReviewState);
    onClose();
  };

  const handleBoxClick = (box: BoxType): void => {
    setSelectedBox(box);
    setReview((prevUser) => ({
      ...prevUser,
      review_type: box,
    }));
    setTReview((prevUser) => ({
      ...prevUser,
      review_type: box,
    }));
  };

  const handleNextClick = (): void => {
    setIsNextClicked(true);
  };

  React.useEffect(() => {
    if (companyData?.company?.companyInfo?.companyType === 'local') {
      setReview((prevReview) => ({
        ...prevReview,
        //   company: companyData,
        review_type: companyData?.review_type,
        services: companyData?.services,
        job_start_date: companyData?.job_start_date,
        job_completion_date: companyData?.job_completion_date,
        job_due_date: companyData?.job_due_date,
        budget_adherence: companyData?.budget_adherence,
        reliability: companyData?.reliability,
        communication: companyData?.communication,
        work_quality: companyData?.work_quality,
        lead_time: companyData?.lead_time,
        feedback: companyData?.feedback,
        is_anonymous: !userData,
        user: {
          avatar: companyData?.user?.avatar,
          name: companyData?.user?.name,
          designation: companyData?.user?.designation,
          company: companyData?.user?.company,
          email: companyData?.user?.email,
          job_title: companyData?.user?.job_title,
        },
      }));
    }
    setSelectedBox(companyData?.review_type);

    if (companyData?.company?.companyInfo?.companyType !== 'local') {
      setTReview((prevReview) => ({
        ...prevReview,
        //   company: companyData,
        review_type: companyData?.review_type,
        reliability: companyData?.reliability,
        communication: companyData?.communication,
        work_quality: companyData?.work_quality,
        lead_time: companyData?.lead_time,
        feedback: companyData?.feedback,
        is_anonymous: !userData,
        user: {
          avatar: companyData?.user?.avatar,
          name: companyData?.user?.name,
          designation: companyData?.user?.designation,
          company: companyData?.user?.company,
          email: companyData?.user?.email,
          job_title: companyData?.user?.job_title,
        },
      }));
    }
  }, [companyData?._id]);
  React.useEffect(() => {
    setReview((prevReview) => ({
      ...prevReview,
      user: {
        ...prevReview?.user,
        email: userData?.email,
      },
    }));
  }, [userData?.email]);

  return (
    <Box>
      {/* <Button
        sx={{
          color: '#475467',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
          fontFamily: 'Inter',
          cursor: 'pointer',
          textTransform: 'capitalize',
        }}
        onClick={handleOpen}
      >
        {' '}
        <BorderColorIcon />
      </Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={{
        //   background: 'transparent',
        //   '& .MuiBackdrop-root': {
        //     backgroundColor: 'rgba(0, 0, 0, 0.5)',
        //   },
        // }}
      >
        <Box
          width={'50%'}
          maxHeight={'90vh'}
          position={'absolute'}
          top={'50%'}
          left={'50%'}
          bgcolor={'#ffffff'}
          borderRadius={'10px'}
          boxShadow={'24px'}
          padding={'40px'}
          sx={{
            transform: 'translate(-50%, -50%)',
            '@media (max-width: 780px)': {
              width: '580px',
              padding: '20px',
            },
            '@media (max-width: 650px)': {
              width: '400px',
            },
            '@media (max-width: 450px)': {
              width: '380px',
            },
            '@media (max-width: 420px)': {
              width: '320px',
            },
          }}
        >
          <>
            <img
              src={CloseIcon}
              alt="Close"
              onClick={handleClose}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                right: '3%',
                top: '3%',
                zIndex: 1,
                marginTop: '1.5%',
              }}
            />

            {!isNextClicked ? (
              <React.Fragment>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'10px'}
                  marginTop={'4%'}
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                  >
                    <img
                      src={ReviewIcon}
                      alt="Verification Logo"
                      style={{ ...imgStyle, ...mediaQueryStyle['@media (max-width: 450px)'] }}
                    />
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                    <Typography
                      fontSize={'24px'}
                      fontWeight={'800'}
                      fontFamily={'SF UI Display'}
                      color={'#000000'}
                      lineHeight={'28px'}
                      id="modal-modal-title"
                      sx={{
                        '@media (max-width: 450px)': {
                          fontSize: '20px',
                        },
                      }}
                    >
                      Review {companyData?.companyInfo?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    gap={'5%'}
                    margin={'10% 0'}
                  >
                    <Box
                      width={'178px'}
                      height={'123px'}
                      padding={'5% 0'}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={'20px'}
                      border={'1px solid #E8E8E8'}
                      borderRadius={'10px'}
                      position={'relative'}
                      sx={{
                        '@media (max-width: 650px)': {
                          width: '45%',
                        },
                      }}
                      onClick={(): void => {
                        handleBoxClick('recommendation');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {selectedBox === 'recommendation' && (
                        <StyledCheckIcon src={CheckCircleIcon} alt="Check" />
                      )}
                      <StyledThumbsUpIcon src={ThumbsUp} alt="ThumbsUp" />{' '}
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'700'}
                        fontFamily={'SFProDisplay'}
                        color={'#000000'}
                        lineHeight={'19px'}
                        sx={{
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                          },
                        }}
                      >
                        Recommendation
                      </Typography>
                    </Box>
                    <Box
                      width={'178px'}
                      height={'123px'}
                      padding={'5% 0'}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'center'}
                      gap={'20px'}
                      border={'1px solid #E8E8E8'}
                      borderRadius={'10px'}
                      position={'relative'}
                      sx={{
                        '@media (max-width: 650px)': {
                          width: '45%',
                        },
                      }}
                      onClick={(): void => {
                        handleBoxClick('complaint');
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {selectedBox === 'complaint' && (
                        <StyledCheckIcon src={CheckCircleIcon} alt="Check" />
                      )}
                      <StyledIcon src={Complaint} alt="Complaint" />{' '}
                      <Typography
                        fontSize={'16px'}
                        fontWeight={'700'}
                        fontFamily={'SFProDisplay'}
                        color={'#000000'}
                        lineHeight={'19px'}
                        sx={{
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                          },
                        }}
                      >
                        Complaint
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'right'}
                    alignItems={'center'}
                  >
                    {selectedBox && (
                      <Button
                        sx={{
                          width: '86px',
                          height: '41px',
                          bgcolor: !selectedBox ? '#DCDEDF' : '#00BD57',
                          fontSize: '16px',
                          fontWeight: '700',
                          fontFamily: 'SFProDisplay',
                          lineHeight: '19px',
                          color: '#FFFFFF',
                          textTransform: 'capitalize',
                          padding: '2% 0',
                          '&:hover': {
                            bgcolor: '#00BD57',
                          },
                          '@media (max-width: 450px)': {
                            fontSize: '10px',
                            width: '10%',
                          },
                        }}
                        onClick={handleNextClick}
                      >
                        Next
                      </Button>
                    )}
                  </Box>
                </Box>
              </React.Fragment>
            ) : companyData?.company?.companyInfo?.companyType !== 'local' ? (
              <>
                <NationalReviewStepper
                  review={tReview}
                  setReview={setTReview}
                  companyData={companyData}
                  getAllReviews={getAllReviews}
                />
              </>
            ) : (
              <>
                <ReviewStepper
                  review={review}
                  setReview={setReview}
                  companyData={companyData}
                  getAllReviews={getAllReviews}
                />
              </>
            )}
          </>
        </Box>
      </Modal>
    </Box>
  );
};

export default EditReviewModal;
