import React, { FC, useState, useEffect } from 'react';
import { Avatar, Box, Divider, Popover, Typography } from '@mui/material';
import Logo from '../assets/logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { mediaUrl } from '../config';
// import Bell from '../assets/bellicon.svg';

interface HeaderProps {
  hideLinks?: boolean;
}

const Header: FC<HeaderProps> = ({ hideLinks = false }) => {
  const nav = useNavigate();
  const adminJSON = localStorage.getItem('admin');
  const adminData = adminJSON ? JSON.parse(adminJSON) : '';
  const pathname = window.location.pathname;
  const location = useLocation();
  const [profilePopupAnchor, setProfilePopupAnchor] = useState<HTMLDivElement | null>(null);

  const [showNavButtons, setShowNavButtons] = useState(true);

  useEffect(() => {
    if (location.pathname === '/addcompany') {
      setShowNavButtons(false);
    } else {
      setShowNavButtons(true);
    }
  }, [location.pathname]);

  const handleAvatarClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setProfilePopupAnchor(profilePopupAnchor ? null : event.currentTarget);
  };

  const handleSignout = (): void => {
    localStorage.removeItem('token');
    localStorage.removeItem('admin');
    window.location.reload();
  };

  return (
    <Box
      width={'100%'}
      height={'72px'}
      padding={'0 13%'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderBottom={'1px solid var(--Gray-200, #EAECF0)'}
      bgcolor={'#FFFFFF'}
    >
      <Box display={'flex'} alignItems={'center'} gap={'50px'}>
        <Box width={'125px'}>
          <Link to="/dashboard/home">
            <img style={{ width: '100%' }} src={Logo} alt="Logo" />
          </Link>
        </Box>
        {!hideLinks && showNavButtons && (
          <Box display={'flex'} gap={'5px'}>
            <Link to="/dashboard/home">
              <Typography
                fontSize={'16px'}
                fontFamily={'Inter'}
                fontWeight={'600'}
                lineHeight={'24px'}
                borderRadius={'6px'}
                bgcolor={pathname === '/dashboard/home' ? 'var(--Gray-50, #F9FAFB)' : '#FFFFFF'}
                padding={'8px 12px'}
                whiteSpace={'pre'}
              >
                Dashboard
              </Typography>
            </Link>
            <Link to="/dashboard/request">
              <Typography
                fontSize={'16px'}
                fontFamily={'Inter'}
                fontWeight={'600'}
                lineHeight={'24px'}
                borderRadius={'6px'}
                bgcolor={pathname === '/dashboard/request' ? '#F9FAFB' : '#FFFFFF'}
                padding={'8px 12px'}
                whiteSpace={'pre'}
              >
                Company Requests
              </Typography>
            </Link>
            <Link to="/dashboard/claimcompany">
              <Typography
                fontSize={'16px'}
                fontFamily={'Inter'}
                fontWeight={'600'}
                lineHeight={'24px'}
                borderRadius={'6px'}
                bgcolor={pathname === '/dashboard/claimcompany' ? '#F9FAFB' : '#FFFFFF'}
                padding={'8px 12px'}
                whiteSpace={'pre'}
              >
                Claim Requests
              </Typography>
            </Link>
            <Link to="/dashboard/messages">
              <Typography
                fontSize={'16px'}
                fontFamily={'Inter'}
                fontWeight={'600'}
                lineHeight={'24px'}
                borderRadius={'6px'}
                bgcolor={pathname === '/dashboard/messages' ? '#F9FAFB' : '#FFFFFF'}
                padding={'8px 12px'}
                whiteSpace={'pre'}
              >
                {/* Company Reviews */}
                Company Messages
              </Typography>
            </Link>
            <Link to="/dashboard/topsearch">
              <Typography
                fontSize={'16px'}
                fontFamily={'Inter'}
                fontWeight={'600'}
                lineHeight={'24px'}
                borderRadius={'6px'}
                bgcolor={pathname === '/dashboard/topsearch' ? '#F9FAFB' : '#FFFFFF'}
                padding={'8px 12px'}
                whiteSpace={'pre'}
              >
                Search Stats
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
      <Box display={'flex'} alignItems={'center'} gap={'30px'} justifyContent={'right'}>
        {/* <Box>
          <img src={Bell} alt="notification" />
        </Box> */}
        <Box onClick={handleAvatarClick} sx={{ cursor: 'pointer' }}>
          <Avatar
            alt="Admin Profile"
            /* eslint-disable @typescript-eslint/restrict-template-expressions */
            src={adminData?.photo ? `${mediaUrl}/${adminData?.photo}` : ''}
          />
        </Box>
        {profilePopupAnchor && (
          <Popover
            open={Boolean(profilePopupAnchor)}
            anchorEl={profilePopupAnchor}
            onClose={() => {
              setProfilePopupAnchor(null);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '10px',
                border: 'solid 1px #EAECF0',
              },
            }}
          >
            <Box
              width="263px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              borderRadius="10px"
            >
              <Box
                width="100%"
                p="0 4%"
                m="4% 0"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box width="20%">
                  <Avatar
                    alt={adminData?.name}
                    /* eslint-disable @typescript-eslint/restrict-template-expressions */
                    src={adminData?.photo ? `${mediaUrl}/${adminData?.photo}` : ''}
                  />
                </Box>
                <Box width="80%">
                  <Typography
                    fontFamily="SF UI Display"
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="20px"
                    color="#344054"
                  >
                    {adminData?.name}
                  </Typography>
                  <Typography
                    fontFamily="SF UI Display"
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="20px"
                    color="#475467"
                    textTransform={'capitalize'}
                  >
                    {adminData?.role}

                    {/* D’s Signs & Lighting LLC */}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
              <Box width="100%" p="0 4%" m="4% 0" display="flex" alignItems="center" gap="5px">
                <Box
                  width={'100%'}
                  onClick={() => {
                    nav('/resetpassword');
                  }}
                >
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="14px"
                    sx={{ cursor: 'pointer' }}
                  >
                    Reset Password
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%' }} />
              <Box width="100%" p="0 4%" m="4% 0" display="flex" alignItems="center" gap="5px">
                <Box width={'100%'} onClick={handleSignout}>
                  <Typography
                    fontFamily="SF UI Display"
                    fontWeight="500"
                    lineHeight="20px"
                    fontSize="14px"
                    sx={{ cursor: 'pointer' }}
                  >
                    Sign Out
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Popover>
        )}
      </Box>
    </Box>
  );
};

export default Header;
