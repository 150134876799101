import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  CircularProgress,
  Slider,
  ListItemIcon,
  MenuItem,
  Select,
} from '@mui/material';
import CompanyInfo from '../../assets/homeimg.svg';
import UpoadImg from '../../assets/uploadIcon.svg';
import DefaultAvatar from '../../assets/avatar.svg';
import ServiceIcon from '../../assets/serviceimg.svg';
// import RightArrow from '../../assets/rightArrow.svg';
import ServiceArea from '../../assets/serviceAreaIcon.svg';
import request from '../../api/request';
import { toast } from 'react-toastify';
import { mediaUrl } from '../../config';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../assets/closeFilledIcon.svg';
import { IAddress, IAddressResponse } from '../../types/user';
import serviceFilter1 from '../../assets/serviceFilter/serviceFilter1.svg';
import serviceFilter2 from '../../assets/serviceFilter/serviceFilter2.svg';
import serviceFilter3 from '../../assets/serviceFilter/serviceFilter3.svg';
import serviceFilter4 from '../../assets/serviceFilter/serviceFilter4.svg';
import serviceFilter5 from '../../assets/serviceFilter/serviceFilter5.svg';
import printProduction from '../../assets/serviceFilter/serviceFilter6.svg';
import PrintInstallation from '../../assets/serviceFilter/serviceFilter7.svg';
import WrapInstallation from '../../assets/serviceFilter/serviceFilter8.svg';
import CustomToast from '../../components/common/CustomToast';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MapAreaCoverage from './MapAreaCoverage';

const options = [
  // {
  //   value: 'Full Service Companies',
  //   label: 'Full Service',
  //   color: '#FF5722',
  //   logo: 'fullserviceicon',
  //   image: serviceFilter1,
  // },
  {
    value: 'Sign Fabrication',
    label: 'Sign Fabrication',
    color: '#E91E63',
    logo: 'fabricatorsicon',
    image: serviceFilter2,
  },
  {
    label: 'Print Production',
    value: 'Print Production',
    color: '#E91E63',
    logo: 'productionicon',
    image: printProduction,
  },
  {
    value: 'Sign Installation',
    label: 'Sign Installation',
    color: '#2196F3',
    logo: 'installerIcon',
    image: serviceFilter3,
  },
  {
    value: 'Service',
    label: 'Service',
    color: '#2196F3',
    logo: 'serviceIcon',
    image: serviceFilter4,
  },
  {
    value: 'Surveys',
    label: 'Surveys',
    color: '#2196F3',
    logo: 'surveyIcon',
    image: serviceFilter5,
  },
  {
    label: 'Permits',
    value: 'Permits',
    color: '#2196F3',
    logo: 'permitIcon',
    image: serviceFilter1,
  },
  {
    label: 'Print Installation',
    value: 'Print Installation',
    color: '#2196F3',
    logo: 'printInstallationIcon',
    image: PrintInstallation,
  },
  {
    label: 'Wrap Installation',
    value: 'Wrap Installation',
    color: '#2196F3',
    logo: 'wrapInstallationIcon',
    image: WrapInstallation,
  },
];

const PrettoSlider = styled(Slider)({
  marginTop: '8px',
  color: '#52af77',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    boxShadow: '0px 1px 4px 0px rgba(148, 148, 148, 0.25)',
    background: '#fff',
    color: '#000',
    padding: '3px 15px',
    border: '1px solid #D0D5DD',
    height: 32,
    borderRadius: '8px',
  },
});

// const serviceAreas = [{ id: 'service-states', label: 'Service States' }];

const initialState = {
  companyInfo: {
    name: '',
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
      address: '',
      country: '',
    },
    formation_year: '',
    licence: '',
    company_size: '',
    union_shop: '',
    ul_list: '',
    available_for_work: '',
    responce_time: '',
    description: '',
    phone: '',
    formattedNo: '',

    email: '',
    domain: '',
    website: '',

    location: {
      type: 'Point',
      coordinates: [40, 6],
    },
    logo: '',
    companyType: 'local',
  },
  services: [
    {
      service: '',
      percentage: null,
      subService: [''],
      logo: '',
    },
  ],
  serviceArea: [
    {
      location: {
        type: 'Polygon',
        coordinates: [[]],
      },
      distance: null, // in miles
    },
  ],
  serviceAreaDescription: '',
  profileCompleted: true,
};

export interface IAddlocalCompany {
  user: any;
  domain?: any;
  company?: any;
}
const AddLocalCompany: FC<IAddlocalCompany> = ({ user, domain, company }) => {
  const [allAddresses, setAllAddresses] = useState<IAddress[]>([]);
  const selectRef = useRef<string | null>(null); // Ref for the currently open dropdown
  const [name, setName] = useState('');
  const [data, setData] = useState(initialState);
  const [openDrop, setOpenDrop] = useState(false);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [radiusInMiles, setRadiusInMiles] = useState(data?.serviceArea[0]?.distance ?? 50);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDomainUpdated, setIsDomainUpdated] = useState(false);

  console.log('final datadatadata', data);

  interface CustomDropdownIconProps {
    dropdownRef: string;
  }

  const handleIconClick = (dropdownRef: any): any => {
    if (selectRef.current === dropdownRef) {
      setOpenDrop((prev) => !prev); // Toggle if the same dropdown is clicked
    } else {
      selectRef.current = dropdownRef; // Update to new dropdown
      setOpenDrop(true); // Open the new dropdown
    }
  };

  const CustomDropdownIcon: React.FC<CustomDropdownIconProps> = ({ dropdownRef }) => (
    <div
      onClick={(e) => {
        e.stopPropagation(); // Prevent Select from toggling on container click
        handleIconClick(dropdownRef);
      }}
      style={{
        marginRight: '14px',
        cursor: 'pointer',
      }}
    >
      {openDrop && selectRef.current === dropdownRef ? <ExpandLess /> : <ExpandMore />}
    </div>
  );

  const handleGetAddress = (value: string): void => {
    setName(value);
    request<IAddressResponse>('get', `/address?text=${value}`)
      .then((response) => {
        console.log(response);
        setAllAddresses(response.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddressChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: IAddress,
  ): void => {
    if (value) {
      setData({
        ...data,
        companyInfo: {
          ...data.companyInfo,
          address: {
            street: value.contexts.place,
            city: value.contexts.district,
            state: value.contexts.region,
            zip: value.contexts.postcode,
            address: value.place_name,
            country: value.contexts.country,
          },
          location: value.geometry,
        },
      });
    }
  };

  const formatPhoneNumber = (phoneNumber: string): string => {
    const cleaned = phoneNumber.replace(/\D/g, '');

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      let formattedNumber = '';
      if (match[1]) {
        formattedNumber += `(${match[1]}`;
      }
      if (match[2]) {
        formattedNumber += `) ${match[2]}`;
      }
      if (match[3]) {
        formattedNumber += `-${match[3]}`;
      }
      return formattedNumber;
    }

    // Return as-is if format doesn't match
    return phoneNumber;
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const formattedValue = formatPhoneNumber(value);
    setFormattedPhoneNumber(formattedValue);
    handleChange(event);
  };

  const isYearValid = (year: string): boolean => {
    const yearNumber = Number(year);
    const currentYear = new Date().getFullYear();
    if (year.length < 4) {
      return false;
    }
    if (yearNumber > currentYear) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e: { target: { name: any; value: any } }): void => {
    const { name, value } = e.target;

    setData((prevData) => {
      if (name.startsWith('companyInfo.address.')) {
        const addressField = name.split('.')[2];
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            address: {
              ...prevData.companyInfo.address,
              [addressField]: value,
            },
          },
        };
      } else if (name === 'serviceAreaDescription') {
        return {
          ...prevData,
          [name]: value,
        };
      } else if (name === 'phone') {
        const numericInput = value.replace(/\D/g, '');

        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            phone: numericInput,
            formattedNo: formatPhoneNumber(numericInput),
          },
        };
      } else if (name === 'formation_year') {
        const numericInput = value.replace(/\D/g, '');

        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            formation_year: numericInput,
          },
        };
      } else if (name === 'domain') {
        const noSpacesValue = value.replace(/\s/g, '');
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            [name]: noSpacesValue,
          },
        };
      } else {
        return {
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            [name]: value,
          },
        };
      }
    });
  };

  const nav = useNavigate();

  const handleRemoveImage = (): void => {
    setData((prev) => ({
      ...prev,
      companyInfo: {
        ...prev.companyInfo,
        logo: '',
      },
    }));
    const fileInput = document.getElementById('upload-button') as HTMLInputElement | null; // Get the file input element
    if (fileInput) {
      fileInput.value = ''; // Resets the file input element to allow re-uploading the same image
    }
  };

  // const handleSelectedStatesChange = (states: string[]): void => {
  //   setLoading(false);

  //   const formattedServiceArea = states.map((state) => ({ name: state }));
  //   setData((prevData: any) => ({
  //     ...prevData,
  //     serviceArea: formattedServiceArea,
  //   }));
  // };

  const handleServiceChange = (service: any): void => {
    setData((prevData) => {
      let updatedServices;
      const existingServiceIndex = prevData?.services?.findIndex(
        (s: any) => s?.service === service?.value,
      );

      if (existingServiceIndex !== -1) {
        updatedServices = prevData?.services?.filter((s: any) => s?.service !== service?.value);
      } else {
        // If the first service object is empty, overwrite it
        if (prevData?.services[0]?.service === '') {
          updatedServices = [
            {
              service: service?.value,
              percentage: null,
              subService: [''],
              logo: service?.logo,
            },
          ];
        } else {
          // Add the service if it doesn't exist
          updatedServices = prevData?.services?.concat({
            service: service?.value,
            percentage: null,
            subService: [''],
            logo: service?.logo,
          });
        }
      }

      return { ...prevData, services: updatedServices };
    });
  };

  const isChecked = (service: string): boolean => {
    return data.services.some((s) => s?.service === service);
  };
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          const imageUrl = (res as unknown as string[])[0]; // Assuming the response contains the image URL in `data.url`

          setData((prevData) => ({
            ...prevData,
            companyInfo: {
              ...prevData.companyInfo,
              logo: imageUrl,
            },
          }));
        })
        .catch((err) => {
          console.error('Image upload error', err);
        });
    }
  };
  const handleErrorCheck = (): void => {
    if (!isYearValid?.(data?.companyInfo.formation_year)) {
      toast(<CustomToast message={'Please Enter Valid Year'} type="error" />);
      return;
    }
    const domainRegex = /^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if (domain) {
      handleSubmit();
    } else if (domainRegex.test(data?.companyInfo?.domain)) {
      // Extract part after "www."
      const valueWithoutWWW = data?.companyInfo?.domain.substring(4); // "abc.xyz"
      console.log('valueWithoutWWW', valueWithoutWWW);
      if (valueWithoutWWW) {
        setLoading(true);
        setData((prevData) => ({
          ...prevData,
          companyInfo: {
            ...prevData.companyInfo,
            domain: valueWithoutWWW,
          },
        }));

        setIsDomainUpdated(true);
      }
    } else {
      // Show toast error for invalid format
      // toast.error('Enter valid domain format.');
      toast(<CustomToast message={'Enter valid domain format.'} type="error" />);
    }
  };

  const handleSubmit = (): void => {
    if (!data?.companyInfo?.name) {
      // toast.error('Company name is required');
      toast(<CustomToast message={'Company name is required'} type="error" />);

      return;
    }
    if (!data?.companyInfo?.address.state) {
      // toast.error('Company address is required');
      toast(<CustomToast message={'Company address is required'} type="error" />);

      return;
    }

    if (!data?.companyInfo?.email) {
      // toast.error('Company email is required');
      toast(<CustomToast message={'Company email is required'} type="error" />);
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,7}$/;
    const isValidDomain = domainRegex.test(data?.companyInfo?.domain);
    if (!emailPattern.test(data.companyInfo.email)) {
      // toast.error('Invalid email format');
      toast(<CustomToast message={'Invalid email format'} type="error" />);
      return;
    }
    if (!data?.companyInfo?.domain) {
      // toast.error('Company domain is required');
      toast(<CustomToast message={'Company domain is required'} type="error" />);
      return;
    }
    if (!isValidDomain) {
      // toast.error('Enter Valid Domain Format');
      toast(<CustomToast message={'Enter Valid Domain Format'} type="error" />);
      return;
    }
    // service
    if (!data?.services[0]?.service?.length) {
      // toast.error('select atleast one service');
      toast(<CustomToast message={'select atleast one service'} type="error" />);
      return;
    }
    // service area
    // if (!data?.serviceArea[0]?.distance) {
    //   // toast.error('Add service state');
    //   toast(<CustomToast message={'Add service state'} type="error" />);

    //   return;
    // }
    // if (!data?.serviceAreaDescription) {
    //   // toast.error('Add servicearea details');
    //   toast(<CustomToast message={'Add servicearea details'} type="error" />);

    //   return;
    // }
    setLoading(true);
    console.log('final data', data);
    request('post', `/companies`, data)
      .then((res: any) => {
        const stateData = {
          user,
          company: res.company,
        };
        // toast.success(`Company created successfully`);
        toast(<CustomToast message={'Company created successfully'} type="success" />);
        if (user) {
          nav('/adduser', { state: stateData });
        } else {
          nav('/dashboard/home');
        }
        setLoading(false);

        console.log('res', res);
        setData(initialState);
      })
      .catch((err) => {
        setLoading(false);

        // toast.error('Company already exists.', err);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);

        console.log('err', err);
      });
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    validateForm();
  }, [data]);
  useEffect(() => {
    if (domain) {
      setData((prevData) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          domain,
        },
      }));
    }
  }, [domain]);

  const validateForm = (): void => {
    if (
      !data.companyInfo.name ||
      !data.companyInfo.address.state ||
      !data.companyInfo.phone ||
      !data.companyInfo.email ||
      !data.companyInfo.domain ||
      !data?.services[0]?.service.length
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };
  useEffect(() => {
    if (data?.companyInfo?.formattedNo) {
      setFormattedPhoneNumber(formatPhoneNumber(data.companyInfo.formattedNo));
    }
  }, [data?.companyInfo?.formattedNo]);
  useEffect(() => {
    if (isDomainUpdated) {
      const timer = setTimeout(() => {
        handleSubmit();
        // Reset the flag after submission
        setIsDomainUpdated(false);
      }, 1000); // Delay in milliseconds (e.g., 1000 ms = 1 second)

      return () => {
        clearTimeout(timer);
      }; // Clean up the timer
    }
  }, [isDomainUpdated]);
  useEffect(() => {
    if (company) {
      setData((prevData) => ({
        ...prevData,
        companyInfo: {
          ...prevData.companyInfo,
          name: company?.companyInfo?.name,
          domain: company?.companyInfo?.website,
        },
        services:
          company?.services?.map((service: any) => ({
            service: service?.service,
            percentage: service?.percentage,
            subService: service?.subService,
            logo: service?.logo,
          })) || [],
      }));
    }
  }, [company]);

  return (
    <Box
      width={'100%'}
      borderRadius={'16px'}
      border={'1px solid rgba(69, 66, 56, 0.10)'}
      padding={'36px'}
    >
      <Typography
        fontSize={'24px'}
        fontWeight={'600'}
        lineHeight={'20px'}
        fontFamily={'Inter'}
        color={'#000000'}
        marginBottom={'36px'}
      >
        Fill the details below:
      </Typography>
      <Box width={'65%'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            src={CompanyInfo}
            alt="Company Logo"
            style={{ width: '100px', marginBottom: '20px' }}
          />

          <Typography
            fontSize="24px"
            fontWeight="800"
            lineHeight="28.64px"
            fontFamily="SF UI Display"
            color="#000000"
            id="modal-modal-title"
          >
            Company Info{' '}
          </Typography>
          <Typography
            fontFamily="SF UI Display"
            fontSize="16px"
            fontWeight="300"
            lineHeight="19.09px"
            color="#000000"
            marginTop="10px"
            id="modal-modal-title"
          >
            Enter the company details
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} margin={'3% 0'}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
              // bgcolor={'red'}
              position={'relative'}
            >
              <Avatar
                alt="Avatar"
                src={
                  /* eslint-disable @typescript-eslint/restrict-template-expressions */ data
                    ?.companyInfo?.logo
                    ? `${mediaUrl}/${data?.companyInfo?.logo}`
                    : DefaultAvatar
                }
                sx={{ width: '64px', height: '64px' }}
              />
              {data?.companyInfo?.logo && (
                <Box
                  width={'20px'}
                  display="flex"
                  justifyContent="center"
                  alignItems={'center'}
                  position="absolute"
                  right="5px"
                  top="-3px"
                  onClick={handleRemoveImage}
                  // bgcolor={'blue'}
                >
                  <img
                    src={CloseIcon}
                    alt=".."
                    style={{ width: '18px', height: '18px', cursor: 'pointer' }}
                  />
                </Box>
              )}
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              ml={2}
            >
              <input
                id="upload-button"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => document.getElementById('upload-button')?.click()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  bgcolor: '#ffffff',
                  padding: '10px 16px',
                  border: '1px solid #D0D5DD',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&:hover': {
                    bgcolor: '#FFFFFF',
                  },
                }}
              >
                <img src={UpoadImg} alt="UpoadImg" />
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  lineHeight={'20px'}
                  fontFamily={'Inter'}
                  textTransform={'capitalize'}
                  color={'#344054'}
                >
                  Upload Company Logo
                </Typography>
              </Button>
            </Box>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Name{' '}
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="name"
              variant="outlined"
              placeholder="D’s Sign & Lighting LLC"
              name="name"
              value={data?.companyInfo?.name}
              onChange={handleChange}
              inputProps={{
                maxLength: 30,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Address{' '}
            </Typography>

            <Autocomplete
              fullWidth
              sx={{
                '.MuiOutlinedInput-root': {
                  padding: '10px 14px',
                },
              }}
              value={name}
              freeSolo
              filterOptions={(options) => options}
              options={allAddresses}
              getOptionLabel={(option: string | IAddress) => {
                if (typeof option === 'object') {
                  return option.place_name;
                } else {
                  return option;
                }
              }}
              onChange={(event, value) => {
                console.log(value, 'value');
                if (value && typeof value !== 'string') handleAddressChange(event, value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="4311 Iris Dr SW, Baltimore, MD "
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input.MuiAutocomplete-input': {
                      padding: '0px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  id="select"
                  onChange={(e) => {
                    handleGetAddress(e.target.value);
                  }}
                  // inputProps={{
                  //   sx: {
                  //     '&::placeholder': {
                  //       fontSize: '16px',
                  //     },
                  //     '@media screen and (max-width: 1060px)': {
                  //       '&::placeholder': {
                  //         fontSize: '12px',
                  //       },
                  //     },
                  //   },
                  // }}
                  InputLabelProps={{
                    style: {
                      borderRadius: '50px',
                    },
                  }}
                />
              )}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Formation Year{' '}
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="name"
              variant="outlined"
              placeholder=""
              name="formation_year"
              value={data?.companyInfo?.formation_year}
              onChange={handleChange}
              inputProps={{
                maxLength: 4,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Contractor License #{' '}
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="name"
              variant="outlined"
              placeholder=""
              name="licence"
              value={data?.companyInfo?.licence}
              onChange={handleChange}
              // inputProps={{
              //   maxLength: 30,
              // }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Size{' '}
            </Typography>
            <Select
              // ref={selectRef}
              // disabled={companyId?.length}
              name="company_size"
              fullWidth
              value={data?.companyInfo?.company_size || ''}
              onChange={handleChange}
              displayEmpty
              variant="outlined"
              open={openDrop && selectRef.current === 'companySize'}
              onClose={() => {
                setOpenDrop(false);
              }}
              onOpen={() => {
                selectRef.current = 'companySize';
                setOpenDrop(true);
              }}
              IconComponent={() => <CustomDropdownIcon dropdownRef="companySize" />}
              sx={{
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                },
                '& .MuiSelect-select': {
                  color: data?.companyInfo?.company_size ? 'inherit' : '#C0C0C0', // Change color based on value
                  padding: '10px 14px !important',
                },
              }}
            >
              <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                Select
              </MenuItem>

              <MenuItem value="Solo Operator">Solo Operator</MenuItem>
              <MenuItem value="2-4 employees">2-4 employees</MenuItem>
              <MenuItem value="5-10 employees">5-10 employees</MenuItem>
              <MenuItem value="10-30 employees">10-30 employees</MenuItem>
              <MenuItem value="30-50 employees">30-50 employees</MenuItem>
              <MenuItem value="50+ employees">50+ employees</MenuItem>
            </Select>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Union Shop
            </Typography>
            <Select
              // ref={selectRef}
              // disabled={companyId?.length}
              name="union_shop"
              fullWidth
              value={data?.companyInfo?.union_shop || ''}
              onChange={handleChange}
              displayEmpty
              variant="outlined"
              open={openDrop && selectRef.current === 'unionShop'}
              onClose={() => {
                setOpenDrop(false);
              }}
              onOpen={() => {
                selectRef.current = 'unionShop';
                setOpenDrop(true);
              }}
              IconComponent={() => <CustomDropdownIcon dropdownRef="unionShop" />}
              sx={{
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                },
                '& .MuiSelect-select': {
                  color: data?.companyInfo?.union_shop ? 'inherit' : '#C0C0C0', // Change color based on value
                  padding: '10px 14px !important',
                },
              }}
            >
              <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                Select
              </MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </Box>
          {data?.services?.some((service: any) => service.service === 'Fabricators') && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="start"
              justifyContent="start"
              gap="14px"
              margin="17px 0 19px 0"
            >
              <Typography
                sx={{
                  fontFamily: 'SF UI Display',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#000000',
                }}
              >
                UL Listed{' '}
              </Typography>
              <Select
                // ref={selectRef}
                // disabled={companyId?.length}
                name="ul_list"
                fullWidth
                value={data?.companyInfo?.ul_list || ''}
                onChange={handleChange}
                displayEmpty
                variant="outlined"
                open={openDrop && selectRef.current === 'ul_list'}
                onClose={() => {
                  setOpenDrop(false);
                }}
                onOpen={() => {
                  selectRef.current = 'ul_list';
                  setOpenDrop(true);
                }}
                IconComponent={() => <CustomDropdownIcon dropdownRef="ul_list" />}
                sx={{
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#fff',
                    '& fieldset': {
                      borderColor: '#e0e0e0',
                    },
                    '&:hover fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1px solid #D0D5DD',
                    },
                  },
                  '& .MuiSelect-select': {
                    color: data?.companyInfo?.ul_list ? 'inherit' : '#C0C0C0', // Change color based on value
                    padding: '10px 14px !important',
                  },
                }}
              >
                <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                  Select
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Box>
          )}
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Currently Available For Work{' '}
            </Typography>
            <Select
              // ref={selectRef}
              // disabled={companyId?.length}
              name="available_for_work"
              fullWidth
              value={data?.companyInfo?.available_for_work || ''}
              onChange={handleChange}
              displayEmpty
              variant="outlined"
              open={openDrop && selectRef.current === 'available_for_work'}
              onClose={() => {
                setOpenDrop(false);
              }}
              onOpen={() => {
                selectRef.current = 'available_for_work';
                setOpenDrop(true);
              }}
              IconComponent={() => <CustomDropdownIcon dropdownRef="available_for_work" />}
              sx={{
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                },
                '& .MuiSelect-select': {
                  color: data?.companyInfo?.available_for_work ? 'inherit' : '#C0C0C0', // Change color based on value
                  padding: '10px 14px !important',
                },
              }}
            >
              <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                Select
              </MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Typical Response Time{' '}
            </Typography>
            <Select
              // ref={selectRef}
              // disabled={companyId?.length}
              name="responce_time"
              fullWidth
              value={data?.companyInfo?.responce_time || ''}
              onChange={handleChange}
              displayEmpty
              variant="outlined"
              open={openDrop && selectRef.current === 'responce_time'}
              onClose={() => {
                setOpenDrop(false);
              }}
              onOpen={() => {
                selectRef.current = 'responce_time';
                setOpenDrop(true);
              }}
              IconComponent={() => <CustomDropdownIcon dropdownRef="responce_time" />}
              sx={{
                borderRadius: '8px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                  },
                  '&:hover fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    border: '1px solid #D0D5DD',
                  },
                },
                '& .MuiSelect-select': {
                  color: data?.companyInfo?.responce_time ? 'inherit' : '#C0C0C0', // Change color based on value
                  padding: '10px 14px !important',
                },
              }}
            >
              <MenuItem value="" disabled sx={{ display: openDrop ? 'none' : 'block' }}>
                Select
              </MenuItem>
              <MenuItem value="24hrs">24 Hours</MenuItem>
              <MenuItem value="48hrs">48 Hours</MenuItem>
              <MenuItem value="72hrs">72 Hours</MenuItem>
              <MenuItem value="96+hrs">96+ Hours</MenuItem>
            </Select>
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Phone Number
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="phone"
              variant="outlined"
              placeholder="(500) 234-5678"
              name="phone"
              value={formattedPhoneNumber}
              onChange={handlePhoneChange}
              inputProps={{
                pattern: '[0-9]*',
                maxLength: 14,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Email
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="email"
              variant="outlined"
              placeholder="abc@xyz.com"
              name="email"
              value={data?.companyInfo?.email}
              onChange={handleChange}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Domain
            </Typography>
            <TextField
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="Domain"
              type="text"
              variant="outlined"
              placeholder="www.mysignco.com"
              name="domain"
              value={domain ? `www.${data?.companyInfo?.domain}` : data?.companyInfo?.domain}
              onChange={domain ? undefined : handleChange}
              inputProps={{
                maxLength: 40,
                readOnly: !!domain,
              }}
            />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="start"
            gap="14px"
            margin="17px 0 19px 0"
          >
            <Typography
              sx={{
                fontFamily: 'SF UI Display',

                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#000000',
              }}
            >
              Company Overview (Optional)
            </Typography>
            <TextField
              rows={4}
              multiline
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',

                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="description"
              variant="outlined"
              placeholder="Enter description Here"
              name="description"
              value={data?.companyInfo?.description}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </Box>
      <Box width={'65%'} margin={'4% 0'}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            gap="5px"
          >
            <img src={ServiceIcon} alt="" />
            <Typography
              fontSize="24px"
              fontWeight="800"
              lineHeight="28.64px"
              fontFamily="SF UI Display"
              color=" #000000"
              id="modal-modal-title"
              margin="1% 0"
            >
              Services
            </Typography>

            <Typography
              fontFamily="SF UI Display"
              fontSize="16px"
              fontWeight="300"
              lineHeight="19.09px"
              color="#000000"
              marginTop="10px"
              id="modal-modal-title"
              mb={8}
            >
              Add all the services your company offers.
            </Typography>
          </Box>
        </Box>
        <Box width="100%">
          {options?.map((option, index) => (
            <Box
              key={index}
              borderRadius="10px"
              border="solid 1px #E8E8E8"
              bgcolor={isChecked(option?.value) ? '' : ''}
              padding="2%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="1rem"
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: '#DFFFED',
                },
              }}
              onClick={() => {
                handleServiceChange(option);
              }} // Handle box click
            >
              <Box
                display="flex"
                alignItems="center"
                gap="15px"
                onClick={() => {
                  handleServiceChange(option);
                }} // Handle box click
                bgcolor={isChecked(option?.value) ? '' : ''}
              >
                <Checkbox
                  checked={isChecked(option?.value)}
                  onChange={(event) => {
                    event.stopPropagation(); // Stop the event from propagating to the Box
                    handleServiceChange(option);
                  }}
                  sx={{
                    '&.Mui-checked': {
                      color: '#00BD57',
                    },
                  }}
                />
                <ListItemIcon>
                  <img
                    src={option.image}
                    style={{ width: '40px', height: '40px', margin: '0 5px' }}
                  />
                </ListItemIcon>
                <Typography
                  fontFamily="SF UI Display"
                  fontWeight="700"
                  fontSize="16px"
                  lineHeight="19.09px"
                >
                  {option.label}
                </Typography>
              </Box>
              {/* <Box width="50%" display="flex" justifyContent="flex-end" paddingRight="2%">
                <img src={RightArrow} alt="" style={{ width: '24px', height: '24px' }} />
              </Box> */}
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        width="65%"
        margin={'4% 0'}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection={'column'}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            src={ServiceArea}
            alt="ServiceArea"
            style={{ width: '100px', marginBottom: '15px' }}
          />

          <Typography
            fontSize="24px"
            fontWeight="800"
            lineHeight="28.64px"
            fontFamily="SF UI Display"
            color=" #000000"
            id="modal-modal-title"
          >
            Service Area
          </Typography>
          {/* <Typography
            fontFamily="SF UI Display"
            fontSize="16px"
            fontWeight="400"
            lineHeight="19.09px"
            color=" #000000"
            marginTop="4px"
            id="modal-modal-title"
            width={'60%'}
            textAlign={'center'}
          > */}
          <Typography
            fontFamily="SF UI Display"
            fontSize="16px"
            fontWeight="300"
            lineHeight="19.09px"
            color="#000000"
            marginTop="4px"
            id="modal-modal-title"
            width={'80%'}
            textAlign={'center'}
          >
            Define all the service areas you do work in to show up on all relevant searches for
            buyers and nationals.
          </Typography>
        </Box>
        <Box
          width={'100%'}
          display={'flex'}
          gap={'10px'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Box width={'300px'} height={'300px'} marginTop={'2%'}>
            <MapAreaCoverage data={data} setData={setData} radiusInMiles={radiusInMiles} />
          </Box>

          <Box
            width={'300px'}
            // sx={{
            //   '@media (max-width: 600px)': {
            //     width: '100%',
            //   },
            // }}
          >
            <PrettoSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              value={radiusInMiles}
              min={0}
              max={150}
              onChange={(e, newValue) => {
                setRadiusInMiles(newValue as number);
                setLoading(false);
              }}
              onChangeCommitted={(e, newValue) => {
                setRadiusInMiles(newValue as number);
                setLoading(false);
              }}
              valueLabelFormat={(value) => `${value} Miles`} // Customize label with "Miles"
            />
          </Box>
        </Box>
        {/* <Box width={'100%'} marginTop={'1%'} display={'flex'} flexDirection={'column'} gap="10px">
          <Box
            width={'100%'}
            display={'flex'}
            gap={'10px'}
            flexDirection={'column'}
            sx={{
              overflowY: 'auto',
            }}
          >
            {serviceAreas?.map((area) => (
              <Box
                key={area.id}
                display={'flex'}
                flexDirection={'column'}
                gap={'5px'}
                width={'100%'}
                padding={'2% 0'}
              >
                <Typography
                  fontSize={'16px'}
                  fontWeight={'600'}
                  fontFamily={'SF UI Display'}
                  lineHeight={'19.09px'}
                  color={'#000000'}
                >
                  {area.label}
                </Typography>
                <CustomizedHook
                  selectedStates={data?.serviceArea?.map((area) => area?.name)}
                  onChange={handleSelectedStatesChange}
                />
              </Box>
            ))}
          </Box>
          <Box padding="1% 0 0 0">
            <Typography
              fontSize={'16px'}
              fontWeight={'600'}
              fontFamily={'SF UI Display'}
              lineHeight={'19.09px'}
              color={'#000000'}
              mb={'1.5%'}
            >
              Service Area Details
            </Typography>
            <TextField
              multiline
              rows={4}
              sx={{
                width: '100%',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 5px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
              id="serviceAreaDescription"
              variant="outlined"
              placeholder="Enter details here"
              name="serviceAreaDescription"
              value={data.serviceAreaDescription}
              onChange={handleChange}
            />
          </Box>
        </Box> */}
      </Box>
      <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'right'}
        marginTop={'2%'}
      >
        <Button
          onClick={handleErrorCheck}
          disabled={isButtonDisabled || loading}
          sx={{
            bgcolor: isButtonDisabled || loading ? '#f0f0f0' : '#47B45F',
            color: '#ffffff',
            border: isButtonDisabled || loading ? 'none' : '1px solid #47B45F',
            borderRadius: '8px',
            padding: '10px 16px',

            minWidth: '20%',
            textTransform: 'capitalize',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '24px',
            '&:hover': {
              bgcolor: '#47B45F',
            },
          }}
        >
          {loading ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            '            Save & Add Company   '
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default AddLocalCompany;
