import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Avatar,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Modal,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Add from '../../assets/addicon.svg';
import DefaultAvatar from '../../assets/avatar.svg';
import UploadFile from '../../assets/backup.svg';
import Edit from '../../assets/companyIcon.svg';
import request from '../../api/request';
import { mediaUrl } from '../../config';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import CustomToast from '../common/CustomToast';

// import CompanyImage from '../../assets/companyAvatar.png';

const AllRegisteredCompanies: FC = () => {
  const nav = useNavigate();
  const pathname = window.location.pathname;
  const [page, setPage] = useState(1);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileLoad, setFileLoad] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<any>();
  const verifiedCompanies = companies?.filter(
    (company: any) => company?.companyInfo?.verification?.status === true,
  );
  const itemsPerPage = pathname === '/dashboard/home' ? 5 : 30;
  const filteredCompanies = verifiedCompanies.filter((company: any) =>
    company?.companyInfo?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const totalPages = Math.ceil(filteredCompanies?.length / itemsPerPage);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const handleOpenDeleteConfirmation = (user: any): void => {
    setSelectedUser(user);
    setOpenDeleteConfirmation(true);
  };
  const handleCloseDeleteConfirmation = (): void => {
    setSelectedUser(null);
    setOpenDeleteConfirmation(false);
  };
  const handlePreviousPage = (): void => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (): void => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const getAllCompanies = (): void => {
    setLoading(true);
    request('get', `/companies`)
      .then((res: any) => {
        console.log('res searcchh', res);
        setLoading(false);
        setCompanies(res?.companies ?? []);
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
      });
  };
  const handleDeleteCompany = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('delete', `/companies/${selectedUser?._id}`)
      .then((res: any) => {
        console.log('delete company res', res);
        toast(<CustomToast message={'Company deleted successfully'} type="success" />);
        handleCloseDeleteConfirmation();
        getAllCompanies();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = filteredCompanies?.slice(startIndex, endIndex);
  console.log('paginatedRows companies', paginatedRows);
  const handleFileUpload = (event: any): void => {
    const file = event.target.files[0];

    if (file) {
      setFileLoad(true);
      const formData = new FormData();
      formData.append('file', file); // Append the file to FormData
      request('post', `/companies/import-companies`, formData)
        .then((response) => {
          console.log('response 4353', response);
          toast(<CustomToast message={'Companies uploaded successfully'} type="success" />);
          setFileLoad(false);
        })
        .catch((error) => {
          console.log('error 4353', error);
          setFileLoad(false);
        });
      setFileLoad(false);
      console.log('Uploaded file:', file);
      // Handle file processing here (CSV or XLSX)
    }
  };
  useEffect(() => {
    getAllCompanies();
  }, []);
  useEffect(() => {
    setPage(1);
  }, [searchQuery]);
  return (
    <Box
      width={'74%'}
      marginBottom={'2%'}
      borderRadius={'12px'}
      border={'1px solid #EAECF0'}
      bgcolor={'#FFF'}
      boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
      minHeight={'50vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box width={'100%'}>
        <Box
          display={'flex'}
          width={'100%'}
          padding={'20px 24px'}
          borderBottom={'1px solid #EAECF0'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box>
            <Typography
              color={'#101828'}
              fontFamily={'Inter'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'28px'}
            >
              All Companies
            </Typography>
          </Box>
          <Box width={'45%'}>
            <TextField
              placeholder="Search Company"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              fullWidth
              sx={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                color: '#667085',
                borderRadius: '8px',
                '.MuiOutlinedInput-input': {
                  padding: '10px 14px',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: '1px solid #D0D5DD',
                    outline: 'none',
                  },
                  '&:hover fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#D0D5DD',
                  },
                  '&.MuiInputBase-root:focus-visible': {
                    borderColor: '#D0D5DD',
                  },
                },
              }}
            />
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            <Box display={'flex'} justifyContent={'right'} margin={'10px 0'} whiteSpace={'noowrap'}>
              <input
                accept=".csv, .xlsx"
                style={{ display: 'none' }}
                id="upload-file"
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="upload-file">
                <Button
                  // disabled
                  sx={{
                    border: '1px solid #47B45F',
                    color: '#505059',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    linHeight: '21px',
                    textAlign: 'center',
                    textTransform: 'math-auto',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    borderRadius: '8px',
                    padding: '10px 14px',
                    ':disabled': {
                      background: '#cccccc',
                      color: '#666666',
                      border: '1px solid #cccccc',
                    },
                    width: fileLoad ? '150px' : 'fit-content',
                    cursor: fileLoad ? 'default' : 'pointer',
                  }}
                  component="span"
                  disabled={fileLoad}
                >
                  {fileLoad ? <CircularProgress size={15} color="inherit" /> : 'Import csv file'}
                  {!fileLoad && <img style={{ width: '16px', height: '16px' }} src={UploadFile} />}
                </Button>
              </label>
            </Box>
            <Link to={'/addcompany'}>
              <Button
                // disabled
                sx={{
                  bgcolor: '#00BD57',
                  border: '1px solid #00BD57',
                  borderRadius: '8px',
                  padding: '10px 14px',
                  color: '#ffffff',
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  ':disabled': {
                    background: '#cccccc',
                    color: '#666666',
                    border: '1px solid #cccccc',
                  },
                  '&:hover': {
                    bgcolor: '#00BD57',
                  },
                }}
              >
                Add Company
                <img src={Add} alt="Add" />{' '}
              </Button>
            </Link>
          </Box>
        </Box>
        <Box width={'100%'}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      width: '40%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                      padding: '0 24px',
                    }}
                  >
                    Company
                  </TableCell>

                  <TableCell
                    sx={{
                      width: '20%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Created By
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '20%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Page Owner
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '20%',
                      fontSize: '12px',
                      fontWeight: '500',
                      lineHeight: '18px',
                      color: '#475467',
                      fontFamily: 'Inter',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Company Type
                  </TableCell>
                </TableRow>
              </TableHead>
              {paginatedRows?.length ? (
                <TableBody sx={{ widows: '100%' }}>
                  {paginatedRows?.map((item: any, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        cursor: 'pointer',
                        '&:last-child td, &:last-child th': {
                          borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        },
                        '&:hover': { backgroundColor: '#FADE701A' },
                      }}
                      onClick={() => {
                        /* eslint-disable @typescript-eslint/restrict-template-expressions */
                        nav(`/dashboard/requestdetail/${item?._id}`);
                      }}
                    >
                      <TableCell
                        sx={{
                          width: '40%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                        }}
                        component="th"
                        scope="row"
                      >
                        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'10px'}>
                          <Box display="flex" alignItems="center">
                            <Avatar
                              alt="Avatar"
                              src={
                                /* eslint-disable @typescript-eslint/restrict-template-expressions */ item
                                  ?.companyInfo?.logo
                                  ? `${mediaUrl}/${item?.companyInfo?.logo}`
                                  : DefaultAvatar
                              }
                              sx={{ width: '40px', height: '40px' }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              color={'#101828'}
                              fontFamily={'Inter'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'20px'}
                            >
                              {item?.companyInfo?.name}
                            </Typography>
                            <Typography
                              color={'#475467'}
                              fontFamily={'Inter'}
                              fontSize={'14px'}
                              fontWeight={'400'}
                              lineHeight={'20px'}
                            >
                              {item?.companyInfo?.address?.city && (
                                <>{item.companyInfo.address.city}</>
                              )}
                              {item?.companyInfo?.address?.state && (
                                <>
                                  {item.companyInfo.address.city && ', '}
                                  {item.companyInfo.address.state}
                                </>
                              )}
                              {item?.companyInfo?.address?.country && (
                                <>
                                  {(item.companyInfo.address.city ||
                                    item.companyInfo.address.state) &&
                                    ', '}
                                  {item.companyInfo.address.country}
                                </>
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          width: '20%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          // whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          gap={'4px'}
                          border={
                            item?.createdBy === 'admin' ? '1px solid #D9D6FE' : '1px solid #ECFDF3'
                            // item?.createdBy === 'admin'
                            //   ? '1px solid #D9D6FE'
                            //   : item?.createdBy === 'self'
                            //   ? '1px solid #ECFDF3'
                            //   : '1px solid #000000'
                          }
                          borderRadius={'16px'}
                          // boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                          padding={'4px 6px'}
                          width={'fit-content'}
                          bgcolor={
                            item?.createdBy === 'admin' ? '#F4F3FF' : '#ECFDF3'
                            // item?.createdBy === 'admin'
                            //   ? '#F4F3FF'
                            //   : item?.createdBy === 'self' && item?.companyInfo?.owner
                            //   ? '#ECFDF3'
                            //   : '#000000'
                          }
                        >
                          <Typography
                            color={
                              item?.createdBy === 'admin' ? '#5925DC' : '#067647'
                              // item?.createdBy === 'admin'
                              //   ? '#5925DC'
                              //   : item?.createdBy === 'self' && item?.companyInfo?.owner
                              //   ? '#067647'
                              //   : '#FFFFFF'
                            }
                            fontFamily={'Inter'}
                            fontSize={'12px'}
                            fontWeight={'500'}
                            textTransform={'capitalize'}
                            lineHeight={'16px'}
                          >
                            {/* {item?.createdBy === 'admin'
                              ? item?.createdBy
                              : item?.createdBy === 'self' && item?.companyInfo?.owner
                              ? 'Page Owner'
                              : 'Team Member'} */}
                            {item?.createdBy === 'admin' ? item?.createdBy : 'Page Owner'}
                          </Typography>
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{
                          width: '20%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          width={'100%'}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                        >
                          <Box>
                            <Typography
                              color={item?.companyInfo?.owner?.first_name ? '#667085' : '#FF5D39'}
                              fontFamily="SF UI Display"
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'24px'}
                              textTransform={'capitalize'}
                            >
                              {item?.companyInfo?.owner
                                ? `${item?.companyInfo?.owner?.first_name} ${item?.companyInfo?.owner?.last_name}`
                                : 'Unclaimed!'}
                            </Typography>
                            <Typography
                              color={'#667085'}
                              fontFamily="SF UI Display"
                              fontSize={'14px'}
                              fontWeight={'300'}
                              lineHeight={'24px'}
                              textTransform={'capitalize'}
                              // fontStyle={'italic'}
                            >
                              {item?.companyInfo?.owner?.job_title ?? ''}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: '20%',
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#101828',
                          fontFamily: 'Urbanist',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <Box
                          width={'100%'}
                          display={'flex'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          gap={'10px'}
                        >
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={'4px'}
                            border={'1px solid #D0D5DD'}
                            borderRadius={'6px'}
                            boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                            padding={'2px 6px'}
                            // width={'80%'}
                          >
                            <Box
                              width={'7px'}
                              height={'7px'}
                              borderRadius={'3.5px'}
                              bgcolor={
                                item?.companyInfo?.companyType === 'local'
                                  ? '#17B26A'
                                  : item?.companyInfo?.companyType === 'national'
                                  ? '#667085'
                                  : item?.companyInfo?.companyType === 'supplier'
                                  ? '#2E90FA'
                                  : 'pink'
                              }
                            />

                            <Typography
                              color={'#344054'}
                              fontFamily={'Inter'}
                              fontSize={'12px'}
                              fontWeight={'500'}
                              textTransform={'capitalize'}
                              lineHeight={'16px'}
                            >
                              {item?.companyInfo?.companyType}{' '}
                              {item?.companyInfo?.companyType !== 'supplier' &&
                                item?.companyInfo?.companyType !== 'distributor' &&
                                'Company'}
                            </Typography>
                          </Box>
                          <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                            <Box width={'16.67px'} height={'16.67px'}>
                              <img style={{ cursor: 'pointer' }} src={Edit} alt="Edit" />
                            </Box>
                            <DeleteIcon
                              sx={{ cursor: 'pointer', color: 'rgba(71, 180, 95)' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOpenDeleteConfirmation(item);
                              }}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                ''
              )}
            </Table>
            {!paginatedRows?.length ? (
              <Typography
                color={'#101828'}
                fontFamily={'Inter'}
                fontSize={'18px'}
                fontWeight={'600'}
                lineHeight={'28px'}
                padding={'2% 5%'}
                whiteSpace={'nowrap'}
              >
                {loading ? <CircularProgress size={15} color="inherit" /> : 'No Companies Found'}
              </Typography>
            ) : (
              ''
            )}
          </TableContainer>
        </Box>
      </Box>
      {paginatedRows?.length ? (
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="32px"
          sx={{
            '@media (max-width:520px)': {
              flexDirection: 'column',
              gap: '10px',
            },
          }}
        >
          <Box display={'flex'} alignItems={'center'} gap={'12px'}>
            <Button
              variant="outlined"
              onClick={handlePreviousPage}
              disabled={page === 1}
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D0D5DD',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#344054',
                padding: '8px 12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
                '&.Mui-disabled': {
                  borderColor: '#E0E0E0',
                  color: '#E0E0E0',
                },
              }}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={handleNextPage}
              disabled={page === totalPages}
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D0D5DD',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#344054',
                padding: '8px 12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
                '&.Mui-disabled': {
                  borderColor: '#E0E0E0',
                  color: '#E0E0E0',
                },
              }}
            >
              Next
            </Button>
          </Box>
          {pathname === '/dashboard/home' ? (
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'transparent',
                border: '1px solid #D0D5DD',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '20px',
                color: '#344054',
                padding: '8px 12px',
                borderRadius: '8px',
                textTransform: 'capitalize',
                '&:hover': {
                  bgcolor: 'transparent',
                },
                '&.Mui-disabled': {
                  borderColor: '#E0E0E0',
                  color: '#E0E0E0',
                },
              }}
              onClick={() => {
                nav('/dashboard/companies');
              }}
            >
              View All
            </Button>
          ) : (
            <Typography
              fontFamily="Inter"
              lineHeight="20px"
              fontSize="14px"
              color="#344054"
              fontWeight="500"
            >
              Page {page} of {Math.ceil(filteredCompanies?.length / itemsPerPage)}
            </Typography>
          )}
        </Box>
      ) : (
        ''
      )}
      <Modal open={openDeleteConfirmation} onClose={handleCloseDeleteConfirmation}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '12px',
          }}
        >
          <Typography variant="h6" gutterBottom>
            Are you sure you want to delete?
          </Typography>
          <Box
            mt={2}
            width={'100%'}
            display={'flex'}
            justifyContent="space-between"
            alignItems={'center'}
            gap={'20px'}
          >
            <Button
              onClick={handleCloseDeleteConfirmation}
              variant="outlined"
              color="primary"
              fullWidth
              sx={{
                textTransform: 'capitalize',
                border: 'solid 1px black',
                color: 'black',
                bgcolor: 'white',
                '&:hover': {
                  bgcolor: 'white',
                  border: 'solid 1px black',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{
                textTransform: 'capitalize',
                border: 'solid 1px red',
                color: 'white',
                bgcolor: 'red',
                '&:hover': {
                  bgcolor: 'red',
                  border: 'solid 1px red',
                },
              }}
              onClick={handleDeleteCompany}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AllRegisteredCompanies;
