import AddLocalCompany from '../pages/addCompany/AddLocalCompany.pages';
import AddNationalCompany from '../pages/addCompany/AddNationalCompany.pages';
import AddSupplier from '../pages/addCompany/AddSupplier.pages';
import LocalIcon from '../assets/localIcon.svg';
import NationalIcon from '../assets/nationalIcon.svg';
import SupplierIcon from '../assets/supplierIcon.svg';
import Wholesaler from '../assets/wholesalerIcon.svg';
import AddDistributor from '../pages/addCompany/AddDistributor.pages';

interface IRoutes {
  //   coloredIcon: any;
  icon: any;
  component?: any;
  path: string;
  name: string;
  layout: string;
  desp: string;
}
export const LocalSignRoutes: IRoutes[] = [
  {
    icon: LocalIcon,
    component: AddLocalCompany,
    path: '/localcompany',
    name: 'Local Company',
    layout: '/addcompany',
    desp: 'Partnering with Community-Based Enterprises',
  },
  {
    icon: NationalIcon,
    component: AddNationalCompany,
    path: '/national',
    name: 'National Company',
    layout: '/addcompany',
    desp: 'Expanding Reach with Nationwide Presence',
  },
  {
    icon: SupplierIcon,
    component: AddSupplier,
    path: '/supplier',
    name: 'Suppliers',
    layout: '/addcompany',
    desp: 'Connecting you with trusted sources for your needs',
  },
  {
    icon: SupplierIcon,
    component: AddDistributor,
    path: '/distributor',
    name: 'Distributors',
    layout: '/addcompany',
    desp: 'Connecting you with reliable sources to meet your needs',
  },
  {
    icon: Wholesaler,
    // component: AddNationalCompany,
    path: '/wholesalers',
    name: 'Wholesalers',
    layout: '/addcompany',
    desp: ' Bridging the Gap Between Manufacturers and Retailers',
  },
];
