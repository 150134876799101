import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button, TextField, Typography } from '@mui/material';

interface ReviewModalProps {
  open: boolean;
  onClose: () => void;
  reason: string;
  handleReject: (id: any) => void;
  handleAccept: () => void;
  setReason?: (data: any) => any;
}

const style = {
  position: 'absolute',
  maxHeight: '70vh',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '700px',
  bgcolor: '#ffffff',
  boxShadow: 24,
  borderRadius: '10px',
  p: 3,
  overflowY: 'auto',
};

const FlagModal: React.FC<ReviewModalProps> = ({
  open,
  onClose,
  reason,
  handleReject,
  handleAccept,
  setReason,
}) => {
  return (
    <Box>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          sx: {
            backgroundColor: 'var(#0C111D)',
            opacity: 0.7,
            backdropFilter: 'blur(8px)', // Adjust the blur level as needed
          },
        }}
      >
        <Box sx={style}>
          <>
            <Box width={'100%'} padding={'2%'} alignItems={'center'}>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="500"
                fontSize="16px"
                // textAlign="center"
                lineHeight="19.09px"
                color="black"
                mb={'5px'}
              >
                Indicate the Reason
              </Typography>
              <Typography
                fontFamily="SF UI Display"
                fontWeight="400"
                fontSize="16px"
                // textAlign="center"
                lineHeight="19.09px"
                color="black"
                mb={4}
              >
                Provide us more information on why you are removing this review.
              </Typography>
              <TextField
                fullWidth
                rows={4}
                multiline
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  color: '#667085',
                  borderRadius: '8px',
                  '.MuiOutlinedInput-input': {
                    padding: '10px 14px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: '1px solid #D0D5DD',
                      outline: 'none',
                    },
                    '&:hover fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#D0D5DD',
                    },
                    '&.MuiInputBase-root:focus-visible': {
                      borderColor: '#D0D5DD',
                    },
                  },
                }}
                InputProps={{
                  inputProps: {
                    maxLength: 500,
                  },
                }}
                id="fname"
                variant="outlined"
                placeholder="Write your explanation here..."
                value={reason}
                onChange={(e) => {
                  setReason?.(e.target.value);
                }}
              />
              <Box
                width={'100%'}
                mt={2}
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Button
                  onClick={onClose}
                  variant="contained"
                  sx={{
                    width: '49%',
                    padding: '10px 24px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '4px',
                    borderRadius: '8px',
                    border: '1px solid rgba(208, 213, 221, 1)',
                    background: '#FFFFFF',
                    color: '#000',
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    fontFamily: 'SF UI Display',
                    fontSize: '16px',
                    '&:hover': {
                      bgcolor: '#FFFFFF',
                    },
                  }}
                  // disabled={loading}
                  // onClick={() => {
                  //   handleFlag(reason);
                  //   setReason('');
                  // }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleAccept}
                  variant="contained"
                  sx={{
                    width: '49%',
                    gap: '4px',
                    padding: '10px 24px',
                    borderRadius: '8px',
                    border: '1px solid #00BD57',
                    background: '#00BD57',
                    color: 'white',
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    fontFamily: 'SF UI Display',
                    fontSize: '16px',
                    '&:hover': {
                      backgroundColor: '#00BD57',
                    },
                  }}
                  // disabled={loading}
                  // onClick={() => {
                  //   handleFlag(reason);
                  //   setReason('');
                  // }}
                >
                  Remove
                </Button>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>
    </Box>
  );
};

export default FlagModal;
