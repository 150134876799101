import React, { FC, useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Header from '../components/Header.component';
import Tooltip from '@mui/material/Tooltip';

// import Edit from '../assets/editIcon.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Close } from '../assets/close.svg';
import { ReactComponent as Info } from '../assets/info.svg';
import ReviewModal from '../popups/Review.popup';
import { toast } from 'react-toastify';
import request from '../api/request';
import { IReviews, IReviewsResponse } from '../types/user';
import FlagIcon from '../assets/flag.svg';
// import { PagesOutlined } from '@mui/icons-material';
import ClaimArrow from '../assets/claimArrow.svg';
import EditReviewModal from '../popups/EditReviewModal.popup';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { mediaUrl } from '../config';
import FlagModal from '../popups/Flag.popup';
import RemovebuttonIcon from '../assets/DesignUpdate/ReportedButtonIcon.svg';
import CustomToast from '../components/common/CustomToast';
import { useNavigate } from 'react-router-dom';

const Review: FC = () => {
  const nav = useNavigate();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = React.useState(false);
  const [dataLoading, setDataLoading] = React.useState(true);
  const [openFlag, setOpenFlag] = React.useState(false);
  const [reviewList, setReviewList] = useState<IReviews[]>([]);
  const [totalReviewCount, setTotalReviewCount] = useState<number>(0);
  const [reason, setReason] = useState<string>('');
  const [reviewId, setReviewId] = useState<string>('');
  const [approveLoad, setApproveLoad] = useState<any>(null);
  const [rejectLoad, setRejectLoad] = useState<any>(null);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [reviewDetails, setReviewDetails] = useState<any>();
  const [reviewOpen, setReviewOpen] = React.useState(false);
  const [singleReview, setSingleReview] = useState<any>();

  const itemsPerPage = 8;
  const totalPages = Math.ceil(totalReviewCount / itemsPerPage);

  console.log('reviewListreviewList', reviewList);

  const handlePreviousPage = (): void => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (): void => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  const handleClose = (): void => {
    setOpenFlag(false);
    setReason('');
  };
  const handleReviewOpen = (data: any): void => {
    setReviewOpen(true);
    setSingleReview(data);
  };
  const handleReviewClose = (): void => {
    setReviewOpen(false);
  };

  useEffect(() => {
    getAllReviews();
  }, [page, itemsPerPage]);

  const getAllReviews = (): void => {
    setDataLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<IReviewsResponse>('get', `/review?page=${page}&pageSize=${itemsPerPage}`)
      .then((res) => {
        setTotalReviewCount(res.totalReviews);
        setReviewList(res?.reviews);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
        setDataLoading(false);
      });
  };

  const handleAccept = (): void => {
    request('patch', `/review/reportUpdate/${reviewId}`, {
      reason,
      type: 'remove',
    })
      .then((res) => {
        handleClose();
        getAllReviews();
        // toast.success('Review is removed. An email has been sent to the reviewer.');
        toast(
          <CustomToast
            message={'Review is removed. An email has been sent to the reviewer.'}
            type="success"
          />,
        );
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };
  const handleReject = (id: any): void => {
    setRejectLoad(id);
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('patch', `/review/reportUpdate/${id ?? reviewId}`, {
      reason,
      type: 'reject',
    })
      .then((res) => {
        handleClose();
        getAllReviews();
        setRejectLoad(null);
        setSingleReview((prevReview: any) => {
          if (prevReview?._id === reviewId) {
            return { ...prevReview, review_status: 'approved' };
          }
          return prevReview;
        });
        // toast.success('Review is Rejected');
        toast(<CustomToast message={'Review is Rejected'} type="success" />);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setRejectLoad(null);
      });
  };
  const handleOpenEditModal = (review: any): void => {
    setReviewDetails(review);
    setOpenReviewModal(true);
  };

  const handleUpdateStatues = (id: string, status: string): void => {
    const body = {
      review_status: status,
    };
    if (status === 'approved') {
      setApproveLoad(id);
    } else {
      setRejectLoad(id);
    }
    setLoading(true);
    request('put', `/review/${id}`, body)
      .then((res) => {
        console.log('res', res);
        setLoading(false);
        setApproveLoad(null);
        setRejectLoad(null);
        if (status === 'approved') {
          // toast.success('Approved Succesfully');
          toast(<CustomToast message={'Approved Succesfully'} type="success" />);
        } else {
          // toast.success('Rejected Succesfully');
          toast(<CustomToast message={'Rejected Succesfully'} type="success" />);
        }
        getAllReviews();
        setSingleReview((prevReview: any) => {
          if (prevReview?._id === id) {
            return { ...prevReview, review_status: status };
          }
          return prevReview;
        });
      })
      .catch((err) => {
        setLoading(false);
        setApproveLoad(null);
        setRejectLoad(null);

        console.log('err', err);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
      });
  };
  console.log('reviewList review rating', reviewList);
  const handleCloseReviewModal = (): void => {
    setOpenReviewModal(false);
    setReviewDetails('');
  };
  return (
    <Box display="flex" flexDirection={'column'} alignItems="center">
      <Header />
      <Box
        width={'74%'}
        marginBottom={'2%'}
        borderRadius={'12px'}
        border={'1px solid #EAECF0'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
        minHeight={'50vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        margin={'2% 0'}
      >
        <Box width={'100%'}>
          <Box width={'100%'} padding={'20px 24px'} borderBottom={'1px solid #EAECF0'}>
            <Typography
              color={'#101828'}
              fontFamily={'Inter'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'28px'}
            >
              Recent Ratings
            </Typography>
            {/* <Typography
              color={'#475467'}
              fontFamily={'Inter'}
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
            >
              Oversee and Control Reviews for Local Enterprises
            </Typography> */}
          </Box>
          <Box width={'100%'}>
            <TableContainer sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: '25%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        padding: '0 24px',
                      }}
                    >
                      Rated Company
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '25% !important',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        padding: '0 24px',
                      }}
                    >
                      Status
                    </TableCell>

                    <TableCell
                      sx={{
                        width: '15%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Feedback
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '10%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Rating
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '35%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Approve / Reject
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewList?.length
                    ? reviewList?.map((item: any, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            cursor: 'pointer',
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': { backgroundColor: '#FADE701A' },
                          }}
                          onClick={() => {
                            handleReviewOpen(item);
                            setReviewId(item?._id);
                          }}
                        >
                          <TableCell
                            sx={{
                              width: '25%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                              padding: '16px 24px',
                            }}
                            component="th"
                            scope="row"
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              // justifyContent={'space-between'}
                              alignItems={'center'}
                              gap={'12px'}
                            >
                              <Box width={'40px'} height={'40px'}>
                                <Avatar
                                  src={
                                    item?.company?.companyInfo?.logo
                                      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                        `${mediaUrl}/${item?.company?.companyInfo?.logo}`
                                      : ''
                                  }
                                  sx={{
                                    width: '40px',
                                    height: '40px',
                                  }}
                                />
                              </Box>
                              <Box width={'70%'}>
                                <Typography
                                  color={'#101828'}
                                  fontFamily={'Inter'}
                                  fontSize={'14px'}
                                  fontWeight={'500'}
                                  lineHeight={'20px'}
                                >
                                  {item?.company?.companyInfo?.name}
                                </Typography>
                                <Typography
                                  color={'#475467'}
                                  fontFamily={'Inter'}
                                  fontSize={'14px'}
                                  fontWeight={'400'}
                                  lineHeight={'20px'}
                                >
                                  <Tooltip
                                    title={
                                      item?.company?.companyInfo?.description ?? 'description here'
                                    }
                                    arrow
                                  >
                                    <span style={{ cursor: 'pointer' }}>
                                      {item?.company?.companyInfo?.description
                                        ? item.company.companyInfo.description.length > 15
                                          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                            `${item.company.companyInfo.description.substring(
                                              0,
                                              15,
                                            )}..`
                                          : item.company.companyInfo.description
                                        : ''}
                                    </span>
                                  </Tooltip>
                                </Typography>
                              </Box>
                              <Box width={'20px'}>
                                <img src={ClaimArrow} alt="" />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '25% !important',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                              padding: '16px 24px',
                              textTransform: 'capitalize',
                            }}
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'flex-start'}
                              width={'100%'}
                              gap={'10px'}
                            >
                              {item?.review_status === 'pending' && (
                                <Typography
                                  width="37px"
                                  height="22px"
                                  padding={'2px 6px 2px 6px'}
                                  borderRadius={'6px'}
                                  border={'1px solid rgba(199, 215, 254, 1)'}
                                  bgcolor={'#EEF4FF'}
                                  fontWeight={500}
                                  fontSize={'12px'}
                                  lineHeight={'18px'}
                                  textAlign={'center'}
                                  color={'rgba(53, 56, 205, 1)'}
                                  fontFamily="SF UI Display"
                                >
                                  New
                                </Typography>
                              )}
                              {item?.review_status === 'approved' && (
                                <Typography
                                  width="64px"
                                  height="22px"
                                  padding={'2px 6px 2px 6px'}
                                  borderRadius={'6px'}
                                  border={'1px solid rgba(171, 239, 198, 1)'}
                                  bgcolor={'#ECFDF3'}
                                  fontWeight={500}
                                  fontSize={'12px'}
                                  lineHeight={'18px'}
                                  textAlign={'center'}
                                  color={'rgba(6, 118, 71, 1)'}
                                  fontFamily="SF UI Display"
                                >
                                  Approved
                                </Typography>
                              )}
                              {item?.review_status === 'rejected' && (
                                <Typography
                                  width="59px"
                                  height="22px"
                                  padding={'2px 6px'}
                                  borderRadius={'6px'}
                                  border={'1px solid rgba(254, 205, 202, 1)'}
                                  bgcolor={'#FEF3F2'}
                                  fontWeight={500}
                                  fontSize={'12px'}
                                  lineHeight={'18px'}
                                  textAlign={'center'}
                                  color={'rgba(180, 35, 24, 1)'}
                                  fontFamily="SF UI Display"
                                >
                                  Rejected
                                </Typography>
                              )}
                              {item?.review_status === 'reported' && (
                                <Typography
                                  width="62px"
                                  height="22px"
                                  padding={'2px 6px'}
                                  borderRadius={'6px'}
                                  border={'1px solid rgba(254, 223, 137, 1)'}
                                  bgcolor={'#FFFAEB'}
                                  fontWeight={500}
                                  fontSize={'12px'}
                                  lineHeight={'18px'}
                                  textAlign={'center'}
                                  color={'rgba(181, 71, 8, 1)'}
                                  fontFamily="SF UI Display"
                                >
                                  Reported
                                </Typography>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '15%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Typography
                              color={'#101828'}
                              fontFamily={'Inter'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'24px'}
                            >
                              {/* {item?.feedback && item.feedback.length > 36
                                ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                  `${item.feedback.slice(0, 36)}...`
                                : item.feedback} */}
                              {/* {item?.createdBy?.company?.companyInfo?.name ?? '-'} */}
                              {item?.feedback[0]?.answer?.length > 0
                                ? item?.feedback[0]?.answer.length > 20
                                  ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    `${item?.feedback[0]?.answer.slice(0, 20)}...`
                                  : item?.feedback[0]?.answer
                                : item?.feedback[1]?.answer?.length > 0
                                ? item?.feedback[1]?.answer.length > 20
                                  ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    `${item?.feedback[1]?.answer.slice(0, 20)}...`
                                  : item?.feedback[1]?.answer
                                : item?.feedback[2]?.answer?.length > 0
                                ? item?.feedback[2]?.answer.length > 20
                                  ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    `${item?.feedback[2]?.answer.slice(0, 20)}...`
                                  : item?.feedback[2]?.answer
                                : item?.feedback[3]?.answer?.length > 0
                                ? item?.feedback[3]?.answer.length > 20
                                  ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                    `${item?.feedback[3]?.answer.slice(0, 20)}...`
                                  : item?.feedback[3]?.answer
                                : '-'}{' '}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '10%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Rating
                              name="read-only"
                              precision={0.1}
                              value={item?.overall_rating}
                              readOnly
                              sx={{
                                color: !item?.overall_rating
                                  ? '#9E9E9E'
                                  : item?.overall_rating >= 4
                                  ? '#00BD57'
                                  : item?.overall_rating >= 3
                                  ? '#FFB800'
                                  : '#FD3E14',
                                // item?.overall_rating >= 3 ? '#00BD57' : '#FD3E14'
                              }}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '35%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              {item?.review_status === 'pending' && (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  width={'60%'}
                                  gap={'10px'}
                                >
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUpdateStatues(item?._id, 'approved');
                                    }}
                                    disabled={loading}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid #47B45F',
                                      background: 'transparent',
                                      color: '#454238B2',
                                      textTransform: 'capitalize',
                                      width: '100%',
                                      // '&:hover': {
                                      //   bgcolor: '#47B45F',
                                      // },
                                      '@media (max-width: 1500px)': {
                                        width: '130px',
                                      },
                                    }}
                                  >
                                    {approveLoad === item?._id ? (
                                      <CircularProgress size={15} color="inherit" />
                                    ) : (
                                      <>
                                        <Box>
                                          <Check />
                                        </Box>
                                        <Typography
                                          fontSize={'14px'}
                                          fontWeight={'600'}
                                          fontFamily={'Inter'}
                                          lineHeight={'20px'}
                                          color={'#454238B2'}
                                        >
                                          Approve
                                        </Typography>
                                      </>
                                    )}
                                  </Button>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleUpdateStatues(item?._id, 'rejected');
                                    }}
                                    disabled={loading}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid #FF5D39',
                                      background: '#FFFFFF',
                                      color: '#ffffff',
                                      textTransform: 'capitalize',
                                      width: '100%',

                                      '&:hover': {
                                        bgcolor: '#FFFFFF',
                                      },
                                      '@media (max-width: 1500px)': {
                                        width: '130px',
                                      },
                                    }}
                                  >
                                    {rejectLoad === item?._id ? (
                                      <CircularProgress size={15} color="inherit" />
                                    ) : (
                                      <>
                                        <Box>
                                          {/* <img src={Close} alt="Check" /> */}
                                          <Close color="#FF5D39" />
                                        </Box>
                                        <Typography
                                          fontSize={'14px'}
                                          fontWeight={'600'}
                                          fontFamily={'Inter'}
                                          lineHeight={'20px'}
                                          color={'#FF5D39'}
                                        >
                                          Reject
                                        </Typography>
                                      </>
                                    )}
                                  </Button>
                                </Box>
                              )}
                              {item?.review_status === 'approved' && (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  width={'60%'}
                                >
                                  <Button
                                    disabled={!item.flag}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpenFlag(true);
                                      // setReason(item?.reason);
                                      setReviewId(item?._id);
                                    }}
                                    fullWidth
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      background: !item.flag
                                        ? 'rgba(71, 180, 95, 0.50)'
                                        : '#EFCF7D',
                                      color: '#ffffff',
                                      textTransform: 'capitalize',
                                      '&:hover': {
                                        bgcolor: '#EFCF7D',
                                      },
                                      '@media (max-width: 1500px)': {
                                        width: '230px',
                                      },
                                    }}
                                  >
                                    {!item.flag && (
                                      <Box>
                                        <Check color="#E1E1E1" />
                                      </Box>
                                    )}

                                    <Box display="flex" alignItems="center" gap="8px">
                                      {item.flag && (
                                        <img
                                          src={FlagIcon}
                                          alt="Flag Icon"
                                          width={20}
                                          height={20}
                                        />
                                      )}
                                      <Typography
                                        fontSize={'14px'}
                                        fontWeight={'600'}
                                        fontFamily={'Inter'}
                                        lineHeight={'20px'}
                                        color={'#ffffff'}
                                      >
                                        {item.flag ? 'Flagged by Company' : 'Approved'}
                                      </Typography>
                                    </Box>
                                  </Button>
                                </Box>
                              )}
                              {item?.review_status === 'rejected' && (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  width={'60%'}
                                >
                                  <Button
                                    disabled
                                    fullWidth
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid rgba(255, 93, 57, 0.50)',
                                      background: '#FFFFFF',
                                      color: '#FF5D3980',
                                      textTransform: 'capitalize',
                                      '&:hover': {
                                        bgcolor: '#FFFFFF',
                                      },
                                    }}
                                  >
                                    <Box>
                                      <Close color="#FF5D39" opacity={0.5} />
                                    </Box>
                                    <Typography
                                      fontSize={'14px'}
                                      fontWeight={'600'}
                                      fontFamily={'Inter'}
                                      lineHeight={'20px'}
                                      color={'#FF5D3980'}
                                    >
                                      Rejected
                                    </Typography>
                                  </Button>
                                </Box>
                              )}
                              {item?.review_status === 'reported' && (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  width={'60%'}
                                  gap={'10px'}
                                >
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpenFlag(true);
                                      // setReason(item?.reason);
                                      setReviewId(item?._id);
                                    }}
                                    disabled={loading}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid rgba(239, 207, 125, 1)',
                                      background: 'transparent',
                                      color: '#454238B2',
                                      textTransform: 'capitalize',
                                      width: '100%',
                                      // '&:hover': {
                                      //   bgcolor: '#47B45F',
                                      // },
                                      '@media (max-width: 1500px)': {
                                        width: '130px',
                                      },
                                    }}
                                  >
                                    {approveLoad === item?._id ? (
                                      <CircularProgress size={15} color="inherit" />
                                    ) : (
                                      <>
                                        <Box width={'80px'} height={'20px'}>
                                          <img
                                            src={RemovebuttonIcon}
                                            alt="."
                                            style={{ width: '100%', height: '100%' }}
                                          />
                                        </Box>
                                        <Typography
                                          fontSize={'14px'}
                                          fontWeight={'600'}
                                          fontFamily={'Inter'}
                                          lineHeight={'20px'}
                                          color={'#454238B2'}
                                        >
                                          Remove
                                        </Typography>
                                      </>
                                    )}
                                  </Button>
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setReviewId(item?._id);
                                      handleReject(item?._id);
                                    }}
                                    disabled={loading}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid #FF5D39',
                                      background: '#FFFFFF',
                                      color: '#ffffff',
                                      textTransform: 'capitalize',
                                      width: '100%',

                                      '&:hover': {
                                        bgcolor: '#FFFFFF',
                                      },
                                      '@media (max-width: 1500px)': {
                                        width: '130px',
                                      },
                                    }}
                                  >
                                    {rejectLoad === item?._id ? (
                                      <CircularProgress size={15} color="inherit" />
                                    ) : (
                                      <>
                                        <Box>
                                          {/* <img src={Close} alt="Check" /> */}
                                          <Close color="#FF5D39" />
                                        </Box>
                                        <Typography
                                          fontSize={'14px'}
                                          fontWeight={'600'}
                                          fontFamily={'Inter'}
                                          lineHeight={'20px'}
                                          color={'#FF5D39'}
                                        >
                                          Reject
                                        </Typography>
                                      </>
                                    )}
                                  </Button>
                                </Box>
                              )}

                              {/* <Typography
                            color={'#475467'}
                            fontSize={'14px'}
                            fontWeight={'400'}
                            lineHeight={'20px'}
                            fontFamily={'Inter'}
                            sx={{ cursor: 'pointer' }}
                          >
                            More Details
                          </Typography> */}
                              <Box display={'flex'} alignItems={'center'}>
                                {/* <ReviewModal
                                  item={item}
                                  handleUpdateStatues={handleUpdateStatues}
                                  loading={loading}
                                  rejectLoad={rejectLoad}
                                  approveLoad={approveLoad}
                                /> */}
                                <Button
                                  sx={{
                                    color: '#475467',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    fontFamily: 'Inter',
                                    cursor: 'pointer',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {' '}
                                  <Info />
                                </Button>
                                {/* <EditReviewModal
                                  companyData={item}
                                  open={openReviewModal}
                                  onClose={handleCloseReviewModal}
                                  setOpenReviewModal={setOpenReviewModal}
                                /> */}
                                <BorderColorIcon
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenEditModal(item);
                                    nav('/dashboard/edit-review', {
                                      state: { companyData: item },
                                    });
                                  }}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
              {!reviewList?.length ? (
                <Typography
                  color={'#101828'}
                  fontFamily={'Inter'}
                  fontSize={'18px'}
                  fontWeight={'600'}
                  lineHeight={'28px'}
                  padding={'2% 5%'}
                  whiteSpace={'nowrap'}
                >
                  {dataLoading ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    'No Reviews Found'
                  )}
                </Typography>
              ) : (
                ''
              )}
            </TableContainer>
          </Box>
        </Box>
        {reviewList?.length ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="32px"
            sx={{
              '@media (max-width:520px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}
          >
            <Box display={'flex'} alignItems={'center'} gap={'12px'}>
              <Button
                variant="outlined"
                onClick={handlePreviousPage}
                disabled={page === 1}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={page === totalPages}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Next
              </Button>
            </Box>
            <Typography
              fontFamily="Inter"
              lineHeight="20px"
              fontSize="14px"
              color="#344054"
              fontWeight="500"
            >
              Page {page} of {totalPages}
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
      {openFlag && (
        <FlagModal
          open={openFlag}
          onClose={handleClose}
          reason={reason}
          handleAccept={handleAccept}
          handleReject={handleReject}
          setReason={setReason}
        />
      )}
      <EditReviewModal
        companyData={reviewDetails}
        open={openReviewModal}
        onClose={handleCloseReviewModal}
        getAllReviews={getAllReviews}
      />
      <ReviewModal
        item={singleReview}
        reviewOpen={reviewOpen}
        handleReviewClose={handleReviewClose}
        handleUpdateStatues={handleUpdateStatues}
        loading={loading}
        rejectLoad={rejectLoad}
        approveLoad={approveLoad}
        setOpenFlag={setOpenFlag}
        setReason={setReason}
        handleReject={handleReject}
        setReviewId={setReviewId}
      />
    </Box>
  );
};

export default Review;
