import React, { FC, useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Header from '../components/Header.component';
import { toast } from 'react-toastify';
import request from '../api/request';
import CustomToast from '../components/common/CustomToast';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MessageModal from '../popups/MessageModal.popup';

const Messages: FC = () => {
  const [page, setPage] = useState(1);
  const [dataLoading, setDataLoading] = React.useState(true);
  const [messagesList, setMessagesList] = useState<any>([]);
  //   const [totalMessagesCount, setTotalMessagesCount] = useState<number>(0);
  const [singleMessage, setSingleMessage] = useState<any>();
  const [messageOpen, setMessageOpen] = React.useState(false);
  //   const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  //   const itemsPerPage = 9;
  //   const totalPages = Math.ceil(totalMessagesCount / itemsPerPage);

  console.log('reviewListreviewList', singleMessage, messageOpen);

  const handlePreviousPage = (): void => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = (): void => {
    if (page < totalPages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleMessageOpen = (data: any): void => {
    setMessageOpen(true);
    setSingleMessage(data);
  };
  const handleMessageClose = (data: any): void => {
    setMessageOpen(false);
    setSingleMessage(null);
  };

  useEffect(() => {
    getMessages(page);
  }, [page]);

  const getMessages = (page: number): void => {
    setDataLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request<any>('get', `/companies/contactUs?page=${page}`)
      .then((res) => {
        console.log('/companies/contactUs', res);
        setMessagesList(res?.contactUs);
        // setTotalMessagesCount(res?.totalDocuments);
        setTotalPages(res?.totalPages);
        setDataLoading(false);
      })
      .catch((err) => {
        console.log('err', err);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
        setDataLoading(false);
      });
  };

  return (
    <Box display="flex" flexDirection={'column'} alignItems="center">
      <Header />
      <Box
        width={'74%'}
        marginBottom={'2%'}
        borderRadius={'12px'}
        border={'1px solid #EAECF0'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
        minHeight={'50vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        margin={'2% 0'}
      >
        <Box width={'100%'}>
          <Box width={'100%'} padding={'20px 24px'} borderBottom={'1px solid #EAECF0'}>
            <Typography
              color={'#101828'}
              fontFamily={'Inter'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'28px'}
            >
              Recent Messages
            </Typography>
          </Box>
          <Box width={'100%'}>
            <TableContainer sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        width: '25%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'SF UI Display',
                        whiteSpace: 'nowrap',
                        padding: '0 24px',
                      }}
                    >
                      User
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '15% !important',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'SF UI Display',
                        whiteSpace: 'nowrap',
                        padding: '0 24px',
                      }}
                    >
                      Job Title
                    </TableCell>

                    <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'SF UI Display',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Company
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '35%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'SF UI Display',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Message
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '3%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'SF UI Display',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {''}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messagesList?.length
                    ? messagesList?.map((item: any, index: number) => (
                        <TableRow
                          key={index}
                          sx={{
                            cursor: 'pointer',
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': { backgroundColor: '#FADE701A' },
                          }}
                          onClick={() => {
                            handleMessageOpen(item);
                          }}
                        >
                          <TableCell
                            sx={{
                              width: '25%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                              padding: '16px 24px',
                            }}
                            component="th"
                            scope="row"
                          >
                            <Box width={'100%'} gap={'12px'}>
                              <Box
                                width={'100%'}
                                display={'flex'}
                                alignItems={'center'}
                                gap={'5px'}
                              >
                                <Typography
                                  color={'#101828'}
                                  fontFamily={'SF UI Display'}
                                  fontSize={'14px'}
                                  fontWeight={'500'}
                                  lineHeight={'20px'}
                                >
                                  {item?.first_name}
                                </Typography>
                                <Typography
                                  color={'#101828'}
                                  fontFamily={'SF UI Display'}
                                  fontSize={'14px'}
                                  fontWeight={'500'}
                                  lineHeight={'20px'}
                                >
                                  {item?.last_name}
                                </Typography>
                              </Box>
                              <Box width={'100%'}>
                                <Typography
                                  color={'#475467'}
                                  fontFamily={'Inter'}
                                  fontSize={'14px'}
                                  fontWeight={'300'}
                                  lineHeight={'20px'}
                                >
                                  {item?.email}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '15% !important',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                              padding: '16px 24px',
                              textTransform: 'capitalize',
                            }}
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'flex-start'}
                              width={'100%'}
                            >
                              <Typography
                                fontWeight={500}
                                fontSize={'14px'}
                                lineHeight={'20px'}
                                fontFamily="SF UI Display"
                                color={'#101828'}
                              >
                                {item?.job_title}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '20%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Typography
                              color={'#101828'}
                              fontFamily={'SF UI Display'}
                              fontSize={'14px'}
                              fontWeight={'500'}
                              lineHeight={'20px'}
                            >
                              {item?.sender_company}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '35%',
                              fontSize: '14px',
                              fontWeight: '400',
                              color: '#000000',
                              fontFamily: 'SF UI Display',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {item?.message?.length > 70
                              ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                `${item.message.slice(0, 70)}...`
                              : item.message}
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '3%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <InfoOutlinedIcon sx={{ color: '#47B45F' }} />
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
              {!messagesList?.length ? (
                <Typography
                  color={'#101828'}
                  fontFamily={'Inter'}
                  fontSize={'18px'}
                  fontWeight={'600'}
                  lineHeight={'28px'}
                  padding={'2% 5%'}
                  whiteSpace={'nowrap'}
                >
                  {dataLoading ? (
                    <CircularProgress size={15} color="inherit" />
                  ) : (
                    'No Reviews Found'
                  )}
                </Typography>
              ) : (
                ''
              )}
            </TableContainer>
          </Box>
        </Box>
        {messagesList?.length ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="32px"
            sx={{
              '@media (max-width:520px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}
          >
            <Box display={'flex'} alignItems={'center'} gap={'12px'}>
              <Button
                variant="outlined"
                onClick={handlePreviousPage}
                disabled={page === 1}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={page === totalPages}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Next
              </Button>
            </Box>
            <Typography
              fontFamily="Inter"
              lineHeight="20px"
              fontSize="14px"
              color="#344054"
              fontWeight="500"
            >
              Page {page} of {totalPages}
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
      <MessageModal
        singleMessage={singleMessage}
        messageOpen={messageOpen}
        handleMessageClose={handleMessageClose}
      />
    </Box>
  );
};

export default Messages;
