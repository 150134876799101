/* eslint-disable @typescript-eslint/no-base-to-string */
import React, { FC, useEffect, useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Divider, Typography } from '@mui/material';
import Header from '../components/Header.component';
// import Star from '../assets/recommendedIcon.svg';
// import Complaint from '../assets/complaintIcon.svg';
// import Favourite from '../assets/favIcon.svg';
// import Share from '../assets/shareIcon.svg';
import GreenCheck from '../assets/greenCheck.svg';
import BackArrow from '../assets/arrowback.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Close } from '../assets/close.svg';
import request from '../api/request';
import { useParams } from 'react-router-dom';
import { mediaUrl } from '../config';
import { toast } from 'react-toastify';
import Dummyimage from '../assets/dummyimg.png';
import CustomToast from '../components/common/CustomToast';
import MapView from '../components/common/MapView';

const ClaimDetails: FC = () => {
  const params = useParams();
  const { claimId } = params;
  // const [showWebsite, setShowWebsite] = useState(false);
  // const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  // const [showEmailAddress, setShowEmailAddress] = useState(false);
  const [claimDetials, setClaimDetials] = useState<any>();
  const [linkedAccounts, setLinkedAccounts] = useState<any>();
  const [approveLoad, setApproveLoad] = useState<any>(null);
  const [rejectLoad, setRejectLoad] = useState<any>(null);
  const handleBackClick = (): void => {
    window.history.back();
  };

  console.log('claimDetialsclaimDetials', claimDetials);
  console.log('claimDetialsclaimDetials', linkedAccounts);
  // const handleWebsiteClick = (): void => {
  //   setShowWebsite(true);
  // };

  // const handlePhoneNumberClick = (): void => {
  //   setShowPhoneNumber(true);
  // };

  // const handleEmailAddressClick = (): void => {
  //   setShowEmailAddress(true);
  // };
  const getLinkedAccounts = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('get', `/users/getFellowMembers/${claimDetials?.company?._id}`)
      .then((res: any) => {
        console.log('linkedAccounts res', res);
        setLinkedAccounts(res?.users);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const handleApproved = (id: string, status: string): void => {
    // setLoading(true);
    if (status === 'approved') {
      setApproveLoad(id);
    } else {
      setRejectLoad(id);
    }
    request('patch', `/companies/claim/${id}`, {
      status,
    })
      .then(() => {
        if (status === 'approved') {
          // toast.success('Approved Succesfully');
          toast(<CustomToast message={'Approved Succesfully'} type="success" />);
        } else {
          // toast.success('Rejected Succesfully');
          toast(<CustomToast message={'Rejected Succesfully'} type="success" />);
        }
        getClaimById();
        // setLoading(false);
        setApproveLoad(null);
        setRejectLoad(null);
      })
      .catch((err) => {
        console.log('err', err);
        // toast.error(err?.response?.data?.message);
        toast(<CustomToast message={err?.response?.data?.message} type="error" />);
        // setLoading(false);
        setApproveLoad(null);
        setRejectLoad(null);
      });
  };

  const getClaimById = (): void => {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    request('get', `/companies/claim/${claimId}`)
      .then((res: any) => {
        console.log(res, 'ccccccc');
        setClaimDetials(res?.claim);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber;
  };
  useEffect(() => {
    if (claimId) {
      getClaimById();
    }
  }, [claimId]);
  useEffect(() => {
    if (claimDetials?.company?._id) {
      getLinkedAccounts();
    }
  }, [claimDetials?.company?._id]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={'column'}
      width="100%"
      position={'relative'}
    >
      <Header />
      <Box
        position={'absolute'}
        width={'30px'}
        left={'18%'}
        top={'110px'}
        onClick={handleBackClick}
        display={'flex'}
        gap={'10px'}
        sx={{
          cursor: 'pointer',
        }}
      >
        <img style={{ width: '100%', cursor: 'pointer' }} src={BackArrow} alt="BackArrow" />
        <Typography
          fontFamily={'SF UI Display'}
          fontWeight={600}
          // lineHeight={'20px'}
          color={'black'}
          fontSize={'14px'}
        >
          Back
        </Typography>
      </Box>
      <Box
        width={'50%'}
        marginBottom={'2%'}
        borderRadius={'12px'}
        border={'1px solid #EAECF0'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
        minHeight={'50vh'}
        display={'flex'}
        flexDirection={'column'}
        margin={'2% 0'}
        padding={'2% 1%'}
        gap={'30px'}
      >
        <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'20px'}>
          <Box width={'130px'} height={'130px'}>
            {claimDetials?.company?.companyInfo?.logo ? (
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                src={`${mediaUrl}/${claimDetials?.company?.companyInfo?.logo}`}
                alt="logo"
              />
            ) : (
              <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={Dummyimage}
                alt="dummy logo"
              />
            )}
          </Box>
          <Box width={'75%'}>
            <Typography fontSize={'44px'} fontWeight={'700'} color={'#000000'}>
              {claimDetials?.company?.companyInfo.name}
            </Typography>
            <Typography fontSize={'16px'} fontWeight={'300'} color={'#454238'}>
              {claimDetials?.company?.companyInfo.description
                ? claimDetials?.company?.companyInfo.description
                : 'No Description'}
            </Typography>
          </Box>
        </Box>
        <Box width={'100%'}>
          <Typography
            fontFamily={'SF UI Display'}
            fontWeight={900}
            lineHeight={'23.87px'}
            color={'black'}
            fontSize={'20px'}
          >
            Claimed By
          </Typography>
          {/* {linkedAccounts?.map((item: any, index: number) => ( */}
          <Box
            // key={index}
            width={'100%'}
            display={'flex'}
            gap={'15px'}
            marginTop={'2%'}
            alignItems={'center'}
          >
            <Box width="43px" height="43px">
              {claimDetials?.user?.photo ? (
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '100px',
                  }}
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  src={`${mediaUrl}/${claimDetials?.user?.photo}`}
                  alt="logsso"
                />
              ) : (
                <Avatar
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '100px',
                  }}
                  // src={Dummyimage}
                  alt="dummy logo"
                />
              )}
            </Box>
            <Box padding={'0'} width={'40%'} margin={'2% 0'}>
              <Typography
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                lineHeight={'20px'}
                color="rgba(0, 0, 0, 1)"
                fontWeight={800}
              >
                {claimDetials?.user?.first_name} {claimDetials?.user?.last_name}
              </Typography>
              <Typography
                fontFamily={'SF UI Display'}
                fontSize={'16px'}
                lineHeight={'20px'}
                color="rgba(0, 0, 0, 1)"
                fontWeight={600}
              >
                {claimDetials?.user?.email}
              </Typography>
            </Box>
            {/* <Box width={'48%'} display={'flex'} alignItems={'center'}>
                <Box
                  color={item.type === 'owner' ? '#067647' : '#FFFFFF'}
                  fontFamily={'Inter'}
                  fontSize={'12px'}
                  fontWeight={'400'}
                  lineHeight={'24px'}
                  bgcolor={item.type === 'owner' ? '#ECFDF3' : '#000000'}
                  border={'1px solid ' + (item.type === 'owner' ? '#ABEFC6' : '#000000')}
                  display={'flex'}
                  alignItems={'center'}
                  gap={'4px'}
                  borderRadius={'50px'}
                  boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                  padding={'2px 6px'}
                  width={'fit-content'}
                  textTransform={'capitalize'}
                >
                  {item?.type === 'owner' ? 'Page Owner' : 'Team Member'}
                </Box>
              </Box> */}
          </Box>
          {/* ))} */}
        </Box>
        {claimDetials?.company?.companyInfo?.companyType === 'local' && (
          <Box width={'100%'}>
            <Typography
              fontSize={'20px'}
              fontWeight={900}
              color={'#000000'}
              fontFamily={'SF UI Display'}
            >
              Services
            </Typography>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              gap={'20px'}
              marginTop={'3%'}
            >
              {claimDetials?.company?.services?.map(
                (item: { service: any }, index: React.Key | null | undefined) => {
                  return (
                    <Typography
                      key={index}
                      display={'flex'}
                      alignItems={'center'}
                      gap={'5px'}
                      fontSize={'16px'}
                      fontWeight={'700'}
                      color={'#000000'}
                      sx={{ textDecoration: 'underline' }}
                    >
                      <img src={GreenCheck} alt="Check" />
                      {item.service ?? 'testing'}
                    </Typography>
                  );
                },
              )}
            </Box>
          </Box>
        )}
        {/* <Box width={'100%'} margin={'1% 0'}>
          <Typography fontSize={'20px'} fontWeight={'800'} color={'#000000'}>
            Equipments
          </Typography>
          <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'20px'} margin={'3% 0'}>
            <Typography
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
              fontSize={'16px'}
              fontWeight={'700'}
              color={'#000000'}
            >
              <img src={GreenCheck} alt="Check" />
              Crane
            </Typography>
            <Typography
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
              fontSize={'16px'}
              fontWeight={'700'}
              color={'#000000'}
            >
              <img src={GreenCheck} alt="Check" />
              Bucket Truck
            </Typography>
            <Typography
              display={'flex'}
              alignItems={'center'}
              gap={'5px'}
              fontSize={'16px'}
              fontWeight={'700'}
              color={'#000000'}
            >
              <img src={GreenCheck} alt="Check" />
              Service Truck
            </Typography>
          </Box>
        </Box> */}
        {claimDetials?.company?.serviceArea?.[0]?.distance && (
          <Box width={'95%'} height={'300px'} marginTop={'2%'}>
            <MapView
              radiusInMiles={claimDetials?.company?.serviceArea?.[0]?.distance}
              data={claimDetials?.company?.companyInfo?.location}
            />
          </Box>
        )}
        <Box width="95%" border="solid 1px #E8E8E8" borderRadius="5px" padding="2% 4%">
          <Typography
            fontFamily="SF UI Display"
            fontWeight="900"
            fontSize="18px"
            lineHeight="21.48px"
            color="black"
          >
            Company Details
          </Typography>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Location
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {claimDetials?.company?.companyInfo?.address?.address}
            </Typography>
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Domain
            </Typography>
            {/* {!showWebsite ? (
              <Button
                onClick={handleWebsiteClick}
                sx={{
                  padding: '0',
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '19px',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                View
              </Button>
            ) : ( */}
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {claimDetials?.company?.companyInfo.domain}
            </Typography>
            {/* )} */}
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Phone Number
            </Typography>
            {/* {!showPhoneNumber ? (
              <Button
                onClick={handlePhoneNumberClick}
                sx={{
                  padding: '0',
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '19px',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                Show
              </Button>
            ) : ( */}
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
              sx={{ opacity: '0.8' }}
            >
              +1 {formatPhoneNumber(claimDetials?.company?.companyInfo?.phone)}
            </Typography>
            {/* )} */}
          </Box>
          <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="1% 0">
            <Typography
              fontSize="14px"
              fontWeight="600"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              Email Address
            </Typography>
            {/* {!showEmailAddress ? (
              <Button
                onClick={handleEmailAddressClick}
                sx={{
                  padding: '0',
                  margin: '0',
                  textTransform: 'capitalize',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '19px',
                  fontFamily: 'SF UI Display',
                  color: '#00BD57',
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                Show
              </Button>
            ) : ( */}
            <Typography
              fontSize="16px"
              fontWeight="300"
              lineHeight="19px"
              fontFamily="SF UI Display"
            >
              {claimDetials?.company?.companyInfo?.email}
            </Typography>
            {/* )} */}
          </Box>
        </Box>
        {claimDetials?.company?.companyInfo?.companyType === 'local' &&
          !claimDetials?.company?.serviceArea?.[0]?.distance && (
            <Box width="95%" border="solid 1px #E8E8E8" borderRadius="5px" padding="2% 4%">
              <Typography
                fontFamily="SF UI Display"
                fontWeight="800"
                fontSize="18px"
                lineHeight="23px"
                color="black"
              >
                Service States
              </Typography>
              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
              <Box display="flex" justifyContent="space-between" padding="1% 0" gap={'5px'}>
                <Typography
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="19px"
                  fontFamily="SF UI Display"
                >
                  Location
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  gap={'10px'}
                  // width={'90%'}
                  justifyContent={'right'}
                  flexWrap={'wrap'}
                >
                  {claimDetials?.company?.serviceArea?.map(
                    (
                      area: {
                        name:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      index: React.Key | null | undefined,
                    ) => {
                      return (
                        <Typography
                          key={index}
                          fontSize="16px"
                          fontWeight="600"
                          lineHeight="19px"
                          fontFamily="SF UI Display"
                          borderRadius={'5px'}
                          border={'1px solid #000'}
                          bgcolor={'#D7D7D7'}
                          padding={'5px 10px'}
                        >
                          {area.name}
                        </Typography>
                      );
                    },
                  )}
                </Box>
              </Box>
              <Divider sx={{ bgcolor: '#EBEEF1', width: '100%', margin: '2% 0' }} />
            </Box>
          )}
        <Box width={'95%'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          {claimDetials?.status === 'pending' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'100%'}
              gap="10px"
            >
              <Button
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  border: '1px solid #47B45F',
                  background: 'transparent',
                  color: '#454238B2',
                  textTransform: 'capitalize',
                  // '&:hover': {
                  //   bgcolor: '#47B45F',
                  // },
                }}
                onClick={() => {
                  if (claimDetials?._id) handleApproved(claimDetials?._id, 'approved');
                }}
              >
                {approveLoad === claimDetials?._id ? (
                  <CircularProgress size={15} color="inherit" />
                ) : (
                  <>
                    <Box>
                      <Check />
                    </Box>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'600'}
                      fontFamily={'Inter'}
                      lineHeight={'20px'}
                      color={'#454238B2'}
                    >
                      Approve
                    </Typography>
                  </>
                )}
              </Button>
              <Button
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  border: '1px solid #FF5D39',
                  background: '#FFFFFF',
                  color: '#ffffff',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: '#FFFFFF',
                  },
                }}
                onClick={() => {
                  if (claimDetials?._id) handleApproved(claimDetials?._id, 'rejected');
                }}
              >
                {rejectLoad === claimDetials?._id ? (
                  <CircularProgress size={15} color="inherit" />
                ) : (
                  <>
                    <Box>
                      {/* <img src={Close} alt="Check" /> */}
                      <Close color="#FF5D39" />
                    </Box>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'600'}
                      fontFamily={'Inter'}
                      lineHeight={'20px'}
                      color={'#FF5D39'}
                    >
                      Reject
                    </Typography>
                  </>
                )}
              </Button>
            </Box>
          )}
          {claimDetials?.status === 'approved' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Button
                disabled
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  background: 'rgba(71, 180, 95, 0.50)',
                  color: '#ffffff',
                  textTransform: 'capitalize',
                  // mt: 3,
                }}
              >
                <Check color="#E1E1E1" />

                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Inter'}
                  lineHeight={'20px'}
                  color={'#ffffff'}
                >
                  Approved
                </Typography>
              </Button>
            </Box>
          )}
          {claimDetials?.status === 'rejected' && (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Button
                disabled
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '4px',
                  padding: '10px 14px',
                  borderRadius: '8px',
                  border: '1px solid rgba(255, 93, 57, 0.50)',
                  background: '#FFFFFF',
                  color: '#FF5D3980',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: '#FFFFFF',
                  },
                }}
              >
                <Box>
                  {/* <img src={Close} alt="Check" /> */}
                  <Close />
                </Box>
                <Typography
                  fontSize={'14px'}
                  fontWeight={'600'}
                  fontFamily={'Inter'}
                  lineHeight={'20px'}
                  color={'#FF5D3980'}
                >
                  Rejected
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
        {/* <Divider sx={{ bgcolor: '#EBEEF1', width: '95%', margin: '2% 0' }} /> */}
      </Box>
    </Box>
  );
};

export default ClaimDetails;
