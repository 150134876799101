/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  //   Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Header from '../components/Header.component';
// import CompanyImage from '../assets/companyAvatar.png';
import Edit from '../assets/companyIcon.svg';
import { ReactComponent as Check } from '../assets/check.svg';
import { ReactComponent as Close } from '../assets/close.svg';
import { useNavigate } from 'react-router-dom';
import request from '../api/request';
import { toast } from 'react-toastify';
import { Company, ICompanyResponse } from '../types/user';
import CustomToast from '../components/common/CustomToast';

const Request: FC = () => {
  const nav = useNavigate();
  const [page, setPage] = useState(1);
  const [allcompanies, setAllcompanies] = useState<Company[]>();
  const [loading, setLoading] = useState(true);
  const [approveLoad, setApproveLoad] = useState<any>(null);
  const [rejectLoad, setRejectLoad] = useState<any>(null);
  // const sortCompaniesByVerification = (companies: Company[] | undefined): Company[] => {
  //   if (!companies) {
  //     // Handle the case where companies might be undefined
  //     return [];
  //   }

  //   return companies.sort((a, b) => {
  //     if (a.updateRequest && !b.updateRequest) return -1;
  //     if (!a.updateRequest && b.updateRequest) return 1;

  //     const aHasVerification = !!a.companyInfo?.verification;
  //     const bHasVerification = !!b.companyInfo?.verification;

  //     // If a doesn't have verification but b does, a should come first
  //     if (!aHasVerification && bHasVerification) return -1;
  //     // If b doesn't have verification but a does, b should come first
  //     if (aHasVerification && !bHasVerification) return 1;

  //     // If both have or both don't have verification, maintain current order
  //     return 0;
  //   });
  // };
  const sortCompaniesByUpdatedAt = (companies: Company[] | undefined): Company[] => {
    if (!companies) {
      // Handle the case where companies might be undefined
      return [];
    }

    // a = companies that do not have companyInfo.verification
    const noVerification = companies.filter((company) => !company.companyInfo?.verification);
    // b = companies that have companyInfo.verification and their updateRequest is true
    const verifiedWithUpdateRequest = companies.filter(
      (company) => company.companyInfo?.verification && company.updateRequest,
    );
    // c = all other companies (the rest)
    const restCompanies = companies.filter(
      (company) => company.companyInfo?.verification && !company.updateRequest,
    );
    // d = sort companies in noVerification (a) and verifiedWithUpdateRequest (b) by recently updatedAt
    const pendingRequests = [...noVerification, ...verifiedWithUpdateRequest];
    const sortedNoVerification = pendingRequests.sort(
      (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );
    // Combine sorted a (noVerification) and b (verifiedWithUpdateRequest), followed by c (restCompanies)
    return [...sortedNoVerification, ...restCompanies];
  };

  // Usage
  const sortedCompanies = sortCompaniesByUpdatedAt(allcompanies);
  const getCompanies = (): void => {
    request<ICompanyResponse>('get', `/companies`)
      .then((res) => {
        // toast.success('Success');
        setAllcompanies(res?.companies);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log('err', err);
      });
  };
  useEffect(() => {
    getCompanies();
  }, []);

  const itemsPerPage = 9;
  const totalPages = allcompanies ? Math.ceil(allcompanies?.length / itemsPerPage) : 0;
  //   const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number): void => {
  //     setPage(newPage);
  //   };
  const handlePreviousPage = (): void => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = (): void => {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedRows = sortedCompanies?.length && sortedCompanies?.slice(startIndex, endIndex);
  console.log('paginatedRows', paginatedRows);
  // update apporve
  const handleUpdateApproved = (id: string): void => {
    request('put', `/companies/approveEdit/${id}`, {
      updateRequest: true,
    })
      .then(() => {
        toast.success('Company Updated');
        getCompanies();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  // end
  // update reject
  const handleUpdateReject = (id: string): void => {
    request('put', `/companies/approveEdit/${id}`, {
      updateRequest: false,
    })
      .then(() => {
        toast.success('Company Updated');
        getCompanies();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  // end
  const handleRejectRequest = (id: string): void => {
    request('patch', `/companies/requests/${id}`)
      .then(() => {
        toast(<CustomToast message={'Company request rejected!'} type="success" />);

        getCompanies();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const handleApproved = (id: string, status: boolean, type: string): void => {
    if (type === 'approved') {
      setApproveLoad(id);
    } else {
      setRejectLoad(id);
    }
    request('patch', `/companies/${id}`, {
      approved: status,
    })
      .then(() => {
        toast.success('Status Updated');
        getCompanies();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  console.log('paginatedRows22', paginatedRows);

  return (
    <Box width={'100%'}>
      <Header />
      <Box
        width={'74%'}
        margin={'1% 13%'}
        borderRadius={'12px'}
        border={'1px solid #EAECF0'}
        bgcolor={'#FFF'}
        boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
        minHeight={'80vh'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
      >
        <Box width={'100%'}>
          <Box width={'100%'} padding={'20px 24px'} borderBottom={'1px solid #EAECF0'}>
            <Typography
              color={'#101828'}
              fontFamily={'Inter'}
              fontSize={'18px'}
              fontWeight={'600'}
              lineHeight={'28px'}
            >
              Company Requests
            </Typography>
            {/* <Typography
              color={'#475467'}
              fontFamily={'Inter'}
              fontSize={'14px'}
              fontWeight={'400'}
              lineHeight={'20px'}
            >
              Manage and Respond to Local Business Requests Efficientlytly
            </Typography> */}
          </Box>
          <Box width={'100%'}>
            <TableContainer sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <Table aria-label="simple table" sx={{ width: '100%' }}>
                <TableHead sx={{ width: '100%' }}>
                  <TableRow sx={{ width: '100%' }}>
                    <TableCell
                      sx={{
                        width: '40%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                        padding: '0 24px',
                      }}
                    >
                      Company
                    </TableCell>
                    {/* Edit stauts */}

                    {/* company type */}
                    <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Company Type
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Location
                    </TableCell> */}
                    {/* <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Page Owner
                    </TableCell> */}
                    <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '#475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Request Type
                    </TableCell>
                    <TableCell
                      sx={{
                        width: '20%',
                        fontSize: '12px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        color: '475467',
                        fontFamily: 'Inter',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Approve / Reject
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allcompanies?.length && paginatedRows
                    ? paginatedRows?.map((company, index: number) => (
                        // {allcompanies.map((item, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            cursor: company?.requestType === 'requested' ? 'no-drop' : 'pointer',
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': { backgroundColor: '#FADE701A' },
                          }}
                          onClick={() => {
                            /* eslint-disable @typescript-eslint/restrict-template-expressions */
                            if (company?.requestType === 'requested') return;
                            nav(`/dashboard/requestdetail/${company?._id}`, {
                              state: {
                                updateRequest: company?.updateRequest,
                              },
                            });
                          }}
                        >
                          <TableCell
                            sx={{
                              width: '40%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                              padding: '16px 24px',
                            }}
                            component="th"
                            scope="row"
                          >
                            <Box width={'100%'} display={'flex'} alignItems={'center'} gap={'12px'}>
                              <Box>
                                <Typography
                                  color={'#101828'}
                                  fontFamily={'Inter'}
                                  fontSize={'14px'}
                                  fontWeight={'500'}
                                  lineHeight={'20px'}
                                >
                                  {company?.companyInfo?.name}
                                </Typography>
                                <Typography
                                  color={'#475467'}
                                  fontFamily={'Inter'}
                                  fontSize={'14px'}
                                  fontWeight={'400'}
                                  lineHeight={'20px'}
                                >
                                  {company?.companyInfo?.address?.city && (
                                    <>{company.companyInfo.address.city}</>
                                  )}
                                  {company?.companyInfo?.address?.state && (
                                    <>
                                      {company.companyInfo.address.city && ', '}
                                      {company.companyInfo.address.state}
                                    </>
                                  )}
                                  {company?.companyInfo?.address?.country && (
                                    <>
                                      {(company.companyInfo.address.city ||
                                        company.companyInfo.address.state) &&
                                        ', '}
                                      {company.companyInfo.address.country}
                                    </>
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>

                          {/* company type */}
                          <TableCell
                            sx={{
                              width: '20%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={'4px'}
                              border={'1px solid #D0D5DD'}
                              borderRadius={'6px'}
                              boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                              padding={'2px 6px'}
                              width={'fit-content'}
                            >
                              <Box
                                width={'7px'}
                                height={'7px'}
                                borderRadius={'3.5px'}
                                bgcolor={
                                  company?.companyInfo?.companyType === 'local'
                                    ? '#17B26A'
                                    : company?.companyInfo?.companyType === 'national'
                                    ? '#667085'
                                    : company?.companyInfo?.companyType === 'supplier'
                                    ? '#2E90FA'
                                    : 'pink'
                                }
                              />

                              <Typography
                                color={'#344054'}
                                fontFamily={'Inter'}
                                fontSize={'12px'}
                                fontWeight={'500'}
                                textTransform={'capitalize'}
                                lineHeight={'16px'}
                              >
                                {company?.companyInfo?.companyType}{' '}
                                {company?.companyInfo?.companyType !== 'supplier' &&
                                  company?.companyInfo?.companyType !== 'distributor' &&
                                  'Company'}
                              </Typography>
                            </Box>
                          </TableCell>
                          {/*  */}
                          {/* <TableCell
                            sx={{
                              width: '20% ',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              // whiteSpace: 'nowrap',
                            }}
                          >
                            <Typography
                              // width={'100%'}
                              color={'#667085'}
                              fontFamily={'Inter'}
                              fontSize={'16px'}
                              fontWeight={'400'}
                              lineHeight={'24px'}
                            >
                              {company?.companyInfo?.address?.state.length > 15
                                ? `${company?.companyInfo?.address?.state.slice(0, 15)}...`
                                : company?.companyInfo?.address.state}
                            </Typography>
                          </TableCell> */}
                          {/* <TableCell
                            sx={{
                              width: '20%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box>
                              <Typography
                                color={'#667085'}
                                fontFamily={'SF UI Display'}
                                fontSize={'14px'}
                                fontWeight={'500'}
                                lineHeight={'20px'}
                                textTransform={'capitalize'}
                              >
                                {company?.companyInfo?.owner
                                  ? `${company?.companyInfo?.owner?.first_name} ${company?.companyInfo?.owner?.last_name}`
                                  : 'Not Available'}
                              </Typography>
                              <Typography
                                color={'#667085'}
                                fontFamily={'Inter'}
                                fontSize={'14px'}
                                fontWeight={'300'}
                                lineHeight={'24px'}
                                textTransform={'capitalize'}
                                // fontStyle={'italic'}
                              >
                                {company?.companyInfo?.owner?.job_title ?? ''}
                              </Typography>
                            </Box>
                          </TableCell> */}
                          {/* Edit status */}
                          <TableCell
                            sx={{
                              width: '20%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={'4px'}
                              borderRadius={'6px'}
                              boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                              padding={'2px 6px'}
                              width={'fit-content'}
                              sx={{
                                bgcolor: {
                                  backgroundColor:
                                    company?.requestType === 'updated' ? '#F9F5FF' : '#F0F9FF',
                                },
                                border: {
                                  border:
                                    company?.requestType === 'updated'
                                      ? 'solid 1px rgba(233, 215, 254, 1)'
                                      : company?.requestType === 'requested'
                                      ? '1px solid rgba(178, 221, 255, 1)'
                                      : '1px solid rgba(185, 230, 254, 1)',
                                },
                              }}
                            >
                              <Typography
                                fontFamily={'Inter'}
                                fontSize={'12px'}
                                fontWeight={'500'}
                                textTransform={'capitalize'}
                                lineHeight={'16px'}
                                sx={{
                                  color: {
                                    color:
                                      company?.requestType === 'updated'
                                        ? 'rgba(105, 65, 198, 1)'
                                        : company?.requestType === 'requested'
                                        ? 'rgba(23, 92, 211, 1)'
                                        : 'rgba(2, 106, 162, 1)',
                                  },
                                }}
                              >
                                {company?.requestType === 'updated'
                                  ? 'Page Update'
                                  : company?.requestType === 'requested'
                                  ? 'Page Suggestion'
                                  : 'Page Creation'}
                                {/* {company?.companyInfo?.companyType !== 'supplier' && 'Company'} */}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              width: '20%',
                              fontSize: '14px',
                              fontWeight: '500',
                              color: '#101828',
                              fontFamily: 'Urbanist',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <Box
                              width={'100%'}
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'space-between'}
                            >
                              {!company?.companyInfo?.verification && (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  width={'80%'}
                                  gap="10px"
                                >
                                  <Button
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid #47B45F',
                                      background: 'transparent',
                                      color: '#454238B2',
                                      textTransform: 'capitalize',
                                      width: '100%',
                                      // '&:hover': {
                                      //   bgcolor: '#47B45F',
                                      // },
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (company?.requestType === 'requested') {
                                        nav('/addcompany', { state: { company } });
                                      } else {
                                        handleApproved(company?._id, true, 'approved');
                                      }
                                    }}
                                  >
                                    {approveLoad === company?._id ? (
                                      <CircularProgress size={15} color="inherit" />
                                    ) : (
                                      <>
                                        <Box>
                                          <Check />
                                        </Box>
                                        <Typography
                                          fontSize={'14px'}
                                          fontWeight={'600'}
                                          fontFamily={'Inter'}
                                          lineHeight={'20px'}
                                          color={'#454238B2'}
                                          pr={1}
                                        >
                                          {company?.requestType === 'requested' ? 'Add' : 'Approve'}
                                        </Typography>
                                      </>
                                    )}
                                  </Button>
                                  <Button
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid #FF5D39',
                                      background: '#FFFFFF',
                                      color: '#ffffff',
                                      textTransform: 'capitalize',
                                      width: '100%',
                                      '&:hover': {
                                        bgcolor: '#FFFFFF',
                                      },
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (company?.requestType === 'requested') {
                                        handleRejectRequest(company?._id);
                                      } else {
                                        handleApproved(company?._id, false, 'rejected');
                                      }
                                    }}
                                  >
                                    {rejectLoad === company?._id ? (
                                      <CircularProgress size={15} color="inherit" />
                                    ) : (
                                      <>
                                        <Box>
                                          {/* <img src={Close} alt="Check" /> */}
                                          <Close color="#FF5D39" />
                                        </Box>
                                        <Typography
                                          fontSize={'14px'}
                                          fontWeight={'600'}
                                          fontFamily={'Inter'}
                                          lineHeight={'20px'}
                                          color={'#FF5D39'}
                                        >
                                          Reject
                                        </Typography>
                                      </>
                                    )}
                                  </Button>
                                </Box>
                              )}
                              {company?.companyInfo?.verification?.status === true &&
                                (company?.updateRequest ? (
                                  <Box
                                    width={'80%'}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                  >
                                    <Button
                                      onClick={(e): void => {
                                        e.stopPropagation();
                                        handleUpdateApproved(company?._id);
                                      }}
                                      sx={{
                                        mr: 1,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        padding: '10px 14px',
                                        borderRadius: '8px',
                                        border: '1px solid #47B45F',
                                        background: '#FFFFFF',
                                        color: 'rgba(69, 66, 56, 0.7)',
                                        textTransform: 'capitalize',
                                        width: '50%',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        fontFamily: 'Inter',
                                        // '&:hover': {
                                        //   bgcolor: '#47B45F',
                                        // },
                                      }}
                                    >
                                      <Box>
                                        <Check />
                                      </Box>
                                      update
                                    </Button>
                                    <Button
                                      onClick={(e): void => {
                                        e.stopPropagation();
                                        handleUpdateReject(company?._id);
                                      }}
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        padding: '10px 14px',
                                        borderRadius: '8px',
                                        border: '1px solid #FF5D39',
                                        background: '#FFFFFF',
                                        color: 'rgba(255, 93, 57, 1)',
                                        textTransform: 'capitalize',
                                        width: '48%',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        fontFamily: 'Inter',
                                        '&:hover': {
                                          bgcolor: '#FFFFFF',
                                        },
                                      }}
                                    >
                                      <Box>
                                        {/* <img src={Close} alt="Check" /> */}
                                        <Close color="#FF5D39" />
                                      </Box>
                                      Reject
                                    </Button>
                                  </Box>
                                ) : (
                                  <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    justifyContent={'space-between'}
                                    width={'80%'}
                                  >
                                    <Button
                                      disabled
                                      fullWidth
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '4px',
                                        padding: '10px 14px',
                                        borderRadius: '8px',
                                        background: 'rgba(71, 180, 95, 0.50)',
                                        color: '#ffffff',
                                        textTransform: 'capitalize',
                                      }}
                                    >
                                      <Check color="#E1E1E1" />

                                      <Typography
                                        fontSize={'14px'}
                                        fontWeight={'600'}
                                        fontFamily={'Inter'}
                                        lineHeight={'20px'}
                                        color={'#ffffff'}
                                      >
                                        Approved
                                      </Typography>
                                    </Button>
                                  </Box>
                                ))}
                              {company?.companyInfo?.verification?.status === false && (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  width={'80%'}
                                >
                                  <Button
                                    disabled
                                    fullWidth
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      gap: '4px',
                                      padding: '10px 14px',
                                      borderRadius: '8px',
                                      border: '1px solid rgba(255, 93, 57, 0.50)',
                                      background: '#FFFFFF',
                                      color: '#FF5D3980',
                                      textTransform: 'capitalize',
                                      '&:hover': {
                                        bgcolor: '#FFFFFF',
                                      },
                                    }}
                                  >
                                    <Box>
                                      {/* <img src={Close} alt="Check" /> */}
                                      <Close color="#FF5D39" opacity={0.5} />
                                    </Box>
                                    <Typography
                                      fontSize={'14px'}
                                      fontWeight={'600'}
                                      fontFamily={'Inter'}
                                      lineHeight={'20px'}
                                      color={'#FF5D3980'}
                                    >
                                      Rejected
                                    </Typography>
                                  </Button>
                                </Box>
                              )}
                              {/* <Link to={`/dashboard/requestdetail/${company._id}`}> */}
                              <Box width={'16.67px'} height={'16.67px'}>
                                <img src={Edit} alt="Edit" />
                              </Box>
                              {/* </Link> */}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    : ''}
                  {/* ))}  */}
                </TableBody>
              </Table>
              {!allcompanies?.length ? (
                <Typography
                  color={'#101828'}
                  fontFamily={'Inter'}
                  fontSize={'18px'}
                  fontWeight={'600'}
                  lineHeight={'28px'}
                  padding={'2% 5%'}
                  whiteSpace={'nowrap'}
                >
                  {loading ? <CircularProgress size={15} color="inherit" /> : ' No Request Found'}
                </Typography>
              ) : (
                ''
              )}
            </TableContainer>
          </Box>
        </Box>
        {allcompanies?.length ? (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="32px"
            sx={{
              '@media (max-width:520px)': {
                flexDirection: 'column',
                gap: '10px',
              },
            }}
          >
            <Box display={'flex'} alignItems={'center'} gap={'12px'}>
              <Button
                variant="outlined"
                onClick={handlePreviousPage}
                disabled={page === 1}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={handleNextPage}
                disabled={page === totalPages}
                sx={{
                  backgroundColor: 'transparent',
                  border: '1px solid #D0D5DD',
                  fontSize: '14px',
                  fontWeight: '600',
                  lineHeight: '20px',
                  color: '#344054',
                  padding: '8px 12px',
                  borderRadius: '8px',
                  textTransform: 'capitalize',
                  '&.Mui-disabled': {
                    borderColor: '#E0E0E0',
                    color: '#E0E0E0',
                  },
                }}
              >
                Next
              </Button>
            </Box>
            <Typography
              fontFamily="Inter"
              lineHeight="20px"
              fontSize="14px"
              color="#344054"
              fontWeight="500"
            >
              Page {page} of {allcompanies ? Math.ceil(allcompanies?.length / itemsPerPage) : 0}
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  );
};

export default Request;
