import React, { FC, useEffect, useState } from 'react';
import {
  // Autocomplete,
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Header from '../components/Header.component';

import PersonalInfoIcon from '../assets/signUpiIcon.svg';
import DefaultAvatar from '../assets/avatar.svg';
import UpoadImg from '../assets/uploadIcon.svg';

import { useLocation, useNavigate } from 'react-router-dom';
import request from '../api/request';
import CloseIcon from '../assets/CloseIcon.svg';
import VisibilityOff from '../assets/DesignUpdate/EyeoffIcon.svg';
import Visibility from '../assets//DesignUpdate/eyeicon.svg';
import { toast } from 'react-toastify';
import { mediaUrl } from '../config';
import CustomToast from '../components/common/CustomToast';

interface User {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  photo: string;
  password: string;
  job_title: string;
  claim: boolean;
}
// interface Company {
//   id: number;
//   name: string;
// }

const AddUsers: FC = () => {
  const nav = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const initialUser: User = {
    first_name: '',
    last_name: '',
    company: '',
    email: '',
    photo: '',
    job_title: '',
    password: '',
    claim: !selectedCompany?.companyInfo?.owner,
  };
  const [user, setUser] = useState<User>(initialUser);
  const [domainCompany, setDomainCompany] = useState<any>([]);
  // const [searchCompany, setSearchCompany] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [domain, setDomain] = useState<string>('');

  const location = useLocation();
  const stateData = location.state;

  console.log(stateData, 'stateData');

  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  console.log('user', user);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
  };
  console.log('selectedCompany', selectedCompany);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  // const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   const query = e.target.value;
  //   setSelectedCompany(query);
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     company: '',
  //   }));
  //   if (query) {
  //     request('get', `/companies/searchByName/${query}`)
  //       .then((res: any) => {
  //         // toast.success('Success');
  //         console.log('res searcchh', res?.companies);
  //         setSearchCompany(res?.companies ?? []);
  //       })
  //       .catch((err) => {
  //         toast.error('Error');
  //         console.log('err', err);
  //       });
  //   } else {
  //     setSearchCompany([]);
  //   }
  // };

  // const handleSelectCompany = (
  //   _event: React.SyntheticEvent<Element, Event>,
  //   company: any,
  // ): void => {
  //   setSelectedCompany(company);
  //   setUser((prevUser) => ({
  //     ...prevUser,
  //     company: company?._id,
  //   }));
  //   setSearchCompany([]);
  // };

  const handleRemoveImage = (): void => {
    setUser((prev) => ({
      ...prev,
      photo: '',
    }));
    const fileInput = document.getElementById('upload-button') as HTMLInputElement | null; // Get the file input element
    if (fileInput) {
      fileInput.value = ''; // Resets the file input element to allow re-uploading the same image
    }
  };
  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('photo', file);
      request('post', `/uploads`, formData)
        .then((res) => {
          const imageUrl = (res as unknown as string[])[0]; // Assuming the response contains the image URL in `data.url`

          setUser((prevData) => ({
            ...prevData,
            photo: imageUrl,
          }));
          e.target.value = '';
        })
        .catch((err) => {
          console.error('Image upload error', err);
          // toast.error('error');
        });
    }
  };

  const handleSubmit = (): void => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    const isValidEmail = emailRegex.test(user?.email);
    if (!user?.first_name?.length) {
      // toast.error('Enter First Name');
      toast(<CustomToast message={'Enter First Name'} type="error" />);
    } else if (!user?.last_name?.length) {
      // toast.error('Enter Last Name');
      toast(<CustomToast message={'Enter Last Name'} type="error" />);
    } else if (!user?.email?.length) {
      // toast.error('Enter Email');
      toast(<CustomToast message={'Enter Email'} type="error" />);
    } else if (!isValidEmail) {
      // toast.error('Enter Valid Email');
      toast(<CustomToast message={'Enter Valid Email'} type="error" />);
    } else if (!user?.company?.length) {
      // toast.error('Choose Company');
      toast(<CustomToast message={'Choose Company'} type="error" />);
    }
    if (!user?.password || user.password.length === 0) {
      toast(<CustomToast message={'Please enter a password'} type="error" />);
    } else if (user.password.length < 8) {
      toast(<CustomToast message={'Enter Password of at least 8 characters'} type="error" />);
    } else {
      setLoading(true);

      request('post', `/users`, user)
        .then((res) => {
          // toast.success('User added successfully');
          toast(<CustomToast message={'User added successfully'} type="success" />);
          nav('/dashboard/home');
          setLoading(false);

          console.log('res', res);
          setUser(initialUser);
          setSelectedCompany('');
        })
        .catch((err) => {
          // toast.error(err?.response?.data?.message);
          toast(<CustomToast message={err?.response?.data?.message} type="error" />);
          setLoading(false);

          console.log('err', err);
        });
    }
  };
  const getEmailDomain = (): void => {
    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    request('get', `/companies/searchByEmail/${user?.email}`)
      .then((res: any) => {
        setDomainCompany(res?.companies);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getEmailDomain();
    if (typeof user?.email === 'string' && user?.email.includes('@')) {
      const emailDomain = user?.email.substring(user?.email.lastIndexOf('@') + 1);
      setDomain(emailDomain);
      // setData((prevData) => ({
      //   ...prevData,
      //   companyInfo: {
      //     ...prevData.companyInfo,
      //     domain: emailDomain,
      //   },
      // }));
    } else {
      setDomain('');
    }
  }, [user?.email]);

  useEffect(() => {
    // let domainMatch = false;

    // for (let i = 0; i < domainCompany.length; i++) {
    //   if (domainCompany[i]?.companyInfo?.domain === domain) {
    //     domainMatch = true;
    //     break;
    //   }
    // }

    if (domainCompany[0]?.companyInfo?.domain === domain) {
      setSelectedCompany(domainCompany[0]?.companyInfo?.name);
      setUser((prevUser) => ({
        ...prevUser,
        company: domainCompany[0]?._id,
        claim: false,
      }));
    } else {
      setSelectedCompany('');
      setUser((prevUser) => ({
        ...prevUser,
        company: '',
        claim: false,
      }));
    }
  }, [domainCompany]);

  useEffect(() => {
    if (stateData) {
      setUser((prev) => ({
        claim: stateData.user.claim,
        company: stateData.company._id,
        email: stateData.user.email,
        first_name: stateData.user.first_name,
        last_name: stateData.user.last_name,
        photo: stateData.user.photo,
        job_title: stateData.user.job_title,
        password: stateData.user.password,
      }));
    }
  }, [stateData]);

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      width={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Header hideLinks={true} />
      <Box width={'74%'}>
        <Box
          width={'80%'}
          borderRadius={'16px'}
          border={'1px solid rgba(69, 66, 56, 0.10)'}
          padding={'36px'}
          marginTop={'2%'}
        >
          <Typography
            fontSize={'24px'}
            fontWeight={'600'}
            lineHeight={'20px'}
            fontFamily={'Inter'}
            color={'#000000'}
            marginBottom={'36px'}
          >
            Fill the details below:
          </Typography>
          <Box
            width="100%"
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection="column"
            gap="10px"
          >
            <img src={PersonalInfoIcon} alt="" style={{ width: '77.5px', height: '77.5px' }} />
            <Typography
              fontFamily={'SF UI Display'}
              fontWeight={'800'}
              fontSize={'24px'}
              lineHeight={'28.64px'}
              textAlign={'center'}
            >
              Personal Information
            </Typography>
            <Typography
              fontFamily={'SF UI Display'}
              fontWeight={'400'}
              fontSize={'16px'}
              lineHeight={'19.09px'}
              textAlign={'center'}
            >
              Enter customer details.
            </Typography>
            {/* first name last name fields */}
            <Box
              width={'100%'}
              position="relative"
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {/* profile pic */}
              <Box
                width={'100%'}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                margin={'3% 0'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'flex-start'}
                  alignItems={'flex-start'}
                  position="relative"
                >
                  <Avatar
                    alt="Avatar"
                    src={
                      /* eslint-disable @typescript-eslint/restrict-template-expressions */ user?.photo
                        ? `${mediaUrl}/${user?.photo}`
                        : DefaultAvatar
                    }
                    sx={{ width: '64px', height: '64px' }}
                  />

                  {user.photo && (
                    <Box
                      width={'20px'}
                      display="flex"
                      justifyContent="center"
                      alignItems={'center'}
                      position="absolute"
                      right="5px"
                      top="-3px"
                      onClick={handleRemoveImage}
                    >
                      <img
                        src={CloseIcon}
                        alt=".."
                        style={{ width: '18px', height: '18px', cursor: 'pointer' }}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  ml={2}
                >
                  <input
                    id="upload-button"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}
                    onChange={handleImageUpload}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => document.getElementById('upload-button')?.click()}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      bgcolor: '#ffffff',
                      border: '1px solid #D0D5DD',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      padding: '10px 16px',

                      '&:hover': {
                        bgcolor: '#FFFFFF',
                      },
                    }}
                  >
                    <img src={UpoadImg} alt="UpoadImg" />
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'600'}
                      lineHeight={'20px'}
                      fontFamily={'Inter'}
                      textTransform={'capitalize'}
                      color={'#344054'}
                    >
                      Upload User Profile Picture
                    </Typography>
                  </Button>
                </Box>
              </Box>
              {/* name  */}
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginTop="3%"
              >
                <Box width={'48%'}>
                  <Typography
                    fontFamily={'SF UI Display'}
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="19.09px"
                    margin={'2% 0'}
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      borderRadius: '8px',
                      '.MuiOutlinedInput-input': {
                        padding: '10px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                    InputProps={{
                      inputProps: {},
                    }}
                    id="FirstName"
                    variant="outlined"
                    placeholder="First Name"
                    name="first_name"
                    value={user.first_name}
                    onChange={handleChange} // <-- Add onChange handler
                  />
                </Box>
                {/* last name */}
                <Box width={'48%'}>
                  <Typography
                    fontFamily={'SF UI Display'}
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="19.09px"
                    margin={'2% 0'}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    sx={{
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      borderRadius: '8px',
                      '.MuiOutlinedInput-input': {
                        padding: '10px 14px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: '1px solid #D0D5DD',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                    id="LastName"
                    variant="outlined"
                    placeholder="Last Name"
                    name="last_name"
                    value={user.last_name}
                    onChange={handleChange} // <-- Add onChange handler
                  />
                </Box>
              </Box>
              <Box width={'100%'}>
                <Typography
                  fontFamily={'SF UI Display'}
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="19.09px"
                  margin={'2% 0'}
                >
                  Job Title
                </Typography>
                <TextField
                  fullWidth
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  id="job_title"
                  variant="outlined"
                  placeholder="Enter job title"
                  name="job_title"
                  value={user?.job_title}
                  onChange={handleChange} // <-- Add onChange handler
                  InputProps={{
                    inputProps: {
                      maxLength: 30,
                    },
                  }}
                />
              </Box>
              <Box width={'100%'}>
                <Typography
                  fontFamily={'SF UI Display'}
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="19.09px"
                  margin={'2% 0'}
                >
                  Enter Password
                </Typography>
                <FormControl
                  sx={{
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '12px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={user?.password}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <img src={VisibilityOff} alt="." />
                          ) : (
                            <img src={Visibility} alt="." />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password"
                  />
                </FormControl>
              </Box>
              <Box width={'100%'}>
                <Typography
                  fontFamily={'SF UI Display'}
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="19.09px"
                  margin={'2% 0'}
                >
                  Email
                </Typography>
                <TextField
                  fullWidth
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  id="email"
                  variant="outlined"
                  placeholder="Enter Email"
                  name="email"
                  value={user?.email}
                  onChange={handleChange} // <-- Add onChange handler
                />
              </Box>
              {/* <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0"
            >
              <Box width="100%">
                <Typography
                  fontFamily={'SF UI Display'}
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="19.09px"
                  margin="2% 0"
                >
                  Phone
                </Typography>
                <TextField
                  fullWidth
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  InputProps={{
                    inputProps: {
                      maxLength: 20,
                    },
                  }}
                  id="phone"
                  variant="outlined"
                  placeholder="+0987654321"
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                />
              </Box>
            </Box> */}
              {/* <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="0"
            >
              <Box width="100%">
                <Typography
                  fontFamily={'SF UI Display'}
                  fontWeight={500}
                  fontSize="16px"
                  lineHeight="19.09px"
                  margin="2% 0"
                >
                  Address
                </Typography>
                <TextField
                  fullWidth
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '24px',
                    color: '#667085',
                    borderRadius: '8px',
                    '.MuiOutlinedInput-input': {
                      padding: '10px 14px',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: '1px solid #D0D5DD',
                        outline: 'none',
                      },
                      '&:hover fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#D0D5DD',
                      },
                      '&.MuiInputBase-root:focus-visible': {
                        borderColor: '#D0D5DD',
                      },
                    },
                  }}
                  id="state"
                  variant="outlined"
                  placeholder="Enter Address"
                  name="state"
                  value={user?.address?.state}
                  onChange={handleChange}
                />
              </Box>
            </Box> */}
              {/* company name */}
              <Box width="100%">
                <Typography
                  fontFamily={'SF UI Display'}
                  fontWeight="500"
                  fontSize="16px"
                  lineHeight="19.09px"
                  margin={'2% 0'}
                >
                  Company Name
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  width={'100%'}
                  justifyContent={'space-between'}
                  border="solid 1px #D0D5DD"
                  borderRadius="5px"
                  padding="5px"
                >
                  <TextField
                    fullWidth
                    disabled
                    value={selectedCompany}
                    // onChange={(event) => handleSearch(event.target.value)}
                    placeholder="Find Your Company"
                    sx={{
                      width: '100%',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      color: '#667085',
                      borderRadius: '8px',
                      '.MuiOutlinedInput-input': {
                        padding: '0px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          outline: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#D0D5DD',
                        },
                        '&.MuiInputBase-root:focus-visible': {
                          borderColor: '#D0D5DD',
                        },
                      },
                    }}
                    id="select"
                    InputLabelProps={{
                      style: {
                        borderRadius: '50px',
                      },
                    }}
                  />

                  <Button
                    sx={{
                      width: '20%',
                      backgroundColor: user?.company?.length ? '#E0E0E0' : '#47B45F',
                      fontFamily: 'SF UI Display',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineheight: '19.09px',
                      textAlign: 'center',
                      color: '#FFFFFF',
                      borderRadius: '5px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        bgcolor: '#00BD57',
                      },
                      '@media (max-width: 768px)': {
                        width: '35%',
                      },
                    }}
                    disabled={!!user?.company?.length}
                    // onClick={handleNextClick}
                    onClick={() => {
                      nav('/addcompany', { state: { user, domain } });
                    }}
                  >
                    Add New
                  </Button>
                </Box>
                {/* <Box
                  display={'flex'}
                  alignItems={'center'}
                  width={'100%'}
                  justifyContent={'space-between'}
                  border="solid 1px #D0D5DD"
                  borderRadius="5px"
                  padding="5px"
                >
                  <Autocomplete
                    disableClearable
                    fullWidth
                    sx={{
                      '.MuiOutlinedInput-root': {
                        padding: '10px 14px',
                      },
                    }}
                    value={selectedCompany ?? stateData?.company}
                    freeSolo
                    options={searchCompany}
                    getOptionLabel={(option: any) => {
                      if (typeof option === 'object') {
                        return option.companyInfo.name;
                      } else {
                        return option;
                      }
                    }}
                    onChange={(event, value) => {
                      console.log(value, 'value');
                      if (value && typeof value !== 'string') handleSelectCompany(event, value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Find Your Compnay"
                        sx={{
                          width: '100%',
                          fontSize: '16px',
                          fontWeight: '400',
                          lineHeight: '24px',
                          color: '#667085',
                          borderRadius: '8px',
                          '.MuiOutlinedInput-input.MuiAutocomplete-input': {
                            padding: '0px',
                          },
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              outline: 'none',
                            },
                            '&:hover fieldset': {
                              borderColor: '#D0D5DD',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#D0D5DD',
                            },
                            '&.MuiInputBase-root:focus-visible': {
                              borderColor: '#D0D5DD',
                            },
                          },
                        }}
                        id="select"
                        onChange={handleSearch}
                        // inputProps={{
                        //   sx: {
                        //     '&::placeholder': {
                        //       fontSize: '16px',
                        //     },
                        //     '@media screen and (max-width: 1060px)': {
                        //       '&::placeholder': {
                        //         fontSize: '12px',
                        //       },
                        //     },
                        //   },
                        // }}
                        InputLabelProps={{
                          style: {
                            borderRadius: '50px',
                          },
                        }}
                      />
                    )}
                  />

                  <Button
                    sx={{
                      width: '20%',
                      backgroundColor: user?.company?.length ? '#E0E0E0' : '#00BD57',
                      fontFamily: "SF UI Display",
                      fontSize: '16px',
                      fontWeight: '600',
                      lineheight: '19.09px',
                      textAlign: 'center',
                      color: '#FFFFFF',
                      borderRadius: '5px',
                      textTransform: 'capitalize',
                      '&:hover': {
                        bgcolor: '#00BD57',
                      },
                    }}
                    disabled={!!user?.company?.length}
                    onClick={() => {
                      nav('/addcompany', { state: user });
                    }}
                  >
                    Add New
                  </Button>
                </Box> */}
              </Box>
            </Box>
          </Box>
          <Box
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'right'}
            marginTop={'5%'}
          >
            <Button
              sx={{
                bgcolor: '#47B45F',
                color: '#ffffff',
                border: '1px solid #47B45F',
                borderRadius: '8px',
                padding: '10px 16px',
                textTransform: 'capitalize',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: '600',

                lineHeight: '24px',
                '&:hover': {
                  bgcolor: '#47B45F',
                },
              }}
              onClick={handleSubmit}
              disabled={
                loading ||
                !user?.first_name?.length ||
                !user?.last_name?.length ||
                !user?.company?.length ||
                !user?.email?.length ||
                !user?.password?.length ||
                !user?.job_title?.length
              }
              style={{
                backgroundColor:
                  !user.first_name.length ||
                  !user.last_name.length ||
                  !user.company.length ||
                  !user?.email?.length ||
                  !user?.job_title?.length
                    ? '#E0E0E0'
                    : '#47B45F',
                border:
                  !user.first_name.length ||
                  !user.last_name.length ||
                  !user.company.length ||
                  !user?.email?.length ||
                  !user?.job_title?.length
                    ? 'none'
                    : '1px solid #47B45F',
              }}
            >
              {loading ? <CircularProgress size={15} color="inherit" /> : 'Add User'}{' '}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AddUsers;
