import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../components/Header.component';
import AllRegisteredCompanies from '../components/home/AllRegisteredCompanies.components';
import BackArrow from '../assets/arrowback.svg';

const Companies: FC = () => {
  const handleBackClick = (): void => {
    window.history.back();
  };
  return (
    <Box width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'center'}>
      <Header />
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        marginTop={'2%'}
        position={'relative'}
      >
        <Box
          position={'absolute'}
          width={'30px'}
          left={'5%'}
          top={'0'}
          onClick={handleBackClick}
          display={'flex'}
          gap={'10px'}
          sx={{
            cursor: 'pointer',
          }}
        >
          <img style={{ width: '100%', cursor: 'pointer' }} src={BackArrow} alt="BackArrow" />
          <Typography
            fontFamily={'SF UI Display'}
            fontWeight={600}
            // lineHeight={'20px'}
            color={'black'}
            fontSize={'14px'}
          >
            Back
          </Typography>
        </Box>
        <AllRegisteredCompanies />
      </Box>
    </Box>
  );
};

export default Companies;
