import React, { FC, useEffect } from 'react';
import Router from 'react-easy-router';
import { ToastContainer } from 'react-toastify';

// Routes
import { routes, unAuthRoutes } from './routes';

// CSS Integration
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
import eventHandler from './utils/eventHandler';

const App: FC = () => {
  useEffect(() => {
    eventHandler().finally(console.log);
  }, []);

  const checkAuth = (): boolean => {
    return localStorage.getItem('token') !== null;
  };
  return (
    <div className="App">
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={false}
        pauseOnHover={true}
        draggable={true}
        closeButton={false} // Hide the default close button
        toastStyle={{
          border: '2px solid #000',
          borderRadius: '12px',
          boxShadow: '0px 4px 4px 0px #1018280D',
        }}
      />
      {checkAuth() ? (
        <Router routes={routes} isAuthenticated={checkAuth} />
      ) : (
        <Router routes={unAuthRoutes} isAuthenticated={checkAuth} />
      )}
    </div>
  );
};

export default App;
